<template>
  <div
    v-if="error"
    class="flex h-svh flex-col items-center justify-center space-y-2 overflow-auto bg-main-dark"
  >
    <img
      loading="eager"
      src="~/assets/images/not_available.svg"
      alt=""
      class="block size-24"
    />
    <span class="mb-4 text-3xl font-bold text-white">
      {{ error?.statusCode }}
    </span>
    <span class="mb-9 text-2xl font-medium text-white">
      {{ error?.message }}
    </span>
    <button
      @click="goToHome()"
      class="flex items-center gap-3 rounded-full !bg-linear-primary px-6 py-2.5 text-white hover:opacity-90 lg:px-8"
    >
      <span
        class="whitespace-nowrap text-xs font-semibold uppercase lg:text-base lg:font-bold [&]:leading-none"
      >
        {{ $t("common.go_home") }}
      </span>
      <v-icon class="hidden size-6 lg:block">mdi-arrow-right-circle</v-icon>
    </button>
  </div>
</template>
<script setup>
import { ROUTES_NAME } from "./config/router";

const error = useError();

const goToHome = () => {
  clearError({ redirect: ROUTES_NAME.LOBBY });
};
watch(error, (value) => {
  if (value) {
    console.error(value);
  }
});
</script>
