<template>
  <div ref="intersectionRef">
    <slot />
  </div>
</template>
<script lang="ts" setup>
import { onMounted, onUnmounted, useTemplateRef } from "vue";

type Props = {
  options?: IntersectionObserverInit;
};
const props = defineProps<Props>();
const intersect = defineModel<boolean>();
const divRef = useTemplateRef<HTMLDivElement>("intersectionRef");
const observer = new IntersectionObserver(
  (entries) => {
    if (entries[0].isIntersecting) {
      intersect.value = true;
      observer.disconnect();
    }
  },
  props.options || { threshold: 0.3, rootMargin: "50px" }
);

onMounted(() => {
  if (divRef.value) {
    observer.observe(divRef.value);
  }
});

onUnmounted(() => {
  observer.disconnect();
});
</script>
