<template>
  <div class="relative text-white">
    <CommonDropdown
      :listData="getWalletNetworkList"
      v-model="isOpen"
      disableSearch
      :no-data-message="$t('common.message.no_network_found')"
      :wrap-class="'h-[42px] bg-main-150'"
      :content-class="'w-max-content right-0'"
      list-data-class="scrollbar-max-content"
      location="bottom end"
    >
      <template #selected>
        <div class="flex w-full items-center truncate font-[500] text-white">
          <div class="relative top-[1px] truncate text-sm">
            {{
              selectedNetWork?.network_code ||
              $t("common.placeholder.select_a_network")
            }}
          </div>
        </div>
      </template>

      <template #list-dropdown-item>
        <div
          v-for="(network, index) in getWalletNetworkList"
          :key="index"
          class="cursor-pointer px-2 py-[12px] sm:px-3"
          @click="changeSelectedNetwork(network)"
        >
          <div class="flex items-center justify-between gap-2 sm:gap-4">
            <div
              class="truncate break-words text-[11px] font-[500] text-white sm:text-sm"
            >
              {{ `${network?.network_code} - ${network?.network_name}` }}
            </div>
            <div class="h-6 w-6 flex-shrink-0">
              <svg
                v-if="selectedNetWork?.network_code === network?.network_code"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5Z"
                  fill="#73DD87"
                />
                <path
                  d="M10.5799 16.08C10.3799 16.08 10.1899 16 10.0499 15.86L7.21994 13.03C6.92994 12.74 6.92994 12.26 7.21994 11.97C7.50994 11.68 7.98994 11.68 8.27994 11.97L10.5799 14.27L15.7199 9.13001C16.0099 8.84001 16.4899 8.84001 16.7799 9.13001C17.0699 9.42001 17.0699 9.90001 16.7799 10.19L11.1099 15.86C10.9699 16 10.7799 16.08 10.5799 16.08Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        </div>
      </template>
    </CommonDropdown>
  </div>
</template>

<script setup lang="ts">
import { useGetListEntityWallets } from "~/queries/walletService";
import { INetwork } from "~/types/schema";

const { data: listEntityWallets } = useGetListEntityWallets();

const emit = defineEmits(["onNetworkChange"]);
const props = defineProps(["selectedWalletRef", "selectedNetWork"]);

const isOpen = ref(false);

const getWalletNetworkList = computed((): INetwork[] => {
  const selectedWallet = listEntityWallets.value?.find(
    (item: any) => item?.code === props?.selectedWalletRef?.entity_wallet_code
  );
  if (selectedWallet) return selectedWallet.networks || [];
  return [];
});

const changeSelectedNetwork = (network: any) => {
  emit("onNetworkChange", network);
  isOpen.value = false;
};
</script>

<style scoped></style>
