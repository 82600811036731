<template>
  <component
    :is="!width ? 'div' : width < BREAKPOINT ? VOverlay : 'div'"
    :model-value="railNav && width < BREAKPOINT"
    attach="#auth-layout"
    scroll-strategy="block"
    @click:outside="railNav = !railNav"
    id="nav-bar-overlay"
    z-index="1007"
  >
    <div
      id="nav-bar"
      aria-label="navbar"
      class="navigation-drawer-custom-scrim top-16 z-[1008] h-[calc(100dvh-64px)] translate-y-0 bg-main-175 max-xl:absolute max-xl:w-screen xs:top-[94px] xs:h-[calc(100dvh-94px)] xl:h-full"
      :class="{
        'translate-y-0 xl:w-[280px]': railNav,
        'max-xl:translate-y-[110%] xl:w-[62px]': !railNav,
      }"
      style="
        transition: 0.05s;
        transition-property: box-shadow, transform, visibility, width, height,
          left, right, top, bottom;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      "
    >
      <div
        class="flex h-full flex-col bg-main-175 pb-6 md:mx-auto md:w-[50%] xl:mx-0 xl:w-full"
      >
        <!-- section 1 toggle expand -->
        <div class="flex flex-col">
          <div
            id="id"
            class="hidden !border-b !border-main-150 px-4 py-6 xl:flex"
            :class="railNav ? 'flex' : 'justify-center'"
          >
            <div
              @click.stop="railNav = !railNav"
              class="flex h-[45px] cursor-pointer items-center"
              :class="railNav ? 'justify-center' : ''"
            >
              <v-icon>mdi-menu</v-icon>
            </div>
            <LobbyType
              direction="row"
              class="transition-0 mb-4 flex flex-1 items-center justify-center overflow-hidden rounded-lg xl:mb-0"
              :class="railNav ? 'ml-3 block' : 'hidden'"
            />
          </div>
          <div class="border-b border-main-150 p-4 xl:hidden">
            <LobbyType
              aria-label="mobile-tab"
              direction="row"
              class="flex items-center overflow-hidden rounded-lg xl:hidden"
              :class="railNav ? 'max-h-40' : 'max-h-0'"
              :on-click="
                () => {
                  railNav = !railNav;
                }
              "
            />
          </div>
        </div>

        <!-- section 2 search -->
        <div
          :class="railNav ? 'px-4' : 'px-2'"
          class="flex flex-col border-b border-main-150 py-6"
        >
          <!-- show when menu shrink -->
          <LobbyType
            direction="column"
            class="hidden flex-col overflow-hidden rounded-lg border-x border-transparent xl:flex"
            :class="railNav ? 'max-h-0 w-0' : 'mb-3 max-h-80 w-12'"
          />

          <!-- show when menu expand -->
          <div
            @click="navbarSearch = true"
            class="flex cursor-text items-center gap-3 rounded-lg border border-pink-1 bg-main-dark px-3 py-1.5 hover:!bg-[#202429]"
            :class="{
              'xl:h-11 xl:w-[46px] xl:gap-2 xl:overflow-hidden': !railNav,
            }"
          >
            <svg
              class="flex-shrink-0"
              :class="{ 'relative left-[-3px]': railNav }"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 21L15.0001 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <input
              readonly
              :placeholder="$t('common.btn.search')"
              class="w-full outline-none"
              name="navbar-search"
            />
          </div>
        </div>

        <!-- section 3 list nav  -->
        <div
          class="h-[calc(100dvh-64px-77px-105px-94px)] overflow-y-auto overflow-x-hidden xl:h-auto"
          :class="{ 'xs:h-[calc(100dvh-94px-77px-105px-94px)]': railNav }"
        >
          <NavbarList :items="navbarActive.slice(0, 5)" :railNav="railNav" />
          <ClientOnly>
            <NavbarList :items="categories" :railNav="railNav" />
          </ClientOnly>
          <NavbarList :items="navbarActive.slice(-4)" :railNav="railNav" />
          <div class="w-full p-6" :class="{ 'xl:hidden': !railNav }">
            <ClientOnly>
              <ChangeLanguage wrap-class="!w-full" />
            </ClientOnly>
          </div>
          <div
            :class="{ 'xl:block': !railNav }"
            class="hidden px-4 py-3 xl:!px-2"
          >
            <div
              @click="openLanguage"
              class="flex cursor-pointer items-center justify-center gap-3 !rounded-xl p-3 text-white hover:text-main-pink max-xl:w-full"
              :class="{
                'size-[45px]': !railNav,
              }"
            >
              <Language class="size-5 hover:text-main-pink" />
            </div>
            <v-tooltip
              v-if="width >= 1280"
              activator="parent"
              class="custom-tooltip"
            >
              <div
                class="flex h-[45px] w-[160px] items-center justify-center !rounded-xl bg-white p-3 shadow-lg duration-200"
              >
                <div class="flex flex-1 items-center justify-between pl-2">
                  <div
                    class="menu-title flex-1 text-sm font-semibold leading-[150%] text-black"
                  >
                    {{ $t("common.change_language") }}
                  </div>
                </div>
              </div>
            </v-tooltip>
          </div>
        </div>
      </div>
      <StickyNav class="block xl:hidden" v-if="width < BREAKPOINT && railNav" />
    </div>
  </component>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useDisplay } from "vuetify";
import { VOverlay } from "vuetify/components";
import Language from "~/components/icon/Language.vue";
import LobbyType from "~/components/layouts/LobbyType.vue";
import NavbarList from "~/components/layouts/NavbarList.vue";
import { configEnum, DISPLAY_SECTION } from "~/config/enum";
import { ROUTES_NAME } from "~/config/router";
import { useGetGameCategories } from "~/queries/gameService";
import { useLanguageStore } from "~/stores/languageStore";
import { useNavbarSearch } from "~/stores/navbarSearchStore";
import { useSystemStore } from "~/stores/systemStore";
import { INavBarItem, NavbarEnum } from "~/types/schema";

const BREAKPOINT = configEnum.BREAK_POINT_SCREEN;
const { width } = useDisplay();
const { railNav } = storeToRefs(useSystemStore());
const { open } = storeToRefs(useLanguageStore());

const openLanguage = () => {
  railNav.value = true;
  setTimeout(() => {
    open.value = true;
  }, 400);
};

const { open: navbarSearch } = storeToRefs(useNavbarSearch());
const route = useRoute();

watch(
  () => route.path,
  () => {
    if (width.value < BREAKPOINT) {
      railNav.value = false;
    }
  }
);

const { data: listCategories } = useGetGameCategories();

const navbarList = useNavbar();
let lastId = navbarList.value.at(-1)?.id || 0;

const categories = computed((): INavBarItem[] => {
  return (listCategories.value || [])
    .filter((item) => item?.display_sections?.includes(DISPLAY_SECTION.IN_MENU))
    .map((item) => {
      return {
        id: (lastId += 1),
        name: item.name,
        url: ROUTES_NAME.GAME_CATEGORIES_DETAIL(item.slug),
        disabled: false,
        hidden: false,
        image: item.image || "",
      };
    })
    .concat(
      navbarActive.value.filter(
        (item) => item.id === NavbarEnum.PROVIDERS
      ) as any
    );
});

const navbarActive = computed(() => {
  return navbarList.value.filter((item) => !item.hidden);
});
</script>
<style scoped lang="scss">
:deep(.overflow-y-auto) {
  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(253, 63, 204, 0.3);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(253, 63, 204, 0.3);
  }
}

:deep(.v-navigation-drawer__content) {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

:deep(.group-tooltip:not(.disabled):not(.challenge)) {
  &:hover {
    svg path {
      stroke: theme("colors.main-pink");
    }

    svg .rect {
      fill: theme("colors.main-pink");
    }

    .menu-title {
      color: theme("colors.main-pink") !important;
    }
  }
}

:deep(.challenge:not(.disabled)) {
  &:hover {
    svg {
      fill: theme("colors.main-pink");
    }

    .menu-title {
      color: theme("colors.main-pink") !important;
    }
  }
}

.navigation-drawer-custom-scrim + .v-navigation-drawer__scrim {
  position: fixed;
}
</style>
