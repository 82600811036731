import { default as _91slug_93QjQBnbhYaBMeta } from "/var/lib/jenkins/workspace/FE/client/pages/blogs/[slug].vue?macro=true";
import { default as indexXwTXzN0yT9Meta } from "/var/lib/jenkins/workspace/FE/client/pages/blogs/index.vue?macro=true";
import { default as tags_46clientMQCICiqvzDMeta } from "/var/lib/jenkins/workspace/FE/client/pages/blogs/tags.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/var/lib/jenkins/workspace/FE/node_modules/nuxt/dist/components/runtime/client-component.js").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as _91slug_93DvxdFYBL9QMeta } from "/var/lib/jenkins/workspace/FE/client/pages/categories/[slug].vue?macro=true";
import { default as indexdG7tVOOyVtMeta } from "/var/lib/jenkins/workspace/FE/client/pages/challenges/index.vue?macro=true";
import { default as verifyAuh74rQiptMeta } from "/var/lib/jenkins/workspace/FE/client/pages/email/verify.vue?macro=true";
import { default as indexxkcyYpNcqEMeta } from "/var/lib/jenkins/workspace/FE/client/pages/favorite/index.vue?macro=true";
import { default as indexqB8GvT75elMeta } from "/var/lib/jenkins/workspace/FE/client/pages/forgot-password/index.vue?macro=true";
import { default as send_45successc0Dvt1JnTIMeta } from "/var/lib/jenkins/workspace/FE/client/pages/forgot-password/send-success.vue?macro=true";
import { default as index4cEM262gNzMeta } from "/var/lib/jenkins/workspace/FE/client/pages/gaming-history/index.vue?macro=true";
import { default as index_46cliento8vsq2TTnzMeta } from "/var/lib/jenkins/workspace/FE/client/pages/inboxes/index.client.vue?macro=true";
import { default as index4hTyHRhGe6Meta } from "/var/lib/jenkins/workspace/FE/client/pages/index.vue?macro=true";
import { default as indexoF5SKiXm1dMeta } from "/var/lib/jenkins/workspace/FE/client/pages/live-casino/index.vue?macro=true";
import { default as indexBDo0c3LSdqMeta } from "/var/lib/jenkins/workspace/FE/client/pages/login/index.vue?macro=true";
import { default as update_45emailUKClVgRX8XMeta } from "/var/lib/jenkins/workspace/FE/client/pages/login/update-email.vue?macro=true";
import { default as indexBu9MPqGHWjMeta } from "/var/lib/jenkins/workspace/FE/client/pages/not-available/index.vue?macro=true";
import { default as _91slug_93k1JGCh0NvpMeta } from "/var/lib/jenkins/workspace/FE/client/pages/page-detail/[slug].vue?macro=true";
import { default as _91token_93DZyk1sqtuiMeta } from "/var/lib/jenkins/workspace/FE/client/pages/password/reset/[token].vue?macro=true";
import { default as indexLdndmfEYw2Meta } from "/var/lib/jenkins/workspace/FE/client/pages/play-again/index.vue?macro=true";
import { default as _91slug_93pTge7rx6DrMeta } from "/var/lib/jenkins/workspace/FE/client/pages/play-game/[slug].vue?macro=true";
import { default as index6lLpeiULkVMeta } from "/var/lib/jenkins/workspace/FE/client/pages/prizes/index.vue?macro=true";
import { default as change_45passwordIiKZuNdiWEMeta } from "/var/lib/jenkins/workspace/FE/client/pages/profile/change-password.vue?macro=true";
import { default as my_45profileHhNXPgcm7AMeta } from "/var/lib/jenkins/workspace/FE/client/pages/profile/my-profile.vue?macro=true";
import { default as _91slug_93OtvdchQMpaMeta } from "/var/lib/jenkins/workspace/FE/client/pages/promotion/[slug].vue?macro=true";
import { default as indexHAk7Uhl60pMeta } from "/var/lib/jenkins/workspace/FE/client/pages/promotion/index.vue?macro=true";
import { default as _91id_93Yq2fq9guDQMeta } from "/var/lib/jenkins/workspace/FE/client/pages/provider/[id].vue?macro=true";
import { default as indexLAKUia1II6Meta } from "/var/lib/jenkins/workspace/FE/client/pages/provider/index.vue?macro=true";
import { default as indexHyxNxJOMKhMeta } from "/var/lib/jenkins/workspace/FE/client/pages/sign-up/index.vue?macro=true";
import { default as welcomen49JhgsW1wMeta } from "/var/lib/jenkins/workspace/FE/client/pages/sign-up/welcome.vue?macro=true";
import { default as _91slug_93f531rhkxKmMeta } from "/var/lib/jenkins/workspace/FE/client/pages/tournaments/[slug].vue?macro=true";
import { default as indexUgPdy5e5teMeta } from "/var/lib/jenkins/workspace/FE/client/pages/tournaments/index.vue?macro=true";
import { default as index0IrL5JtcxAMeta } from "/var/lib/jenkins/workspace/FE/client/pages/transaction-history/index.vue?macro=true";
import { default as indexZL8IIAjVb7Meta } from "/var/lib/jenkins/workspace/FE/client/pages/vip-program/index.vue?macro=true";
import { default as x_45forwarded_45for_45testuy23HHgJtmMeta } from "/var/lib/jenkins/workspace/FE/client/pages/x-forwarded-for-test.vue?macro=true";
import { default as component_45stubp1YHn5lF2vMeta } from "/var/lib/jenkins/workspace/FE/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubp1YHn5lF2v } from "/var/lib/jenkins/workspace/FE/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "blogs-slug",
    path: "/blogs/:slug()",
    meta: _91slug_93QjQBnbhYaBMeta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/blogs/[slug].vue")
  },
  {
    name: "blogs",
    path: "/blogs",
    meta: indexXwTXzN0yT9Meta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/blogs/index.vue")
  },
  {
    name: "blogs-tags",
    path: "/blogs/tags",
    meta: tags_46clientMQCICiqvzDMeta || {},
    component: () => createClientPage(() => import("/var/lib/jenkins/workspace/FE/client/pages/blogs/tags.client.vue"))
  },
  {
    name: "categories-slug",
    path: "/categories/:slug()",
    meta: _91slug_93DvxdFYBL9QMeta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/categories/[slug].vue")
  },
  {
    name: "challenges",
    path: "/challenges",
    meta: indexdG7tVOOyVtMeta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/challenges/index.vue")
  },
  {
    name: "email-verify",
    path: "/email/verify",
    meta: verifyAuh74rQiptMeta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/email/verify.vue")
  },
  {
    name: "favorite",
    path: "/favorite",
    meta: indexxkcyYpNcqEMeta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/favorite/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: indexqB8GvT75elMeta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/forgot-password/index.vue")
  },
  {
    name: "forgot-password-send-success",
    path: "/forgot-password/send-success",
    meta: send_45successc0Dvt1JnTIMeta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/forgot-password/send-success.vue")
  },
  {
    name: "gaming-history",
    path: "/gaming-history",
    meta: index4cEM262gNzMeta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/gaming-history/index.vue")
  },
  {
    name: "inboxes",
    path: "/inboxes",
    meta: index_46cliento8vsq2TTnzMeta || {},
    component: () => createClientPage(() => import("/var/lib/jenkins/workspace/FE/client/pages/inboxes/index.client.vue"))
  },
  {
    name: "index",
    path: "/",
    meta: index4hTyHRhGe6Meta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/index.vue")
  },
  {
    name: "live-casino",
    path: "/live-casino",
    meta: indexoF5SKiXm1dMeta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/live-casino/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: indexBDo0c3LSdqMeta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/login/index.vue")
  },
  {
    name: "login-update-email",
    path: "/login/update-email",
    meta: update_45emailUKClVgRX8XMeta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/login/update-email.vue")
  },
  {
    name: "not-available",
    path: "/not-available",
    meta: indexBu9MPqGHWjMeta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/not-available/index.vue")
  },
  {
    name: "page-detail-slug",
    path: "/page-detail/:slug()",
    meta: _91slug_93k1JGCh0NvpMeta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/page-detail/[slug].vue")
  },
  {
    name: "password-reset-token",
    path: "/password/reset/:token()",
    meta: _91token_93DZyk1sqtuiMeta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/password/reset/[token].vue")
  },
  {
    name: "play-again",
    path: "/play-again",
    meta: indexLdndmfEYw2Meta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/play-again/index.vue")
  },
  {
    name: "play-game-slug",
    path: "/play-game/:slug()",
    meta: _91slug_93pTge7rx6DrMeta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/play-game/[slug].vue")
  },
  {
    name: "prizes",
    path: "/prizes",
    meta: index6lLpeiULkVMeta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/prizes/index.vue")
  },
  {
    name: "profile-change-password",
    path: "/profile/change-password",
    meta: change_45passwordIiKZuNdiWEMeta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/profile/change-password.vue")
  },
  {
    name: "profile-my-profile",
    path: "/profile/my-profile",
    meta: my_45profileHhNXPgcm7AMeta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/profile/my-profile.vue")
  },
  {
    name: "promotion-slug",
    path: "/promotion/:slug()",
    meta: _91slug_93OtvdchQMpaMeta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/promotion/[slug].vue")
  },
  {
    name: "promotion",
    path: "/promotion",
    meta: indexHAk7Uhl60pMeta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/promotion/index.vue")
  },
  {
    name: "provider-id",
    path: "/provider/:id()",
    meta: _91id_93Yq2fq9guDQMeta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/provider/[id].vue")
  },
  {
    name: "provider",
    path: "/provider",
    meta: indexLAKUia1II6Meta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/provider/index.vue")
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: indexHyxNxJOMKhMeta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/sign-up/index.vue")
  },
  {
    name: "sign-up-welcome",
    path: "/sign-up/welcome",
    meta: welcomen49JhgsW1wMeta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/sign-up/welcome.vue")
  },
  {
    name: "tournaments-slug",
    path: "/tournaments/:slug()",
    meta: _91slug_93f531rhkxKmMeta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/tournaments/[slug].vue")
  },
  {
    name: "tournaments",
    path: "/tournaments",
    meta: indexUgPdy5e5teMeta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/tournaments/index.vue")
  },
  {
    name: "transaction-history",
    path: "/transaction-history",
    meta: index0IrL5JtcxAMeta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/transaction-history/index.vue")
  },
  {
    name: "vip-program",
    path: "/vip-program",
    meta: indexZL8IIAjVb7Meta || {},
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/vip-program/index.vue")
  },
  {
    name: "x-forwarded-for-test",
    path: "/x-forwarded-for-test",
    component: () => import("/var/lib/jenkins/workspace/FE/client/pages/x-forwarded-for-test.vue")
  },
  {
    name: component_45stubp1YHn5lF2vMeta?.name,
    path: "/lobby",
    component: component_45stubp1YHn5lF2v
  }
]