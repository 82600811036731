import { defineNuxtPlugin, useRoute } from "#app";
import { configEnum } from "~/config/enum";

export default defineNuxtPlugin((nuxtApp) => {
  const route = useRoute();
  const referenceId = route.query.reference_id;
  const affiliateToken = route.query.affiliate_token;

  if (referenceId) {
    const referenceIdCookie = useCustomCookie("reference_id", {
      maxAge: configEnum.COOKIE_EXPIRED,
    });
    referenceIdCookie.value = referenceId as string;
  }

  if (affiliateToken) {
    const affiliateTokenCookie = useCustomCookie("affiliate_token", {
      maxAge: configEnum.COOKIE_EXPIRED,
    });
    affiliateTokenCookie.value = affiliateToken as string;
  }
});
