<template>
  <v-card
    class="w-full max-w-[560px] !bg-main-dark !px-[16px] !pb-[24px] !pt-4 lg:!px-8 lg:pb-10"
    :class="{
      'wallet-setting-no-padding': hideHeader,
    }"
  >
    <template v-if="!hideHeader">
      <div class="relative left-0 flex justify-end lg:left-4">
        <button
          @click="close"
          type="button"
          class="rounded-full hover:bg-main-125"
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.0884 15.0877L24.9123 24.9116"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15.0877 24.9116L24.9116 15.0877"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>

      <div class="relative mb-8 flex justify-center">
        <div
          class="text-center text-[18px] font-bold leading-[132%] text-white lg:text-[24px]"
        >
          {{ $t("common.wallet_settings") }}
        </div>
      </div>
    </template>
    <v-form class="overflow-y-auto">
      <div class="mb-4 flex items-center">
        <div class="flex-1 pr-4">
          <div class="mb-1 text-base font-bold leading-[140%] text-white">
            {{ $t("common.message.hide_zero") }}
          </div>
          <p class="text-[14px] leading-[150%] text-neutral-50">
            {{ $t("common.message.hide_zero_mess") }}
          </p>
        </div>
        <div class="w-[48px] flex-shrink-0">
          <v-switch
            color="primary"
            v-model="hideZeroBalance"
            hide-details
          ></v-switch>
        </div>
      </div>
      <div class="flex items-center">
        <div class="flex-1 pr-4">
          <div class="mb-1 text-base font-bold leading-[140%] text-white">
            {{ $t("common.message.display_flat") }}
          </div>
          <p class="text-[14px] leading-[150%] text-neutral-50">
            {{ $t("common.message.display_flat_mess") }}
          </p>
        </div>
        <div class="w-[48px] flex-shrink-0">
          <v-switch
            color="primary"
            v-model="displayInFlat"
            hide-details
          ></v-switch>
        </div>
      </div>
      <div class="mt-4" :class="{ disabled: !displayInFlat }">
        <div class="grid grid-cols-2 gap-4 lg:grid-cols-3">
          <div
            v-for="(currency, index) in listCurrencies?.currencies"
            :key="index"
            class="flex items-center"
          >
            <input
              v-model="selectedCurrency"
              type="radio"
              class="hidden"
              :id="`wallet-input-${index + 1}`"
              :value="currency.name"
            />
            <label
              class="flex items-center gap-[14px]"
              :for="`wallet-input-${index + 1}`"
            >
              <div
                class="flex h-6 w-6 cursor-pointer items-center justify-center !rounded-md border !border-main-125 bg-main-150"
              >
                <svg
                  v-if="currency.name === selectedCurrency"
                  width="18"
                  height="14"
                  viewBox="0 0 18 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.6667 2L6.58333 12.0833L2 7.5"
                    stroke="#FD3FCC"
                    stroke-width="2.4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="flex cursor-pointer items-center">
                <img
                  class="mr-2 block h-4 w-4 flex-shrink-0 rounded-full"
                  :src="currency.image"
                  :alt="currency.name"
                />
                <div
                  class="text-base font-medium uppercase leading-[150%] text-white"
                >
                  {{ currency.name }}
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
      <div class="line-x my-6 h-0.5"></div>
      <div>
        <div class="mb-4 text-base font-bold leading-[140%] text-white">
          {{ $t("game_currency") }}
        </div>
        <div class="min-h-[60px]">
          <div class="grid grid-cols-2 gap-4 lg:grid-cols-3">
            <div
              v-for="(currency, index) in listCurrencies?.currencies"
              :key="index"
              class="flex items-center"
            >
              <div
                class="flex cursor-pointer items-center gap-3.5"
                @click="newGameCurrency = currency.name"
              >
                <div
                  class="flex size-6 items-center justify-center !rounded-md border !border-main-125 bg-main-150"
                >
                  <svg
                    v-if="currency.name === newGameCurrency"
                    width="18"
                    height="14"
                    viewBox="0 0 18 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.6667 2L6.58333 12.0833L2 7.5"
                      stroke="#FD3FCC"
                      stroke-width="2.4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div class="flex items-center">
                  <img
                    class="mr-2 block h-4 w-4 flex-shrink-0 rounded-full"
                    :src="currency.image"
                    :alt="currency.name"
                  />
                  <div
                    class="text-base font-medium uppercase leading-[150%] text-white"
                  >
                    {{ currency.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2 text-center xs:mt-4">
        <button
          type="button"
          @click="submitData"
          class="mx-auto h-[56px] rounded-lg !bg-linear-primary px-8 font-extrabold text-white"
        >
          {{ $t("common.btn.save") }}
        </button>
      </div>
    </v-form>
  </v-card>
</template>
<script setup lang="ts">
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { useAuthStore } from "~/stores/authStore";
import { useSystemStore } from "~/stores/systemStore";
import { useWalletStore } from "~/stores/walletStore";

type Props = {
  hideHeader?: boolean;
};

defineProps<Props>();
const useSystem = useSystemStore();
const walletStore = useWalletStore();
const authStore = useAuthStore();
const { getListCurrencies } = storeToRefs(walletStore);
const { user } = storeToRefs(authStore);
const { fetchUser } = authStore;
const { updateWalletSettingApi } = walletStore;
const newGameCurrency = ref(null);
watch(
  () => user.value?.player_information?.game_currency,
  (current) => {
    if (current) newGameCurrency.value = current;
  },
  {
    immediate: true,
  }
);

const listCurrencies = ref(getListCurrencies);
const userCurrency = ref(user.value?.player_information?.wallet_currency);
const userHideZeroSetting = ref(
  user.value?.player_information?.hide_zero_balance
);

const close = () => {
  useSystem.changeWalletSettingsDialog(false);
};

const hideZeroBalance = ref(!!userHideZeroSetting.value);
const displayInFlat = ref(!!userCurrency.value);

const selectedCurrency = ref(
  userCurrency.value || listCurrencies.value.currencies[0].name
);

const { walletTransactionState } = storeToRefs(useSystem);
const submitData = async () => {
  const data: any = {
    wallet_currency: displayInFlat.value ? selectedCurrency.value : null,
    hide_zero_balance: hideZeroBalance.value,
    game_currency: newGameCurrency.value,
  };
  await updateWalletSettingApi(data);
  walletTransactionState.value = false;
};
onMounted(() => {
  fetchUser();
});
</script>
<style scoped lang="scss">
.wallet-setting-no-padding {
  padding: 0 !important;
  margin: 0 !important;
}

:deep(.v-selection-control-group) {
  display: inline-block;
  column-count: 2;
  @media (min-width: 992px) {
    column-count: 3;
  }
}

:deep(.v-switch__track) {
  background: #343843;
  opacity: 1;
  height: 24px;
  width: 48px;
  flex-shrink: 0;

  &.bg-primary {
    background: #fd3fcc !important;
  }
}

:deep(.v-switch__thumb) {
  background: #fff !important;
  box-shadow: none;
}

:deep(.v-switch) {
  .v-selection-control__input {
    transform: translateX(-12px);
  }

  .v-selection-control--dirty {
    .v-selection-control__input {
      transform: translateX(12px);
    }
  }
}

:deep(.btn-gradient) {
  background: linear-gradient(
    270.46deg,
    #ff3eca 0.23%,
    #9b37ff 40%,
    #8378ff 55.55%,
    #53ccff 98.94%
  );
  font-size: 15px;
  font-weight: 700;
  line-height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-left: 12px;
    font-size: 21px;
  }
}

:deep(.mdi-radiobox-blank) {
  border: 2px solid #6626c1;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font-size: unset;

  &:before {
    content: "";
  }
}

:deep(.mdi-radiobox-marked) {
  border: 2px solid #6626c1;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font-size: unset;

  &:before {
    content: "";
    width: 10px;
    height: 10px;
    background: #fd3fcc;
    border-radius: 50%;
  }
}

:deep(.v-selection-control) {
  .v-label {
    opacity: 1;
  }
}

:deep(.line-x) {
  background: linear-gradient(
    90deg,
    rgba(192, 173, 216, 0) 0%,
    rgba(189, 173, 216, 0.4) 49.48%,
    rgba(192, 173, 216, 0) 100%
  );
}

.v-card {
  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
