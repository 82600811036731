<template>
  <div class="flex items-center justify-center py-6">
    <div
      class="flex h-5 w-[200px] items-center justify-center gap-2"
      :class="[loadingMore ? 'opacity-1' : 'opacity-0']"
      v-intersect="{
        handler: loadMore,
        options: {
          threshold: [1.0, 1.0, 1.0],
        },
      }"
    >
      <span class="loader !size-6"></span>
      <span class="text-sm font-medium text-white">
        {{ $t("common.message.loading") }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useGameStore } from "~/stores/gameStore";

const gameStore = useGameStore();
const { getGameByCategory } = gameStore;
const props = defineProps([
  "selectedCategory",
  "keyword",
  "gameList",
  "allowFetch",
  "loadingMore",
]);
const emit = defineEmits(["updateGameList", "fetchData", "setLoadingMore"]);

const loadingMore = ref(props.loadingMore);

const loadMore = async (isIntersecting: any) => {
  if (!isIntersecting) return;

  const lengthData =
    props.selectedCategory === "favorite"
      ? gameStore.listGameByCategory[props.selectedCategory]?.listGame
          ?.gameOrginal?.length
      : gameStore.listGameByCategory[props.selectedCategory]?.listGame?.games
          ?.length;
  if (
    Number(lengthData) <
    gameStore.listGameByCategory[props.selectedCategory]?.total
  ) {
    // emit loading to parent
    emit("setLoadingMore", true);
    const currentPage =
      gameStore.listGameByCategory[props.selectedCategory]?.listGame
        ?.pageLoaded;
    if (props.allowFetch) {
      const dataGame = await getGameByCategory(
        props.selectedCategory,
        currentPage + 1,
        false,
        props.keyword
      );
      const newGameList = [...props.gameList, ...dataGame];
      emit("updateGameList", newGameList);
    } else {
      emit("fetchData", currentPage);
    }
  }
};

// after parent change loading state directly, watch in child to re-update the state
watch(
  () => props.loadingMore,
  (current) => {
    loadingMore.value = current;
  }
);
</script>

<style scoped></style>
