import validate from "/var/lib/jenkins/workspace/FE/node_modules/nuxt/dist/pages/runtime/validate.js";
import _1_45check_45location_45global from "/var/lib/jenkins/workspace/FE/client/middleware/1.check-location.global.ts";
import _2_45redirect_45global from "/var/lib/jenkins/workspace/FE/client/middleware/2.redirect.global.ts";
import _3_45track_45previous_45route_45global from "/var/lib/jenkins/workspace/FE/client/middleware/3.trackPreviousRoute.global.ts";
import _4_45scroll_45to_45top_45global from "/var/lib/jenkins/workspace/FE/client/middleware/4.scroll-to-top.global.ts";
import manifest_45route_45rule from "/var/lib/jenkins/workspace/FE/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _1_45check_45location_45global,
  _2_45redirect_45global,
  _3_45track_45previous_45route_45global,
  _4_45scroll_45to_45top_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/var/lib/jenkins/workspace/FE/client/middleware/auth.ts"),
  "check-completed": () => import("/var/lib/jenkins/workspace/FE/client/middleware/checkCompleted.ts"),
  "fetch-common-api": () => import("/var/lib/jenkins/workspace/FE/client/middleware/fetchCommonApi.ts"),
  guest: () => import("/var/lib/jenkins/workspace/FE/client/middleware/guest.ts")
}