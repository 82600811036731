<template>
  <Scrollbar class="max-h-[40vh] py-0.5 pr-3">
    <div v-if="listAvailable.length" class="space-y-4">
      <div v-for="data in listAvailable" :key="data.id" class="available-item">
        <div class="mb-4 flex items-center justify-between gap-2">
          <div class="flex items-center gap-3 truncate">
            <img src="/images/icon/bonus-star-icon-new.svg" alt="" />
            <p class="truncate text-sm font-bold text-white">
              {{ data.title || "" }}
            </p>
          </div>
          <button
            @click="
              () => {
                if (data.is_deposit_requirement) {
                  claim(data);
                } else {
                  selectedBonus = data;
                  isOpenConfirm = true;
                }
              }
            "
            type="button"
            :disabled="!!data.is_claim"
            class="flex flex-shrink-0 items-center gap-2 rounded-full bg-main-pink text-xs font-bold text-white"
            :class="data.is_claim ? 'px-4 py-1' : 'px-6 py-2'"
          >
            {{
              data.is_claim ? $t("common.btn.claimed") : $t("common.btn.claim")
            }}
            <img
              v-if="data.is_claim"
              src="/images/icon/icon-checked.svg"
              alt=""
            />
          </button>
        </div>
        <CollapseItem
          :data="data"
          :open-details="openDetails"
          @update-list-open-details="
            (id) => (openDetails = handleBonusDetailsCollapse(id, openDetails))
          "
        >
          <div class="flex flex-col overflow-hidden">
            <detail-item
              :label="$t('common.status')"
              :value="$t(getBonusStatusText(data.status))"
            />
            <detail-item :label="$t('common.id')" :value="data.id" />
            <detail-item :label="$t('bonus.available.valid_coins')">
              <template #value>
                <div
                  v-if="data.valid_coins.length"
                  class="flex flex-wrap items-center gap-2"
                >
                  <div
                    v-for="coin in data.valid_coins"
                    :key="coin"
                    class="h-5 w-5 flex-shrink-0"
                  >
                    <img
                      :src="getWalletImageByCode(coin)"
                      :alt="coin"
                      class="h-full w-full object-contain"
                    />
                  </div>
                </div>
              </template>
            </detail-item>
            <detail-item
              :label="$t('bonus.available.received_on')"
              :value="
                data.received_on
                  ? dayjs(data.received_on).format(
                      `YYYY-MM-DD [${$t('bonus.available.at')}] HH:mm`
                    )
                  : ''
              "
            />
            <detail-item
              :label="
                data.status === BONUS_STATUS.AVAILABLE
                  ? $t('bonus.available.expiry_claim_time')
                  : $t('bonus.available.expiry_deposit_time')
              "
            >
              <template #value>
                <p class="font-bold text-white">
                  {{ data.days }}
                  <span class="font-normal">{{ $t("common.days") }}</span>
                  :
                  {{ data.hours }}
                  <span class="font-normal">{{ $t("common.hours") }}</span>
                  :
                  {{ data.mins }}
                  <span class="font-normal">{{ $t("common.mins") }}</span>
                </p>
              </template>
            </detail-item>
            <detail-item
              v-if="
                (data.type === BONUS_TYPE.WELCOME_BONUS_OFFER ||
                  data.type === BONUS_TYPE.DEPOSIT_BONUS_OFFER) &&
                data.is_deposit_requirement
              "
              :label="$t('bonus.available.bonus_percent')"
              :value="`${data.percentage_deposit || 0}%`"
            />
            <detail-item
              :label="$t('common.wagering_required')"
              :value="`${data.bet_requirement || 0}X`"
            />
            <detail-item
              v-if="data.min_deposit_amount"
              :label="$t('bonus.available.minimum_deposit')"
              :value="defaultFiatAmountDisplay(data.min_deposit_amount)"
            />
            <detail-item
              v-if="data.max_deposit_amount"
              :label="$t('bonus.available.maximum_deposit')"
              :value="defaultFiatAmountDisplay(data.max_deposit_amount)"
            />
            <detail-item
              v-if="data.max_bet_per_round"
              :label="$t('bonus.available.max_bet_per_round')"
              :value="defaultFiatAmountDisplay(data.max_bet_per_round)"
            />
            <detail-item
              v-if="data.type === BONUS_TYPE.FREE_SPINS_OFFER"
              :label="$t('bonus.available.total_free_spins')"
              :value="data.fs_round"
            />
            <detail-item
              v-if="data.type === BONUS_TYPE.FREE_SPINS_OFFER"
              :label="$t('bonus.available.bet_amount_spin')"
              :value="getbetAmountOverSpin(data.fs_amount)"
            />
            <detail-item
              v-if="data.type === BONUS_TYPE.FREE_SPINS_OFFER"
              :label="$t('common.game')"
              :value="data.game_name"
            />
            <detail-item
              v-if="data.entity_wallet_code && data.added_bonus_amount"
              :label="$t('bonus.available.bonus_amount')"
            >
              <template #value>
                <div class="flex items-center gap-2">
                  <div class="h-4 w-4 flex-shrink-0">
                    <img
                      :src="getWalletImageByCode(data.entity_wallet_code)"
                      :alt="data.entity_wallet_code"
                      class="h-full w-full object-contain"
                    />
                  </div>

                  <p class="flex-grow text-nowrap font-bold text-white">
                    {{ data.added_bonus_amount }}
                  </p>
                </div>
              </template>
            </detail-item>
            <detail-item
              :label="$t('bonus.available.is_deposit_required')"
              :value="
                data.is_deposit_requirement ? $t('common.yes') : $t('common.no')
              "
            />
            <p
              class="term-and-condition mt-2 text-xs text-[#ef3054]"
              v-if="data.term_and_condition"
              v-html="data.term_and_condition"
            ></p>
          </div>
        </CollapseItem>
      </div>
    </div>
    <div
      v-else
      class="flex h-[300px] items-center justify-center text-sm text-white"
    >
      {{ $t("common.message.no_bonus_found") }}
    </div>
    <v-dialog v-model="isOpenConfirm" width="auto">
      <lazy-bonus-confirm-claim
        v-if="isOpenConfirm"
        @close="isOpenConfirm = false"
        @claim="(selectedCoin: string) => claim(selectedBonus, selectedCoin)"
        :bonus="selectedBonus"
      />
    </v-dialog>
  </Scrollbar>
</template>

<script setup lang="ts">
import dayjs from "dayjs";
import { BONUS_STATUS, BONUS_TYPE, CLAIM_TYPE } from "~/config/enum";
import { useGetAvailableBonus } from "~/queries/bonusService";
import { claimBonus } from "~/service/bonusService";
import { useBonusStore } from "~/stores/bonusStore";
import { useSystemStore } from "~/stores/systemStore";
import { bonusData } from "~/types/schema";
import {
  startCountdownBonus,
  getBonusStatusText,
  getGameCurrencyDefault,
  getWalletImageByCode,
} from "~/utils";
import CollapseItem from "./CollapseItem.vue";
import DetailItem from "./DetailItem.vue";

const { $toast, $loading, $configLoading, $i18n } = useNuxtApp();
const { t } = $i18n || {};
const bonusStore = useBonusStore();
const systemStore = useSystemStore();
const { changeWalletTransactionDialog, changeBonusDialog } = systemStore;

const { data: listAvailable } = useGetAvailableBonus();
const { setAllowFetch, handleReadBonus } = bonusStore;
const emit = defineEmits(["updateBonusTab"]);
const isOpenConfirm = ref(false);
const selectedBonus = ref();

const openDetails = ref<number[]>();

const getbetAmountOverSpin = (value: any) => {
  const unit = getGameCurrencyDefault()?.symbol;
  return `${unit}${value}`;
};

watch(
  listAvailable,
  () => {
    openDetails.value = listAvailable.value?.map((item: bonusData) => item.id);
    const isUnreadBonus = listAvailable.value?.some(
      (item: bonusData) => !item.read_at
    );
    if (isUnreadBonus) {
      void handleReadBonus();
    }
    startCountdownBonus({
      data: listAvailable.value,
      field: "end_claim_time",
    });
  },
  {
    immediate: true,
  }
);

const claim = async (data: bonusData, selectedCoin?: string) => {
  let loader: any = null;
  setAllowFetch(false);
  try {
    loader = $loading.show($configLoading);
    const response = await claimBonus(
      data.id,
      CLAIM_TYPE.CLAIMED,
      data.is_deposit_requirement ? "" : selectedCoin
    );
    if (response.data.success) {
      bonusStore.listAvailable = response?.data?.data?.available_bonus;
      if (data.is_deposit_requirement) {
        // hide bonus dialog and open deposit wallet dialog
        changeBonusDialog(false);
        bonusStore.bonusTypeParams = data.id;
        setTimeout(() => {
          $toast.success(t("common.message.claimed_mess_1"));
          changeWalletTransactionDialog(true);
        }, 300);
      } else {
        if (
          data.type === BONUS_TYPE.WELCOME_BONUS_OFFER ||
          data.type === BONUS_TYPE.DEPOSIT_BONUS_OFFER
        ) {
          $toast.success(
            t("common.message.claimed_mess_2", {
              amount: data.added_bonus_amount || 0,
              code: data.entity_wallet_code,
            })
          );
        } else if (data.type === BONUS_TYPE.FREE_SPINS_OFFER) {
          $toast.success(t("common.message.claimed_mess_3"));
        } else {
          $toast.success(t("common.message.claimed_mess_1"));
        }
        setTimeout(() => {
          // move to Active tab
          emit("updateBonusTab");
        }, 300);
      }
    }
  } catch (error: any) {
    const message = error?.response?.data?.message;
    $toast.error(message);
  } finally {
    loader.hide();
  }
};
</script>

<style scoped lang="scss"></style>
