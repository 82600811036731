import { defineNuxtRouteMiddleware, navigateTo } from "#app";
import { ROUTES_NAME } from "~/config/router";

export default defineNuxtRouteMiddleware((to) => {
  const tokenCookie = useToken();

  if (!tokenCookie.value) {
    const loginUrl = `${ROUTES_NAME.LOGIN}?backToUrl=${encodeURIComponent(to.fullPath)}`;
    return navigateTo(loginUrl, { replace: true });
  }
});
