<template>
  <HomeBannerCarousel
    v-if="sliders.length"
    :sliders="sliders"
    class="mb-8 w-full overflow-hidden"
  />
</template>
<script lang="ts" setup>
import { DISPLAY_STATUS } from "~/config/enum";
import { useGetListImageSliders } from "~/queries/gameService";

type Props = {
  displaySectionBanner: string;
};
const props = defineProps<Props>();
const { data: rawSliders } = useGetListImageSliders();
const token = useToken();

const sliders = computed((): any[] => {
  const displayStatus = token.value
    ? DISPLAY_STATUS.LOGGED_IN
    : DISPLAY_STATUS.LOGGED_OUT;
  return (rawSliders.value || []).reduce((accumulator: any, slideItem: any) => {
    if (
      slideItem.display_sections.includes(props.displaySectionBanner) &&
      slideItem.display_statuses.includes(displayStatus)
    ) {
      accumulator.push({
        id: slideItem.id,
        title: slideItem.title,
        content: slideItem.description,
        srcPc: slideItem.image_url,
        srcSp: slideItem.image_url,
        urlRedirect: slideItem.redirect_url,
        button: slideItem.button_text,
      });
    }
    return accumulator;
  }, []);
});
</script>
