<template>
  <div class="px-3.5 pt-4 lg:!px-10 lg:!pt-6">
    <ImageSlider :display-section-banner="displaySectionBanner" />
    <div class="mb-8 flex gap-2 px-1.5 lg:!mb-12">
      <ListLinkCategory
        :categories-ref="categorySection"
        :mainRoute="mainRoute"
        :mainHomeLabel="mainHomeLabel"
        :mainIcon="mainIcon"
        class="md:max-w-[75%]"
        :selectedCategory="selectedCategory"
        @updateData="setSelectedCategory"
      />
      <InputSearch
        :search="search"
        :on-search="setSearchValue"
        :placeholder="$t(searchPlaceholder)"
      />
    </div>

    <div v-if="selectedCategory || search" class="px-1.5">
      <SearchGameSection
        :search="search"
        :selected-category="selectedCategory"
      />
    </div>

    <div class="min-h-[500px]" v-else>
      <LazyBiggestWin
        v-if="showExtraSections && token"
        class="mb-8 overflow-hidden lg:!mb-12"
      />
      <template
        v-for="category in categoriesPosition.categoriesTop"
        :key="category.id"
      >
        <LazySlideViewAll
          :key="category.id"
          v-if="category.games?.length"
          :list-data="category.games"
          class="mb-8 overflow-hidden lg:mb-12"
          :slug="category.slug"
          :name="category.name"
          :type="topSection"
          :img="category.image"
        />
      </template>
      <SlideViewAllChallenges />
      <div v-if="token && showExtraSections">
        <LazyPlayAgain class="mb-8 overflow-hidden lg:!mb-12" />
        <LazyLobbyFavorite class="mb-8 overflow-hidden lg:!mb-12" />
      </div>
      <ProviderList
        class="mb-8 overflow-hidden lg:!mb-12"
        v-if="showExtraSections"
      />
      <template
        v-for="category in categoriesPosition.categoriesBottom"
        :key="category.id"
      >
        <LazySlideViewAll
          :key="category.id"
          v-if="category.games?.length"
          :list-data="category.games"
          class="mb-8 overflow-hidden lg:mb-12"
          :slug="category.slug"
          :name="category.name"
          :type="bottomSection"
          :img="category.image"
          is-bottom
        />
      </template>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import ImageSlider from "~/components/lobby/ImageSlider.vue";
import InputSearch from "~/components/lobby/InputSearch.vue";
import SearchGameSection from "~/components/lobby/SearchGameSection.vue";
import { DISPLAY_SECTION } from "~/config/enum";
import { useGetGameCategoriesWithGames } from "~/queries/gameService";
import { GameType, IGameCategoryWithGame } from "~/types/schema";

type Props = {
  mainRoute: string;
  mainHomeLabel: string;
  mainIcon?: string;
  displaySectionBanner: string;
  topSection: DISPLAY_SECTION;
  bottomSection: DISPLAY_SECTION;
  navSection: string;
  showExtraSections?: boolean;
  type: GameType;
};

const props = defineProps<Props>();

const token = useToken();

const { data: categories } = useGetGameCategoriesWithGames(props.type);

const categoriesPosition = computed(() => {
  const categoriesTop: IGameCategoryWithGame[] = [];
  const categoriesBottom: IGameCategoryWithGame[] = [];
  categories.value?.forEach((category) => {
    if (category.display_sections.includes(props.topSection))
      categoriesTop.push(category);
    if (category.display_sections.includes(props.bottomSection))
      categoriesBottom.push(category);
  });
  return {
    categoriesTop,
    categoriesBottom,
  };
});

const categorySection = computed(() => {
  return categories.value?.filter((category) =>
    category.display_sections.includes(props.navSection)
  );
});

const route = useRoute();
const selectedCategory = ref(String(route.query.type || ""));
const search = ref(String(route.query.keyword || ""));

watch(
  [() => route.query.type, () => route.query.keyword],
  ([category, keyword]) => {
    selectedCategory.value = String(category || "");
    search.value = String(keyword || "");
  }
);
const handleNavigate = (keyword: string, type: string) => {
  const query = {
    keyword: keyword || undefined,
    type: type || undefined,
  };
  navigateTo({ path: props.mainRoute, query });
};

const setSearchValue = _debounce((event: any) => {
  search.value = event.target.value;
  handleNavigate(search.value, selectedCategory.value);
}, 700);

const setSelectedCategory = (category: string) => {
  selectedCategory.value = category;
  handleNavigate(search.value, category);
};
const searchPlaceholder = computed(() => {
  return selectedCategory?.value
    ? "common.btn.search"
    : "common.btn.search_all_games";
});

onBeforeUnmount(() => {
  scrollToTop();
});
</script>
