<template>
  <v-dialog
    v-model="isOpen"
    :width="'570px'"
    :max-width="'85%'"
    :no-click-animation="true"
  >
    <div class="!rounded-xl bg-main-dark p-4 text-white">
      <div class="mb-2 flex justify-end">
        <button
          @click="isOpen = false"
          type="button"
          class="rounded-full hover:bg-main-125"
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.0884 15.0877L24.9123 24.9116"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15.0877 24.9116L24.9116 15.0877"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>

      <template v-if="selectedItem">
        <div class="mb-6 flex items-center justify-center gap-2">
          <svg
            class="flex-shrink-0"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.90488 8.80176L4.90332 10.8033"
              stroke="white"
              stroke-width="0.7875"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4.92334 8.82153L6.9249 10.8231"
              stroke="white"
              stroke-width="0.7875"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M9.50439 9.8125H9.51096"
              stroke="white"
              stroke-width="0.7875"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12.0894 9.8125H12.0959"
              stroke="white"
              stroke-width="0.7875"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10.7969 11.1118V11.0986"
              stroke="white"
              stroke-width="0.7875"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10.7969 8.52631V8.51318"
              stroke="white"
              stroke-width="0.7875"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.53125 15.0625H10.4688C13.75 15.0625 15.0625 13.75 15.0625 10.4688V9.15625C15.0625 5.875 13.75 4.5625 10.4688 4.5625H6.53125C3.25 4.5625 1.9375 5.875 1.9375 9.15625V10.4688C1.9375 13.75 3.25 15.0625 6.53125 15.0625Z"
              stroke="white"
              stroke-width="0.7875"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M9.16324 1.9375L9.15668 2.60031C9.15012 2.96125 8.86137 3.25 8.50043 3.25H8.48074C8.11981 3.25 7.83105 3.54531 7.83105 3.90625C7.83105 4.26719 8.12637 4.5625 8.4873 4.5625H9.14355"
              stroke="white"
              stroke-width="0.7875"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span class="truncate text-lg font-extrabold">
            {{ selectedItem.game_name }}:
          </span>
          <span class="text-lg text-neutral-50-new">
            {{ selectedItem.ulid.slice(0, 8) }}
          </span>
          <svg
            v-if="!isCopied"
            class="flex-shrink-0 cursor-pointer"
            @click="copyULID"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.77516 15.6666H4.97516C2.3685 15.6666 1.2085 14.5066 1.2085 11.8999V9.09992C1.2085 6.49325 2.3685 5.33325 4.97516 5.33325H7.77516C10.3818 5.33325 11.5418 6.49325 11.5418 9.09992V11.8999C11.5418 14.5066 10.3818 15.6666 7.77516 15.6666ZM4.97516 6.33325C2.9085 6.33325 2.2085 7.03325 2.2085 9.09992V11.8999C2.2085 13.9666 2.9085 14.6666 4.97516 14.6666H7.77516C9.84183 14.6666 10.5418 13.9666 10.5418 11.8999V9.09992C10.5418 7.03325 9.84183 6.33325 7.77516 6.33325H4.97516Z"
              fill="#9DA5B3"
            />
            <path
              d="M11.7747 11.6666H11.0413C10.768 11.6666 10.5413 11.4399 10.5413 11.1666V9.09992C10.5413 7.03325 9.84134 6.33325 7.77467 6.33325H5.70801C5.43467 6.33325 5.20801 6.10659 5.20801 5.83325V5.09992C5.20801 2.49325 6.36801 1.33325 8.97467 1.33325H11.7747C14.3813 1.33325 15.5413 2.49325 15.5413 5.09992V7.89992C15.5413 10.5066 14.3813 11.6666 11.7747 11.6666ZM11.5413 10.6666H11.7747C13.8413 10.6666 14.5413 9.96659 14.5413 7.89992V5.09992C14.5413 3.03325 13.8413 2.33325 11.7747 2.33325H8.97467C6.90801 2.33325 6.20801 3.03325 6.20801 5.09992V5.33325H7.77467C10.3813 5.33325 11.5413 6.49325 11.5413 9.09992V10.6666Z"
              fill="#9DA5B3"
            />
          </svg>
          <span v-else class="text-[10px] text-neutral-50-new">{{
            $t("common.message.copied")
          }}</span>
        </div>

        <div
          class="mb-6 flex flex-wrap items-center justify-center gap-2 text-xs font-medium"
        >
          <span>{{ $t("components.lobby_transaction.modal.placed_at") }}</span>
          <div
            class="flex max-w-[160px] items-center gap-1 truncate !rounded-lg bg-main-150 px-2 py-1 text-neutral-50-new"
          >
            <div class="h-4 w-4 flex-shrink-0">
              <NuxtImg
                loading="lazy"
                :src="selectedItem.level_image"
                class="h-full w-full object-contain"
                alt=""
                lazy-src="/images/no_icon.svg"
              />
            </div>
            <span class="truncate text-neutral-50-new">
              {{ selectedItem.username }}
            </span>
          </div>
          <span>
            {{ formatDate(selectedItem.created_at, "MMMM Do YYYY, h:mm a") }}
          </span>
        </div>

        <div class="mb-6 truncate !rounded-lg bg-main-150 p-6 md:px-8 md:py-4">
          <div
            class="flex flex-col justify-center gap-6 truncate md:flex-row md:items-center"
          >
            <div
              class="flex items-center justify-between gap-4 truncate border-b !border-main-125 pb-4 md:flex-col md:items-start md:justify-normal md:gap-1 md:!border-b-0 md:border-r md:!pb-0 md:pr-6"
            >
              <span class="text-xs font-bold text-neutral-50-new">{{
                $t("components.lobby_transaction.modal.bet")
              }}</span>
              <div class="flex items-center gap-2 truncate md:w-full">
                <div class="h-4 w-4 flex-shrink-0">
                  <v-img
                    loading="lazy"
                    :src="getWalletImageByCode(selectedItem.entity_wallet_code)"
                    class="h-full w-full object-contain"
                    :title="selectedItem.entity_wallet_code"
                    alt=""
                    lazy-src="/images/no_icon.svg"
                  />
                </div>
                <span class="truncate text-sm font-bold">
                  {{ getAmountDisplay(selectedItem, "bet_amount").amount }}
                </span>
              </div>
            </div>
            <div
              class="flex items-center justify-between gap-4 truncate border-b !border-main-125 pb-4 md:flex-col md:items-start md:justify-normal md:gap-1 md:!border-b-0 md:border-r md:!pb-0 md:pr-6"
            >
              <span class="text-xs font-bold text-neutral-50-new">
                {{ $t("components.lobby_transaction.headers.multiplier") }}
              </span>
              <div class="flex items-center gap-2 truncate md:w-full">
                <svg
                  class="flex-shrink-0"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.9997 7.16683L9.71013 9.45637C9.57813 9.58838 9.51212 9.65438 9.43601 9.67911C9.36907 9.70086 9.29695 9.70086 9.23 9.67911C9.15389 9.65438 9.08789 9.58838 8.95588 9.45637L7.04346 7.54395C6.91146 7.41195 6.84546 7.34594 6.76935 7.32121C6.7024 7.29946 6.63028 7.29946 6.56334 7.32121C6.48723 7.34594 6.42122 7.41195 6.28922 7.54395L3.99967 9.8335M14.6663 8.50016C14.6663 12.1821 11.6816 15.1668 7.99967 15.1668C4.31778 15.1668 1.33301 12.1821 1.33301 8.50016C1.33301 4.81826 4.31778 1.8335 7.99967 1.8335C11.6816 1.8335 14.6663 4.81826 14.6663 8.50016Z"
                    stroke="white"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <span class="truncate text-sm font-bold">
                  {{ selectedItem.multiplier }}x
                </span>
              </div>
            </div>
            <div
              class="flex items-center justify-between gap-4 truncate md:flex-col md:items-start md:justify-normal md:gap-1"
            >
              <span class="text-xs font-bold text-neutral-50-new">
                {{ $t("components.lobby_transaction.headers.payout") }}
              </span>
              <div class="flex items-center gap-2 truncate md:w-full">
                <div class="h-4 w-4 flex-shrink-0">
                  <v-img
                    loading="lazy"
                    :src="getWalletImageByCode(selectedItem.entity_wallet_code)"
                    class="h-full w-full object-contain"
                    :title="selectedItem.entity_wallet_code"
                    alt=""
                    lazy-src="/images/no_icon.svg"
                  />
                </div>
                <span class="truncate text-sm font-bold text-main-green">
                  {{ getAmountDisplay(selectedItem, "payout_amount").amount }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <NuxtLink
          :to="ROUTES_NAME.PLAY_GAME(selectedItem.game_code)"
          class="mb-4 flex w-full items-center justify-center gap-3 !rounded-lg !bg-linear-primary p-4"
        >
          <svg
            class="flex-shrink-0"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.5 4.98951C5.5 4.01835 5.5 3.53277 5.70249 3.2651C5.87889 3.03191 6.14852 2.88761 6.4404 2.87018C6.77544 2.85017 7.17946 3.11953 7.98752 3.65823L18.5031 10.6686C19.1708 11.1137 19.5046 11.3363 19.6209 11.6168C19.7227 11.8621 19.7227 12.1377 19.6209 12.383C19.5046 12.6635 19.1708 12.886 18.5031 13.3312L7.98752 20.3415C7.17946 20.8802 6.77544 21.1496 6.4404 21.1296C6.14852 21.1122 5.87889 20.9679 5.70249 20.7347C5.5 20.467 5.5 19.9814 5.5 19.0103V4.98951Z"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span class="font-extrabold">
            {{ $t("common.btn.play") }} {{ selectedItem.game_name }}
          </span>
        </NuxtLink>
      </template>

      <span v-else class="text-sm">
        {{ $t("common.message.something_went_wrong") }}
      </span>
    </div>
  </v-dialog>
</template>
<script setup lang="ts">
import { API_LOADING_TIMEOUT } from "~/config/constant";
import { ROUTES_NAME } from "~/config/router";

type Props = {
  selectedItem?: any;
};

const props = defineProps<Props>();
const isOpen = defineModel<boolean>();
const isCopied = ref(false);
const copyULID = () => {
  navigator?.clipboard?.writeText(window.location.href);
  isCopied.value = true;
  setTimeout(() => {
    isCopied.value = false;
  }, API_LOADING_TIMEOUT);
};

watch([isOpen, () => props.selectedItem], ([open, item]) => {
  // if isOpen then add query params to the URL {modal: 'bet'}, else remove the query params 'modal' only
  const searchParams = new URLSearchParams(window.location.search);
  if (open && item) {
    searchParams.set("modal", "bet");
    searchParams.set("md-id", item.ulid);
    navigateTo(`?${searchParams.toString()}`);
  } else if (!open) {
    searchParams.delete("modal");
    searchParams.delete("md-id");
    navigateTo(`?${searchParams.toString()}`);
  }
});
</script>
