<template>
  <v-dialog v-model="verifyUserInfoDialog" width="auto">
    <v-card
      class="mx-[10px] w-[calc(100vw-68px)] max-w-[560px] !rounded-[16px] !bg-[#49198E] !px-[32px] !py-[40px]"
    >
      <img
        src="/images/icon/security-user-icon.png"
        alt="security-user-icon"
        class="m-auto my-0 !mb-[24px] w-[102px]"
      />
      <span
        class="mb-[16px] text-center text-[24px] font-[700] uppercase leading-[32px] text-[#ffffff]"
      >
        {{ $t("dialog.verify_user_info.title") }}
      </span>
      <span
        class="m-auto mb-[32px] max-w-[330px] text-center text-[14px] font-[400] leading-[21px] text-[#F2D6FF]"
      >
        {{ $t("dialog.verify_user_info.please_verify_mess") }}
      </span>
      <span class="line-gradient mb-[32px]"></span>
      <NuxtLink
        :to="ROUTES_NAME.PROFILE"
        @click="close()"
        class="btn-gradient m-auto h-[56px] w-[100%] rounded-[100px] uppercase text-white"
      >
        {{ $t("common.message.verify_now") }}
      </NuxtLink>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { storeToRefs } from "pinia";
import { ROUTES_NAME } from "~/config/router";
import { useSystemStore } from "~/stores/systemStore";

const useSystem = useSystemStore();
const { verifyUserInfoDialog } = storeToRefs(useSystem);

const close = () => {
  useSystem.changeVerifyUserInfoDialog(false);
};
</script>
<style scoped lang="scss">
.line-gradient {
  height: 2px;
  width: 100%;
  display: block;
  background: linear-gradient(
    90deg,
    rgba(192, 173, 216, 0) 0%,
    rgba(189, 173, 216, 0.4) 49.48%,
    rgba(192, 173, 216, 0) 100%
  );
}

.btn-gradient {
  background: linear-gradient(
    270.46deg,
    #ff3eca 0.23%,
    #9b37ff 40%,
    #8378ff 55.55%,
    #53ccff 98.94%
  );
  font-size: 15px;
  font-weight: 700;
  line-height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-left: 12px;
    font-size: 21px;
  }

  &:hover {
    opacity: 0.8;
  }
}
</style>
