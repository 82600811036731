<template>
  <div class="relative mx-auto w-full px-4 lg:!px-10">
    <div class="flex items-center justify-between gap-2">
      <NuxtLinkScrollToTop :to="ROUTES_NAME.LOBBY">
        <img
          src="/images/logo.svg"
          class="hidden h-[45px] sm:block lg:h-[30px] xxl:h-[35px]"
          alt="CasinoPunkz"
          loading="lazy"
        />
        <img
          src="/images/logo-sp.svg"
          class="block h-[45px] sm:hidden"
          alt="CasinoPunkz"
          loading="lazy"
        />
      </NuxtLinkScrollToTop>
      <div
        class="flex w-full items-center justify-end gap-5 lg:w-auto lg:justify-normal"
      >
        <div>
          <div class="flex items-center gap-2 lg:gap-4">
            <CommonDropdown
              :listData="listWallets"
              v-model="showWallets"
              v-model:search="search"
              :no-data-message="$t('common.message.no_wallet_found')"
              wrap-class="w-[200px] sm:w-[250px] lg:w-[300px] h-[40px] lg:h-[48px]"
              content-class="w-[300px] sm:w-[350px] lg:w-[300px]'"
              showing-hydrated
              :loading="getWalletsLoading"
            >
              <template #selected>
                <div class="flex items-center truncate">
                  <NuxtImg
                    loading="lazy"
                    v-if="selectedWallet?.entity_wallet_code"
                    class="mr-2 size-4 flex-shrink-0 rounded-full"
                    :src="
                      getWalletImageByCode(selectedWallet.entity_wallet_code)
                    "
                    alt="bitcoin"
                  />
                  <div class="truncate font-medium leading-none text-white">
                    <template v-if="inPlay">
                      {{ `(${$t("in_play")})` }}
                    </template>
                    <template v-else>
                      {{
                        walletStore?.listWalletOriginal?.length
                          ? formatWithWalletCode(
                              selectedWallet?.balance || "",
                              "",
                              8
                            )
                          : ""
                      }}
                    </template>
                  </div>
                </div>
              </template>
              <template #list-dropdown-item>
                <div
                  v-for="(wallet, index) in listWallets"
                  :key="index"
                  class="group cursor-pointer border-b !border-main-125 p-3 hover:bg-main-125"
                  :class="{
                    'bg-main-125':
                      selectedWallet?.entity_wallet_code ===
                      wallet?.entity_wallet_code,
                  }"
                  @click="changeSelectedWallet(wallet.entity_wallet_code)"
                >
                  <div class="flex items-center justify-between gap-2 sm:gap-4">
                    <div
                      class="flex flex-grow items-center justify-between gap-4 truncate text-sm font-medium text-white"
                    >
                      <div class="flex items-center gap-2 truncate">
                        <div class="relative size-4 flex-shrink-0">
                          <NuxtImg
                            loading="lazy"
                            class="size-full flex-shrink-0 rounded-full"
                            :src="
                              getWalletImageByCode(wallet?.entity_wallet_code)
                            "
                            alt=""
                          />
                        </div>
                        <div
                          class="flex items-center gap-1 truncate text-xs group-hover:!text-main-pink sm:text-sm"
                          :class="{
                            'text-main-pink':
                              selectedWallet?.entity_wallet_code ===
                              wallet?.entity_wallet_code,
                          }"
                        >
                          <span class="truncate">
                            {{
                              formatWithWalletCode(wallet?.balance || "", "", 8)
                            }}
                          </span>
                          <span>{{ wallet.entity_wallet_code }}</span>
                        </div>
                      </div>
                      <div
                        class="truncate text-xs sm:text-sm"
                        v-if="user?.player_information?.wallet_currency"
                      >
                        {{
                          `${
                            handleCurrencyValue(wallet).symbol
                          }${roundDecimalNumber(
                            Number(handleCurrencyValue(wallet).result),
                            2
                          )}`
                        }}
                      </div>
                    </div>
                    <v-icon
                      :class="
                        selectedWallet?.entity_wallet_code ===
                        wallet?.entity_wallet_code
                          ? 'opacity-100'
                          : 'opacity-0'
                      "
                      size="small"
                      color="success"
                    >
                      mdi-check-circle
                    </v-icon>
                  </div>
                </div>
              </template>
              <template #footer>
                <div class="p-3">
                  <div
                    class="flex cursor-pointer items-center justify-center gap-2"
                    @click="openWalletSettings"
                  >
                    <WalletIcon />

                    <span class="text-sm font-bold text-white">
                      {{ $t("common.wallet_settings") }}
                    </span>
                  </div>
                </div>
              </template>
            </CommonDropdown>
            <button
              @click="handleOpenWalletTransaction"
              class="box-center pulse-button gap-2.5 rounded-lg bg-main-pink text-sm font-medium text-white max-lg:size-10 lg:px-4 lg:py-3.5"
            >
              <WalletIcon />
              <span class="hidden truncate lg:block">
                {{ $t("common.deposit") }}
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="flex flex-shrink-0 items-center gap-2 lg:gap-4">
        <NuxtLinkScrollToTop
          :to="ROUTES_NAME.VIP_PROGRAM"
          class="hidden lg:block"
        >
          <VipIcon />
        </NuxtLinkScrollToTop>
        <v-menu v-model="openMenu" close-on-back>
          <template #activator="{ props }">
            <button
              v-bind="props"
              type="button"
              class="relative size-full rounded-full"
              :class="{ 'bg-main-pink': user?.avatar }"
            >
              <UserIcon v-if="!user?.avatar" />
              <img
                v-else
                :src="user?.avatar"
                class="h-full w-full rounded-full object-cover"
                alt=""
              />
              <span
                v-if="getInboxCount"
                class="absolute -end-2 -top-2 inline-flex h-6 w-6 items-center justify-center rounded-full border-2 border-gray-900 bg-main-red text-xs text-white"
                :class="{
                  '!text-[10px]': getInboxCount.length >= 3,
                }"
                >{{ getInboxCount }}</span
              >
            </button>
          </template>
          <Scrollbar
            class="dropdown-wallet z-[1008] mt-1 max-h-[75vh] min-w-[280px] overflow-auto rounded-[12px] bg-main-175 text-sm text-white shadow-lg md:max-h-[80vh]"
          >
            <div class="p-4">
              <div class="mb-6 !rounded-lg bg-main-150 p-4">
                <div
                  class="flex items-center gap-2 border-b !border-main-125 pb-4"
                >
                  <div class="size-[38px] rounded-full">
                    <UserIcon v-if="!user?.avatar" />
                    <img
                      v-else
                      :src="user?.avatar"
                      class="h-full w-full rounded-full object-cover"
                      alt=""
                    />
                  </div>
                  <p class="font-medium">
                    {{ user?.username }}
                  </p>
                </div>

                <div class="flex flex-col gap-1 pt-4">
                  <div class="flex items-center gap-1">
                    <div class="w-[18px]">
                      <img
                        loading="lazy"
                        :src="levelInfo?.current_level?.image"
                        alt=""
                        class="w-full"
                      />
                    </div>
                    <p>
                      {{ levelInfo?.current_level?.title }}
                    </p>
                  </div>
                  <template
                    v-if="levelInfo?.current_level?.level < LEVEL.PUNK_OVERLORD"
                  >
                    <ProgressLevel
                      :progress="Number(levelInfo?.level_progress)"
                    />
                    <div class="flex items-center justify-between gap-2">
                      <p>{{ Number(levelInfo?.level_progress).toFixed(2) }}%</p>
                      <div class="flex items-center gap-1">
                        <div
                          v-if="levelInfo?.next_level?.image"
                          class="w-[18px]"
                        >
                          <img
                            loading="lazy"
                            :src="levelInfo?.next_level?.image"
                            alt=""
                            class="w-full"
                          />
                        </div>
                        <span>{{ levelInfo?.next_level?.title }}</span>
                      </div>
                    </div>
                  </template>
                </div>
              </div>

              <div class="flex flex-col gap-3">
                <button
                  type="button"
                  @click="
                    () => {
                      openMenu = false;
                      item.callback();
                    }
                  "
                  v-for="item in listMenu"
                  :key="item.id"
                  class="flex items-center gap-2 !rounded-lg px-2 py-3 hover:bg-main-150"
                >
                  <span class="relative">
                    <LazyNavbarSvg
                      v-if="openMenu"
                      :default="true"
                      :item="navbarList.find((ele) => ele.id === item.id)"
                    />
                    <img
                      v-if="
                        isNewBonusAvailable && item.id === NavbarEnum.MY_BONUS
                      "
                      class="absolute -right-1 -top-1"
                      src="/images/icon/new_bonus.png"
                      alt=""
                    />
                  </span>
                  <p>{{ item.name }}</p>
                </button>

                <button
                  v-if="!isWebApp()"
                  type="button"
                  @click="logout"
                  class="flex items-center gap-2 !rounded-lg px-2 py-3 hover:bg-main-150"
                >
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.41602 6.8C7.67435 3.8 9.21602 2.575 12.591 2.575H12.6993C16.4243 2.575 17.916 4.06667 17.916 7.79167V13.225C17.916 16.95 16.4243 18.4417 12.6993 18.4417H12.591C9.24102 18.4417 7.69935 17.2333 7.42435 14.2833"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12.5009 10.5H3.01758"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4.87565 7.70833L2.08398 10.5L4.87565 13.2917"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  {{ $t("common.logout") }}
                </button>
              </div>
            </div>
          </Scrollbar>
        </v-menu>
      </div>
    </div>
    <v-dialog v-model="walletSettingsDialog" width="auto">
      <WalletSettings />
    </v-dialog>
    <v-dialog
      v-model="walletTransactionState"
      max-width="580"
      width="calc(100% - 20px)"
      height="calc(100% - 20px)"
      max-height="700"
      class="dialog_custom"
      :persistent="true"
      :no-click-animation="true"
      :onClick:outside="
        () =>
          onClickOutsideDialog({
            callback: () => changeWalletTransactionDialog(false),
          })
      "
    >
      <WalletTransaction v-if="walletTransactionState" />
    </v-dialog>
    <v-dialog v-model="bonusDialog" width="auto">
      <Bonus v-if="bonusDialog" />
    </v-dialog>
  </div>
</template>
<script setup lang="ts">
import { useRoute, useRouter } from "#app";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import ProgressLevel from "~/components/ProgressLevel.vue";
import WalletSettings from "~/components/WalletSettings.vue";
import UserIcon from "~/components/icon/UserIcon.vue";
import VipIcon from "~/components/icon/VipIcon.vue";
import WalletIcon from "~/components/icon/WalletIcon.vue";
import { LEVEL, QUERY_PARAMS, WALLET_TRANSACTION_ACTION } from "~/config/enum";
import { ROUTES_NAME } from "~/config/router";
import { useGetAvailableBonus } from "~/queries/bonusService";
import { useAuthStore } from "~/stores/authStore";
import { useNoti } from "~/stores/noti";
import { useSettingStore } from "~/stores/settingStore";
import { useSystemStore } from "~/stores/systemStore";
import { useWalletStore } from "~/stores/walletStore";
import { bonusData, NavbarEnum } from "~/types/schema";
import {
  getWalletImageByCode,
  handleCurrencyValue,
  onClickOutsideDialog,
} from "~/utils";

const { inPlay } = storeToRefs(useNoti());
const navbarList = useNavbar();
const useSystem = useSystemStore();
const authStore = useAuthStore();
const {
  walletSettingsDialog,
  walletTransactionState,
  bonusDialog,
  listWalletDialog,
} = storeToRefs(useSystem);
const { walletTransactionParams } = storeToRefs(useWalletStore());
watch(walletTransactionState, (value) => {
  if (!value) walletTransactionParams.value = WALLET_TRANSACTION_ACTION.DEPOSIT;
});
const { user } = storeToRefs(authStore);
const {
  changeListWalletDialog,
  changeWalletTransactionDialog,
  changeBonusDialog,
} = useSystem;

const walletStore = useWalletStore();
const settingStore = useSettingStore();
const { getInboxCount } = storeToRefs(settingStore);
const {
  getSelectedWallet,
  getListWallet,
  listWalletOriginal,
  getWalletsLoading,
} = storeToRefs(walletStore);
const { changeSelectedWalletApi, updateListWallet, setListWallet } =
  walletStore;
const openMenu = ref(false);

const { data: listAvailable } = useGetAvailableBonus(openMenu);

const isNewBonusAvailable = computed(() => {
  return listAvailable.value?.some((item: bonusData) => !item.read_at);
});

// state
const search = ref("");
const listWallets = ref(getListWallet); // list to display UI
const showWallets = ref(false);
const listWalletsToSearch = ref(listWallets.value); // list to search from after switching hide zero balance
const selectedWallet = ref(getSelectedWallet);
const { t } = useNuxtApp().$i18n || {};
const route = useRoute();
const router = useRouter();

const handleListWallet = (hideZeroBalance: boolean) => {
  if (hideZeroBalance) {
    const result = listWalletOriginal.value.filter((item) => item.balance > 0);
    updateListWallet(result);
    listWalletsToSearch.value = result;
  } else {
    updateListWallet(listWalletOriginal.value);
    listWalletsToSearch.value = listWalletOriginal.value;
  }
};

handleListWallet(user.value?.player_information?.hide_zero_balance);

watch(
  () => user.value?.player_information?.hide_zero_balance,
  (current) => {
    handleListWallet(current);
  },
  {
    deep: true,
  }
);

watch(
  listWalletOriginal,
  () => {
    handleListWallet(user.value?.player_information?.hide_zero_balance);
  },
  {
    deep: true,
  }
);

watch(search, (current: string) => {
  if (current) {
    const result = listWalletOriginal.value.filter((item) => {
      const code = item?.entity_wallet_code?.toLowerCase()?.trim();
      const formatCurrent = current.toLowerCase()?.trim();
      return code?.includes(formatCurrent);
    });
    updateListWallet(result);
  } else {
    updateListWallet(listWalletsToSearch.value);
  }
});

watch(listWalletDialog, (current) => {
  if (!current) {
    search.value = "";
    showWallets.value = false;
  }
});

watch(showWallets, (current) => {
  changeListWalletDialog(current);
});

const openDepositKey = QUERY_PARAMS.OPEN_DEPOSIT;

const handleOpenDeposit = () => {
  if (route.query[openDepositKey] === "true") {
    handleOpenWalletTransaction();
    router.replace({ query: { ...route.query, [openDepositKey]: undefined } });
  }
};

const handleOpenWalletTransaction = async () => {
  changeWalletTransactionDialog(true);
  changeListWalletDialog(false);
};

// Watch for changes in query parameters and handle them
watch(() => route.query, handleOpenDeposit);

// Initial check on component mount
onMounted(() => {
  handleOpenDeposit();
  if (!listWalletOriginal.value.length) setListWallet();
});

const { walletTransactionParams: selectedAction } =
  storeToRefs(useWalletStore());
const openWalletSettings = () => {
  selectedAction.value = WALLET_TRANSACTION_ACTION.SETTING;
  changeWalletTransactionDialog(true);
  changeListWalletDialog(false);
};

const changeSelectedWallet = (code: string) => {
  if (code !== selectedWallet.value.entity_wallet_code) {
    changeSelectedWalletApi(code);
  }
  changeListWalletDialog(false);
};

const openBonusDialog = () => {
  changeBonusDialog(true);
};

// open menu
const listMenu = computed(() => [
  {
    id: NavbarEnum.INBOXES,
    name: t("components.menu_profile.inboxes"),
    callback: () => {
      scrollToTop();
      navigateTo(ROUTES_NAME.INBOXES + `?k=` + Math.random());
    },
  },
  {
    id: NavbarEnum.PROFILE,
    name: t("components.menu_profile.profile"),
    callback: () => {
      scrollToTop();
      navigateTo({ path: ROUTES_NAME.PROFILE });
    },
  },
  {
    id: NavbarEnum.MY_BONUS,
    name: t("common.my_bonus"),
    callback: () => {
      openBonusDialog();
    },
  },
  {
    id: NavbarEnum.WALLET,
    name: t("common.wallet"),
    callback: () => {
      handleOpenWalletTransaction();
    },
  },
  {
    id: NavbarEnum.VIP,
    name: t("common.vip"),
    callback: () => {
      scrollToTop();
      navigateTo({ path: ROUTES_NAME.VIP_PROGRAM });
    },
  },
  {
    id: NavbarEnum.TRANSACTIONS,
    name: t("common.transactions"),
    callback: () => {
      scrollToTop();
      navigateTo({ path: ROUTES_NAME.TRANSACTION_HISTORY });
    },
  },
  {
    id: NavbarEnum.LIVE_SUPPORT,
    name: t("common.live_support"),
    callback: () => {
      if (window.LiveChatWidget) window.LiveChatWidget.call("maximize");
    },
  },
]);

const logout = () => {
  useSystem.changeLogoutDialog(true);
};

//level information
onMounted(() => {
  authStore.levelInformation();
});
const { levelInfo } = storeToRefs(authStore);
</script>
<style scoped lang="scss">
header {
  &.header-shadow {
    -webkit-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
    -moz-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
    box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  }
}

.fade-enter-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

:deep(.v-overlay__content) {
  max-height: 700px !important;
}
</style>
