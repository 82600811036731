<template>
  <template v-if="isFirstFetching">
    <div
      class="grid grid-cols-2 gap-4 xs:grid-cols-3 sm:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 xxl:grid-cols-8"
    >
      <CustomSkeleton
        class="aspect-square !rounded-xl"
        v-for="item in 15"
        :key="item"
      />
    </div>
  </template>
  <template v-else-if="listGame?.pages[0].length">
    <div
      class="grid grid-cols-3 gap-4 sm:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 xxl:grid-cols-8"
    >
      <template v-for="(page, idx) in listGame.pages" :key="idx">
        <SlideViewGame
          v-for="game in page"
          :key="game.id"
          :game="game"
          slug="favorite"
          :refresh-provider="true"
        />
      </template>
    </div>
    <LazyClientOnly>
      <div class="flex items-center justify-center py-6">
        <div
          v-if="hasNextPage && !isFirstFetching"
          class="flex h-5 w-[200px] items-center justify-center gap-2"
          :class="[isFetchingNextPage ? 'opacity-1' : 'opacity-0']"
          v-intersect="{
            options: {
              threshold: [1.0, 1.0, 1.0],
            },
            handler: (isIntersect: boolean) => {
              if (isIntersect) fetchNextPage();
            },
          }"
        >
          <span class="loader !size-6" />
          <span class="text-sm font-medium text-white">
            {{ $t("common.message.loading") }}
          </span>
        </div>
      </div>
    </LazyClientOnly>
  </template>

  <div
    v-else
    class="-mx-2 mb-8 flex flex-wrap transition-all duration-200 lg:!mb-12 xl:-mx-3 xl:!flex-nowrap"
  >
    <div
      class="mx-2 flex size-full flex-col items-center justify-center rounded-2xl bg-main-150 py-12"
    >
      <img
        src="/images/icon/no-find.png"
        alt="icon-not-found"
        class="mb-[24px]"
      />
      <span class="text-sm text-white">
        {{ $t("common.message.no_game_found") }}
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useInfiniteQuery } from "@tanstack/vue-query";
import { useGameStore } from "~/stores/gameStore";
import { resCategoryGameList } from "~/types/schema";

type Props = {
  selectedCategory: string;
  search: string;
};
const props = defineProps<Props>();

const { getGameByCategory } = useGameStore();

const {
  isLoading: isFirstFetching,
  data: listGame,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
} = useInfiniteQuery({
  queryKey: [
    "useGetGamesLobby",
    toRef(() => props.search),
    toRef(() => props.selectedCategory),
  ],
  queryFn: ({ pageParam = 1 }) => {
    return getGameByCategory(
      props.selectedCategory,
      pageParam,
      false,
      props.search
    ) as Promise<resCategoryGameList[]>;
  },
  initialPageParam: 1,
  getNextPageParam: (lastPage, _, lastPageParam) => {
    if (lastPage.length < 20) {
      return undefined;
    }
    return lastPageParam + 1;
  },
});
</script>
