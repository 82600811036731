<template>
  <div class="relative text-white">
    <CommonDropdown
      :listData="listBonusType"
      v-model="isOpen"
      :disable-search="true"
      :no-data-message="$t('common.message.no_bonus_type')"
      :wrap-class="`h-[42px] bg-main-150 ${
        isRequiredBonus ? '!border-main-red' : ''
      }`"
      content-class="max-w-full"
      content-max-height="100%"
    >
      <template #selected>
        <div class="flex items-center gap-3 truncate">
          <div class="h-6 w-6 flex-shrink-0">
            <img
              class="size-full"
              src="~/assets/images/bonus-icon.svg"
              alt=""
            />
          </div>
          <p
            v-if="selectedBonusType"
            class="truncate text-sm !font-semibold normal-case"
          >
            {{ $t(getBonusTextByValue()) }}
          </p>
          <div
            v-else
            class="truncate text-sm !font-semibold normal-case text-neutral-50-new"
          >
            {{ $t("common.placeholder.bonus_type") }}
          </div>
        </div>
      </template>

      <template #list-dropdown-item>
        <div
          v-for="(bonus, index) in listBonusType"
          :key="index"
          class="group cursor-pointer border-b !border-main-125 p-3 hover:bg-main-125"
          :class="{
            'bg-main-125': selectedBonusType === bonus?.value,
          }"
          @click="changeSelectedBonus(bonus)"
        >
          <div class="flex items-center gap-3 truncate">
            <div
              v-if="bonus?.value !== noBonusValue"
              class="h-6 w-6 flex-shrink-0"
            >
              <img
                class="size-full"
                src="~/assets/images/bonus-icon.svg"
                alt=""
              />
            </div>
            <p class="truncate text-sm !font-semibold normal-case text-white">
              {{ $t(bonus.title) }}
            </p>
            <div class="h-6 w-6 flex-shrink-0">
              <svg
                v-if="bonus.value === selectedBonusType"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5Z"
                  fill="#73DD87"
                />
                <path
                  d="M10.5799 16.08C10.3799 16.08 10.1899 16 10.0499 15.86L7.21994 13.03C6.92994 12.74 6.92994 12.26 7.21994 11.97C7.50994 11.68 7.98994 11.68 8.27994 11.97L10.5799 14.27L15.7199 9.13001C16.0099 8.84001 16.4899 8.84001 16.7799 9.13001C17.0699 9.42001 17.0699 9.90001 16.7799 10.19L11.1099 15.86C10.9699 16 10.7799 16.08 10.5799 16.08Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        </div>
      </template>
    </CommonDropdown>
  </div>
</template>

<script setup lang="ts">
import { noBonusValue } from "~/config/constant";
import { CLAIM_TYPE } from "~/config/enum";
import { claimBonus } from "~/service/bonusService";

const props = defineProps([
  "listBonusType",
  "selectedBonusType",
  "isRequiredBonus",
]);
const emit = defineEmits(["onBonusTypeChange", "triggerError"]);
const selectedBonusType = ref(props.selectedBonusType);
const { $toast, $i18n } = useNuxtApp();
const { t } = $i18n || {};

const isOpen = ref(false);

const changeSelectedBonus = (bonus: any) => {
  if (selectedBonusType.value !== bonus) {
    selectedBonusType.value = bonus.value;
    isOpen.value = false;
    emit("triggerError", false);
  }
};

const getBonusTextByValue = () => {
  const itemFind = props.listBonusType.find(
    (item) => item.value === selectedBonusType.value
  );
  return itemFind ? itemFind.title : "";
};

watch(selectedBonusType, async (current: number) => {
  emit("onBonusTypeChange", current);
  try {
    const response = await claimBonus(
      current < 0 ? 0 : current,
      CLAIM_TYPE.CLAIMED
    );
    if (response.data.success) {
      if (current < 0) {
        $toast.success(t("common.message.claimed_mess_4"));
      } else {
        $toast.success(t("common.message.claimed_mess_1"));
      }
    }
  } catch (error: any) {
    const message = error?.response?.data?.message;
    $toast.error(message);
  }
});
</script>

<style scoped lang="scss">
:deep(.v-btn__content) {
  justify-content: space-between !important;
  width: 100% !important;
  gap: 12px !important;
  color: #270c4e !important;
  font-weight: 400 !important;
}
</style>
