<template>
  <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.99967 15.1666C11.6816 15.1666 14.6663 12.1819 14.6663 8.49998C14.6663 4.81808 11.6816 1.83331 7.99967 1.83331C4.31778 1.83331 1.33301 4.81808 1.33301 8.49998C1.33301 12.1819 4.31778 15.1666 7.99967 15.1666Z"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.33275 2.5H5.99941C4.69941 6.39333 4.69941 10.6067 5.99941 14.5H5.33275"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 2.5C11.3 6.39333 11.3 10.6067 10 14.5"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2 11.1667V10.5C5.89333 11.8 10.1067 11.8 14 10.5V11.1667"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2 6.49996C5.89333 5.19996 10.1067 5.19996 14 6.49996"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
