<template>
  <NuxtLoadingIndicator color="#FD3FCC" />
  <div class="h-svh overflow-hidden">
    <v-app id="app" class="h-full overflow-auto scroll-smooth">
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>
    </v-app>
    <ClientOnly>
      <LazyLiveChat />
    </ClientOnly>
    <MetaTag />
    <Telegram />
  </div>
</template>

<script setup lang="ts">
import { navigateTo } from "#app";
import { onMounted } from "vue";
import CheckLocation from "~/components/layouts/CheckLocation.vue";
import MetaTag from "~/components/layouts/MetaTag.vue";
import { useCurrentLanguage, useLanguages } from "~/composables/useLanguages";
import { useToken } from "~/composables/useToken";
import { ROUTES_NAME } from "~/config/router";
import { useAuthStore } from "~/stores/authStore";
import { useNotificationStore } from "~/stores/notificationStore";
import { useWalletStore } from "~/stores/walletStore";
import { formatLanguageCode, zoomOutMobile } from "./utils";

const token = useToken();
const lang = useCurrentLanguage();
const listLanguages = useLanguages();
const walletStore = useWalletStore();
const authStore = useAuthStore();
const notificationStore = useNotificationStore();
const route = useRoute();
const settingStore = useSettingStore();

const { $initEcho } = useNuxtApp();
const { backToUrl } = storeToRefs(authStore);

initializeDayjsLocale(lang.value);
const initialLanguage = () => {
  if (!token.value && !lang.value) {
    const systemLanguage = formatLanguageCode(
      window.Telegram.WebApp?.initDataUnsafe?.user?.language_code ||
        navigator.language
    );
    lang.value = listLanguages.value.find(
      (language) => language.value === systemLanguage
    )
      ? systemLanguage
      : "en";
  }
};

// handle data for user after login/logout
watch(token, (current) => {
  const middlewares = route.meta.middleware || [];
  const isMiddleware = (name: any) =>
    Array.isArray(middlewares) && middlewares.includes(name);

  if (current) {
    // Handle login
    // authStore.fetchUser();
    $initEcho();

    if (isMiddleware("guest")) {
      navigateTo(backToUrl.value || ROUTES_NAME.LOBBY, { replace: true });
    }
  } else {
    // Handle logout
    walletStore.clearDataWallet();
    $initEcho();

    if (isMiddleware("auth")) {
      navigateTo(ROUTES_NAME.LOGIN, { replace: true });
    }
  }

  settingStore.setDataSettingGeneral();
});

onMounted(() => {
  zoomOutMobile();
  if (typeof window.trackConversion === "function") {
    window.trackConversion();
  }
  initialLanguage();
  settingStore.setDataSettingGeneral();
  watch(
    () => route.path,
    () => {
      scrollToTop();
    }
  );
});
</script>
