<template>
  <v-dialog v-model="newPromotionResultDialog" width="auto">
    <v-card
      class="mx-[10px] w-[calc(100vw-68px)] max-w-[560px] !rounded-[16px] !bg-main-dark !p-[20px]"
    >
      <div class="flex justify-end">
        <button
          @click="changeNewPromotionResultDialog(false)"
          type="button"
          class="rounded-full hover:bg-main-125"
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.0884 15.0877L24.9123 24.9116"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15.0877 24.9116L24.9116 15.0877"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
      <img
        src="/images/icon/icon-open-bonus-new.png"
        alt="security-user-icon"
        class="m-auto my-0 !mb-[24px] w-[90px]"
      />
      <span
        class="mb-[16px] text-center text-[24px] font-[700] uppercase leading-[32px] text-[#ffffff]"
      >
        {{ $t("common.message.new_reward") }}
      </span>
      <div class="mb-4 px-4 text-center text-white">
        <span
          v-if="promotionResult.type == PROMOTION_AMOUNT_TYPE.CASH"
          class="mb-[30px]"
        >
          {{
            $t("common.message.claimed_cash", {
              num: formatWithWalletCode(promotionResult.amount || "", "", 8),
              entity_wallet_code: promotionResult.entity_wallet_code,
            })
          }}
        </span>
        <section
          v-else-if="promotionResult.type == PROMOTION_AMOUNT_TYPE.FREE_SPIN"
        >
          <div class="mb-[20px]">
            {{
              $t("common.message.claimed_free_spins_game", {
                num: promotionResult.fs_round,
                game_name: promotionResult.game_name,
              })
            }}
          </div>
          <NuxtLink
            :to="ROUTES_NAME.PLAY_GAME(promotionResult.game_code)"
            @click="changeNewPromotionResultDialog(false)"
            class="m-auto flex h-[56px] w-[112px] gap-2 rounded-lg bg-main-pink p-4 text-center uppercase text-white hover:opacity-90"
          >
            <svg
              class="flex-shrink-0"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.5 4.98951C5.5 4.01835 5.5 3.53277 5.70249 3.2651C5.87889 3.03191 6.14852 2.88761 6.4404 2.87018C6.77544 2.85017 7.17946 3.11953 7.98752 3.65823L18.5031 10.6686C19.1708 11.1137 19.5046 11.3363 19.6209 11.6168C19.7227 11.8621 19.7227 12.1377 19.6209 12.383C19.5046 12.6635 19.1708 12.886 18.5031 13.3312L7.98752 20.3415C7.17946 20.8802 6.77544 21.1496 6.4404 21.1296C6.14852 21.1122 5.87889 20.9679 5.70249 20.7347C5.5 20.467 5.5 19.9814 5.5 19.0103V4.98951Z"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {{ $t("common.btn.play") }}
          </NuxtLink>
        </section>
      </div>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { storeToRefs } from "pinia";
import { PROMOTION_AMOUNT_TYPE } from "~/config/enum";
import { ROUTES_NAME } from "~/config/router";
import { usePromotionStore } from "~/stores/promotionStore";
import { useSystemStore } from "~/stores/systemStore";

const useSystem = useSystemStore();
const promotionStore = usePromotionStore();
const { newPromotionResultDialog } = storeToRefs(useSystem);
const { promotionResult } = storeToRefs(promotionStore);
const { changeNewPromotionResultDialog } = useSystem;
</script>
