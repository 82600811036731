<template>
  <svg
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.1673 8.50002V11.8334C15.1673 13.8334 13.834 15.1667 11.834 15.1667H5.16732C3.16732 15.1667 1.83398 13.8334 1.83398 11.8334V8.50002C1.83398 6.68669 2.92732 5.42002 4.62732 5.20669C4.80065 5.18002 4.98065 5.16669 5.16732 5.16669H11.834C12.0073 5.16669 12.174 5.17335 12.334 5.20001C14.054 5.40001 15.1673 6.67335 15.1673 8.50002Z"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.3336 5.19998C12.1736 5.17331 12.007 5.16665 11.8336 5.16665H5.16695C4.98029 5.16665 4.80029 5.17998 4.62695 5.20665C4.72029 5.01998 4.85362 4.84665 5.01362 4.68665L7.18029 2.51331C8.09362 1.60665 9.57362 1.60665 10.487 2.51331L11.6536 3.69333C12.0803 4.11333 12.307 4.64665 12.3336 5.19998Z"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.1673 8.83331H13.1673C12.434 8.83331 11.834 9.43331 11.834 10.1666C11.834 10.9 12.434 11.5 13.1673 11.5H15.1673"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
