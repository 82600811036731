<template>
  <div class="flex-grow">
    <div class="flex flex-col gap-4 sm:flex-row">
      <div class="flex flex-grow flex-col gap-1">
        <p class="text-xs font-medium text-white">
          {{ $t("common.currency") }}
        </p>
        <select-wallet-dropdown
          :selectedWalletRef="selectedWalletRef"
          @on-wallet-change="onWalletChange"
          :show-amount="true"
        />
      </div>
      <div class="flex w-full flex-col gap-1 sm:w-[190px]">
        <p class="required white text-xs font-medium text-white">
          {{ $t("common.network") }}
        </p>
        <select-wallet-network
          :selectedWalletRef="selectedWalletRef"
          :selectedNetWork="selectedNetWork"
          @on-network-change="onNetworkChange"
        />
      </div>
    </div>

    <section class="min-h-[300px]">
      <p
        v-if="listBonusType.length"
        class="my-6 text-center text-sm text-white"
      >
        {{ $t("wallet_transaction.deposit.notice") }}
      </p>

      <div
        v-if="listBonusType.length && !noBonusAvailableMess"
        class="flex flex-col gap-1"
      >
        <div class="required white text-xs font-medium text-white">
          {{ $t("wallet_transaction.deposit.bonus_type") }}
        </div>
        <select-bonus-type-dropdown
          :selectedBonusType="selectedBonusType"
          :listBonusType="listBonusType"
          @on-bonus-type-change="onBonusTypeChange"
          @trigger-error="(value) => (isRequiredBonus = value)"
          :isRequiredBonus="isRequiredBonus"
        />
      </div>
      <p v-if="noBonusAvailableMess" class="error-message pt-1 text-sm">
        {{
          $t("wallet_transaction.deposit.already_claimed", {
            code: selectedWalletRef.entity_wallet_code,
          })
        }}
      </p>
      <div v-if="isRequiredBonus" class="error-message mt-1 text-xs">
        {{ $t("validation_message.required") }}
      </div>

      <div
        v-if="selectedNetWork?.kind == WALLET_NETWORK_KIND.BTC_LIGHTNING"
        class="my-6 !rounded-xl border !border-main-150 p-6"
      >
        <div class="flex flex-col gap-4">
          <div class="flex flex-col gap-1">
            <div class="flex flex-wrap items-center justify-between gap-2">
              <div class="text-xs font-medium capitalize text-white">
                <span class="required white">
                  {{ $t("wallet_transaction.deposit.deposit_amount") }}</span
                >
              </div>
              <div class="text-xs font-medium text-white">
                <span>
                  {{
                    userWalletCurrency
                      ? roundDecimalNumber(Number(depositAmountRaw), 8)
                      : `${roundDecimalNumber(Number(depositAmountRaw), 2)}`
                  }}
                  {{
                    userWalletCurrency
                      ? selectedWalletRef?.entity_wallet_code
                      : `${getCurrencyData()?.name}`
                  }}
                </span>
              </div>
            </div>
            <v-form @submit.prevent="onRequestBtcLightning" ref="form">
              <div class="flex flex-col gap-2 sm:flex-row">
                <v-text-field
                  type="number"
                  v-model="depositAmount"
                  variant="outlined"
                  bg-color="#202329"
                  color="#ffffff"
                  :placeholder="$t('common.placeholder.withdrawal_amount')"
                  class="wallet-amount-input textfield-base w-[100%]"
                  v-numeric-input="NUMBERIC_TYPE.DOT"
                  :rules="[
                    (value) =>
                      validation.min(
                        value,
                        minAmount,
                        $t('validation_message.min', {
                          min: userWalletCurrency
                            ? `${symbol}${roundDecimalNumber(minAmount, 2)}`
                            : `${roundDecimalNumber(minAmount, 8)} ${symbol}`,
                        })
                      ),
                    (value) =>
                      validation.max(
                        value,
                        maxAmount,
                        $t('validation_message.max', {
                          max: userWalletCurrency
                            ? `${symbol}${roundDecimalNumber(maxAmount, 2)}`
                            : `${roundDecimalNumber(maxAmount, 8)} ${symbol}`,
                        })
                      ),
                  ]"
                  @blur="(event: any) => onBlurDepositAmount(event)"
                  @input="() => onInputDepositAmount()"
                >
                </v-text-field>

                <button
                  type="submit"
                  :disabled="
                    Number(depositAmount) == 0 ||
                    handling ||
                    Number(depositAmount) > maxAmount ||
                    Number(depositAmount) < minAmount
                  "
                  class="h-[42px] min-w-[130px] !rounded-lg bg-main-pink px-2 text-sm font-extrabold text-white sm:max-w-[170px] sm:text-[13px] sm:font-medium"
                >
                  {{ $t("common.btn.get_btc_lightning_address") }}
                </button>
              </div>
            </v-form>
          </div>
        </div>
      </div>

      <div class="mb-6 flex items-center justify-center pt-6" />

      <template v-if="showAddressAndQr && !loading && walletDeposit.id">
        <div class="mb-4 flex flex-col items-center gap-5">
          <div class="w-[250px] xs:w-[350px] md:w-[400px]">
            <p class="mb-1 text-sm text-white">
              {{ handleLabelByKind(selectedNetWork?.kind) }}
            </p>
            <div class="max-w-full !rounded-lg bg-main-pink px-4 py-3">
              <div class="flex items-center justify-between gap-[10px]">
                <p class="truncate text-sm font-semibold text-white">
                  {{
                    walletDeposit &&
                    walletDeposit[getFieldByKindValue(walletDeposit?.kind)]
                  }}
                </p>
                <button
                  @click="
                    onCopyString(
                      walletDeposit[getFieldByKindValue(walletDeposit?.kind)]
                    )
                  "
                  type="button"
                  :disabled="
                    !walletDeposit[getFieldByKindValue(walletDeposit?.kind)]
                  "
                  class="h-6 w-6 flex-shrink-0 cursor-pointer"
                >
                  <img src="/images/icon/copy.svg" class="w-full" alt="" />
                </button>
              </div>
            </div>
          </div>

          <div
            v-if="selectedNetWork?.kind == WALLET_NETWORK_KIND.XRP"
            class="w-[250px] xs:w-[350px] md:w-[400px]"
          >
            <p class="mb-1 text-sm text-white">
              {{ $t("wallet_transaction.withdraw.destination_tag") }}
            </p>
            <div class="!rounded-lg bg-main-pink px-4 py-3">
              <div class="flex items-center justify-between gap-[10px]">
                <p class="truncate text-sm font-semibold text-white">
                  {{ walletDeposit?.destination_tag }}
                </p>
                <button
                  @click="onCopyString(walletDeposit?.destination_tag)"
                  type="button"
                  :disabled="!walletDeposit?.destination_tag"
                  class="h-6 w-6 flex-shrink-0 cursor-pointer"
                >
                  <img src="/images/icon/copy.svg" class="w-full" alt="" />
                </button>
              </div>
            </div>

            <p class="mt-2 text-[11px] text-[#ef3054]">
              {{ $t("wallet_transaction.deposit.destination_tag_note") }}
            </p>
          </div>
        </div>

        <template v-if="walletDeposit?.qr_code">
          <div class="mb-4 text-center text-xs text-neutral-50-new">
            {{ $t("wallet_transaction.deposit.scan_here") }}
          </div>
          <div class="mb-4 flex items-center justify-center">
            <div
              class="min-h-[148px] w-[148px] rounded-[12px] border border-solid !border-main-pink bg-white p-3"
            >
              <NuxtImg
                :src="walletDeposit?.qr_code"
                class="w-full object-contain"
                alt=""
                eager
              />
            </div>
          </div>
          <!--                <p v-html="$t('wallet_transaction.deposit.deposit_mess', {-->
          <!--                    coin_code: selectedWalletRef.entity_wallet_code,-->
          <!--                })" class="text-neutral-25-new text-[13px] text-center"></p>-->
        </template>
      </template>

      <div
        v-if="loading"
        class="flex h-[150px] flex-grow items-center justify-center"
      >
        <span class="loader"></span>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { noBonusValue } from "~/config/constant";
import { WALLET_NETWORK_KIND, NUMBERIC_TYPE } from "~/config/enum";
import { useGetAvailableBonus } from "~/queries/bonusService";
import { useAuthStore } from "~/stores/authStore";
import { useBonusStore } from "~/stores/bonusStore";
import { useWalletStore } from "~/stores/walletStore";
import { bonusData, entityWallet, userWalletAddress } from "~/types/schema";
import {
  handleLabelByKind,
  roundDecimalNumber,
  getCurrencyData,
  getRawWalletValueFromCurrency,
} from "~/utils";
import SelectBonusTypeDropdown from "./SelectBonusTypeDropdown.vue";
import SelectWalletDropdown from "./SelectWalletDropdown.vue";
import SelectWalletNetwork from "./SelectWalletNetwork.vue";

const { suspense: loadAvailableBonus } = useGetAvailableBonus();
await loadAvailableBonus();
const walletStore = useWalletStore();
const authStore = useAuthStore();
const { user } = authStore;
const { $toast, $i18n } = useNuxtApp();
const { t } = $i18n || {};
const {
  selectedWallet,
  listEntityWallets,
  listCurrencies,
  getBtcLightingAddress,
  getWalletDeposit,
} = walletStore;
const { walletDeposit, btcLightningAddress } = storeToRefs(walletStore);
const selectedWalletRef = ref(selectedWallet);
const selectedNetWork = ref();
const selected = ref(selectedWallet);
const gettedBtcLighting = ref(false);
const form = ref();
const handling = ref(false);
const loading = ref(false);

const bonusStore = useBonusStore();
const listBonusType = ref<any>([]);
const noBonusAvailableMess = ref(false);
const selectedBonusType = ref(bonusStore.bonusTypeParams || null);

const isRequiredBonus = ref(false);

// deposit lighting
const userWalletCurrency = computed(
  () => user?.player_information?.wallet_currency
);

const minAmount = ref(userWalletCurrency.value ? 0.01 : 0.00000001);
const maxAmount = ref(100000000);
const depositAmount = ref(userWalletCurrency.value ? "0.00" : "0.00000000");
const depositAmountBtcLightingRaw = ref("0.00000000");
const depositAmountRaw = ref(0);
const symbol = ref("");

const onRequestBtcLightning = async (event: any) => {
  loading.value = handling.value = true;
  const results = await event;
  if (results?.valid) {
    try {
      const valueAsNumber = Number(depositAmount.value);
      depositAmount.value = isNaN(valueAsNumber)
        ? userWalletCurrency.value
          ? "0.00"
          : "0.00000000"
        : roundDecimalNumber(
            valueAsNumber,
            userWalletCurrency.value ? 2 : 8
          ).toString();

      depositAmountBtcLightingRaw.value = userWalletCurrency.value
        ? roundDecimalNumber(
            Number(
              getRawWalletValueFromCurrency(
                depositAmount.value,
                selected.value?.entity_wallet_code,
                userWalletCurrency.value
              ).toString()
            ),
            8
          )
        : depositAmount.value;

      gettedBtcLighting.value = false;
      getBtcLightingAddress({ amount: depositAmountBtcLightingRaw.value }).then(
        (res) => {
          if (res?.success) {
            gettedBtcLighting.value = true;
            setTimeout(() => {
              scrollToBottomElement();
            }, 300);
          } else {
            $toast.error(res.message);
          }
          loading.value = false;
        }
      );
    } catch (error) {
      console.error("Error fetching BTC lighting address:", error);
      loading.value = false;
    }
  }
  handling.value = false;
};

const onInputDepositAmount = async () => {
  gettedBtcLighting.value = false;
};

const onBlurDepositAmount = async (event: any) => {
  if (!isNaN(Number(depositAmount.value))) {
    depositAmount.value = userWalletCurrency.value
      ? roundDecimalNumber(Number(depositAmount.value), 2).toString()
      : roundDecimalNumber(Number(depositAmount.value), 8).toString();
  } else {
    if (userWalletCurrency.value) {
      depositAmount.value = "0.00";
    } else {
      depositAmount.value = "0.00000000";
    }
  }
  event.target.setAttribute("data-old-value", depositAmount.value);
};

watch(
  () => selectedWalletRef.value,
  () => {
    getSelectedObject();
    getSymbol();
  }
);
watch(
  () => depositAmount.value,
  () => {
    depositAmountRaw.value = userWalletCurrency.value
      ? getRawWalletValueFromCurrency(
          depositAmount.value,
          selected.value?.entity_wallet_code,
          userWalletCurrency.value
        )
      : Number(depositAmount.value) *
        Number(
          listCurrencies?.rate[selected.value?.entity_wallet_code]?.[
            getCurrencyData()?.name
          ]
        );
  }
);

const showAddressAndQr = computed(() => {
  return (
    selectedNetWork.value?.kind !== WALLET_NETWORK_KIND.BTC_LIGHTNING ||
    gettedBtcLighting.value
  );
});

const getSymbol = () => {
  symbol.value = listCurrencies?.currencies?.find(
    (item: any) => item?.name === userWalletCurrency.value
  )?.symbol;

  if (!symbol.value) {
    symbol.value = selectedWalletRef.value?.entity_wallet_code;
  }
};
getSymbol();

const scrollToBottomElement = () => {
  const event = new CustomEvent("scroll-to-bottom");
  window.dispatchEvent(event);
};

const getSelectedObject = () => {
  selected.value = selectedWalletRef.value;
};
getSelectedObject();
// end deposit lighting

const getListBonusType = () => {
  listBonusType.value = bonusStore.listAvailable
    .filter(
      (item: bonusData) =>
        item.is_deposit_requirement &&
        item.valid_coins?.includes(selectedWalletRef.value.entity_wallet_code)
    )
    .map((item: bonusData) => {
      return {
        title: item.title,
        value: item.id,
      };
    });
  if (listBonusType.value.length) {
    listBonusType.value.push({
      title: "wallet_transaction.deposit.no_bonus",
      value: noBonusValue,
    });
    if (
      selectedBonusType.value &&
      selectedBonusType.value > 0 &&
      !listBonusType.value.some(
        (item: any) => item.value === selectedBonusType.value
      )
    ) {
      noBonusAvailableMess.value = true;
    }
  } else {
    if (selectedBonusType.value && selectedBonusType.value > 0) {
      noBonusAvailableMess.value = true;
    }
  }
};
getListBonusType();

const getSelectedNetWork = () => {
  const itemFind = listEntityWallets?.find(
    (item: entityWallet) =>
      item?.code === selectedWalletRef.value?.entity_wallet_code
  );
  if (itemFind) {
    selectedNetWork.value =
      itemFind?.networks?.length === 1 ? itemFind.networks[0] : "";
  }
};
getSelectedNetWork();
//
// const getValueByField = (field: string, kind: string) => {
//     let result =
//         listWalletDeposit.value && kind ? listWalletDeposit.value[kind][field] : "";
//     return result;
// };
//
// const getKindValue = (walletSelected: any) => {
//     let result = "";
//     let itemFind = listEntityWallets?.find(
//         (item: entityWallet) =>
//             item?.code === walletSelected?.entity_wallet_code
//     );
//     if (itemFind) {
//         result = itemFind?.networks?.length ? itemFind.networks[0].kind : "";
//     }
//     return result;
// };

const getFieldByKindValue = (kind: string) => {
  if (kind === WALLET_NETWORK_KIND.BTC_LIGHTNING) {
    return "payment_request";
  } else if (kind === WALLET_NETWORK_KIND.XRP) {
    return "x_address";
  } else {
    return "wallet_address";
  }
};

const getSelectedWalletObject = async () => {
  let kind = selectedNetWork.value.kind;
  let entity_wallet_code = selectedNetWork.value.coin_code;

  if (!kind || !entity_wallet_code) {
    walletDeposit.value = {} as userWalletAddress;
    scrollToBottomElement();
    return;
  }

  if (kind === WALLET_NETWORK_KIND.BTC_LIGHTNING) {
    walletDeposit.value = btcLightningAddress.value;
    scrollToBottomElement();
    return;
  }

  loading.value = true;
  await getWalletDeposit(entity_wallet_code, kind);
  loading.value = false;
  scrollToBottomElement();
};
getSelectedWalletObject();

const onWalletChange = (value: any) => {
  if (selectedWalletRef.value !== value) {
    selectedWalletRef.value = value;
    noBonusAvailableMess.value = false;
    getSelectedNetWork();
    getListBonusType();
    if (
      !listBonusType.value.length &&
      selectedBonusType.value &&
      selectedBonusType.value > 0 &&
      selectedBonusType.value != noBonusValue
    ) {
      noBonusAvailableMess.value = true;
    }

    getSelectedWalletObject();
  }
};

const onNetworkChange = (value: any) => {
  if (selectedNetWork.value !== value) {
    selectedNetWork.value = value;
    getSelectedWalletObject();
  }
};

const onBonusTypeChange = (selected: number) => {
  selectedBonusType.value = selected;
};

const onCopyString = (value?: string) => {
  if (value) {
    navigator.clipboard.writeText(value);
    $toast.success(t("common.message.copied"));
  }
};

// const goToTermCondition = () => {
//   changeWalletTransactionDialog(false);
//   navigateTo({ path: ROUTES_NAME.PAGE_DETAIL(PAGES.TERMS_AND_CONDITIONS) });
// };

onUnmounted(() => {
  bonusStore.bonusTypeParams = null;
  walletStore.btcLightningAddress = {} as userWalletAddress;
  walletStore.walletDeposit = {} as userWalletAddress;
});
</script>

<style scoped lang="postcss">
:deep(input) {
  @apply max-sm:!text-base;
}
</style>
