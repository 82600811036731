<template>
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.0527344"
      y="0.0526123"
      width="37.8947"
      height="37.8947"
      rx="18.9474"
      fill="#343843"
    />
    <path
      d="M18.999 19C20.9368 19 22.5078 17.429 22.5078 15.4912C22.5078 13.5534 20.9368 11.9824 18.999 11.9824C17.0612 11.9824 15.4902 13.5534 15.4902 15.4912C15.4902 17.429 17.0612 19 18.999 19Z"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M25.0268 26.0175C25.0268 23.3017 22.3251 21.1052 18.9988 21.1052C15.6725 21.1052 12.9707 23.3017 12.9707 26.0175"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
