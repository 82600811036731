<template>
  <div>
    <div ref="container" class="keen-slider max-md:pr-1.5">
      <div
        v-for="(slide, index) in sliders"
        :key="slide.id"
        :class="[
          `number-slide${
            index + 1
          } !aspect-[9/5] flex-[0_0_calc(86%-8px)] px-1.5 md:flex-[0_0_calc(50%)] lg:flex-[0_0_calc(33.333333%)]`,
        ]"
        class="keen-slider__slide pt-2"
        style="height: unset"
      >
        <NuxtLink
          draggable="false"
          :to="slide.urlRedirect ?? '#'"
          class="block size-full cursor-pointer select-none transition-all duration-300 hover:-translate-y-2"
          :alt="slide.title || ''"
          :aria-label="slide.title || 'banner'"
        >
          <NuxtImg
            :src="slide.srcPc"
            :alt="slide.title || ''"
            class="size-full overflow-hidden rounded-lg object-cover"
            loading="eager"
            quality="50"
          />
        </NuxtLink>
      </div>
    </div>
    <div
      v-if="slider && dotHelper.length > 3"
      class="box-center hidden gap-6 pt-6 md:flex"
    >
      <button
        v-for="(_slide, idx) in dotHelper"
        @click="() => update(idx)"
        :class="{
          '!size-3 !bg-main-pink': current === idx,
          'md:hidden': idx === dotHelper.length - 1,
          'lg:hidden': idx === dotHelper.length - 2,
        }"
        class="size-2 bg-white"
        :key="idx"
        :aria-label="`dot-${idx}`"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { BREAKPOINT } from "~/config/breakpoints";

type Props = {
  sliders: any[];
};

const isServer = ref(true);

onMounted(() => {
  isServer.value = false;
});

const props = defineProps<Props>();
const current = ref(0);
const [container, slider] = useKeenSlider({
  loop: false,
  slides: {
    perView: 1.2,
  },
  breakpoints: {
    [`(min-width: ${BREAKPOINT.md})`]: {
      slides: { perView: 2 },
    },
    [`(min-width: ${BREAKPOINT.lg})`]: {
      slides: { perView: 3 },
    },
  },
  initial: current.value,
  slideChanged: (s) => {
    current.value = s.track.details.rel;
  },
});
const dotHelper = computed(() => {
  return slider.value ? [...Array(props.sliders.length)] : [];
});

const update = (idx: number) => {
  slider.value?.emit;
  slider.value?.moveToIdx(idx, true);
};

let resizeObserver: any;
const updateSlide = _throttle(() => {
  slider?.value?.update();
}, 100);

onMounted(() => {
  resizeObserver = new ResizeObserver((entries) => {
    for (let entry of entries) {
      updateSlide();
    }
  });

  if (container.value) {
    resizeObserver.observe(container.value);
  }
});

onBeforeUnmount(() => {
  if (container.value) {
    resizeObserver.unobserve(container.value);
  }
  resizeObserver.disconnect();
});
</script>
