<template>
  <div
    id="auth-layout"
    style="display: flex; position: relative; overflow: hidden; flex: 1"
  >
    <LayoutsNavbar />
    <div class="relative flex-1 overflow-auto scroll-smooth" id="main">
      <LayoutsHeader />
      <div class="flex-grow-1 flex min-h-[600px] flex-col">
        <slot />
        <ClientOnly>
          <LazyLobbyTransactionSkeleton v-if="showLobbyTransaction" />
        </ClientOnly>
      </div>
      <LazyLayoutsFooter class="!pb-[6rem]" />
      <StickyNav class="block xl:hidden" />
      <LayoutsNavbarSearch v-model="navbarSearch" />
    </div>
    <LazyDialogStoreDialog />
  </div>
</template>
<script setup lang="ts">
import { useRoute } from "#app";
import { storeToRefs } from "pinia";
import { useNavbarSearch } from "~/stores/navbarSearchStore";
import { useSystemStore } from "~/stores/systemStore";

const route = useRoute();
const showLobbyTransaction = computed(() => route.meta.showLobbyTransaction);

const { changeListWalletDialog } = useSystemStore();
const { open: navbarSearch } = storeToRefs(useNavbarSearch());

onBeforeUpdate(() => {
  changeListWalletDialog(false);
});
</script>
<style>
#main {
  animation: slideToLeft 0.7s forwards;
}

@keyframes slideToLeft {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>
