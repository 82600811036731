import { defineStore } from "pinia";
import { getSettingsGeneral } from "~/service/settingService";
import { formatStateCount } from "~/utils";

export const useSettingStore = defineStore("useSettingStore", {
  state: () => ({
    challengeCount: 0,
    tournamentCount: 0,
    inboxCount: 0,
  }),
  getters: {
    getChallengeCount: (state) => formatStateCount(state.challengeCount),
    getTournamentCount: (state) => formatStateCount(state.tournamentCount),
    getInboxCount: (state) => formatStateCount(state.inboxCount),
  },
  actions: {
    setInboxCount(num: number) {
      this.inboxCount = num;
    },
    async setDataSettingGeneral() {
      try {
        const res = await getSettingsGeneral();
        if (res.data.success) {
          this.challengeCount = res?.data?.data?.challenge_count;
          this.tournamentCount = res?.data?.data?.tournament_count;
          this.inboxCount = res?.data?.data?.inbox_count;
        }
      } catch (e: any) {}
    },
  },
});
