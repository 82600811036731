<template>
  <LobbySwiper
    :title="name"
    :listData="listData"
    :id="`${slug}-${props.type}`"
    :link="ROUTES_NAME.GAME_CATEGORIES_DETAIL(slug)"
    :svgIcon="img"
    :next-slide-amount="width < 1200 ? 2 : 3"
  >
    <div
      v-for="(game, index) in listData?.slice(0, MAX_ITEM_SHOW_SLIDER)"
      :key="game.id"
      class="keen-slider__slide flex-shrink-0 px-1.5 pt-2 md:px-2"
      :class="[
        `number-slide${index + 1}`,
        isBottom
          ? '!size-40 md:!size-44 lg:!size-48 xl:!size-52'
          : '!size-40 md:!size-52 lg:!size-56 xl:!size-60',
      ]"
    >
      <SlideViewGame :game="game" :slug="slug" />
    </div>
  </LobbySwiper>
</template>
<script setup lang="ts">
import { useDisplay } from "vuetify";
import { MAX_ITEM_SHOW_SLIDER } from "~/config/constant";
import { ROUTES_NAME } from "~/config/router";

type Props = {
  slug: string;
  name: string;
  type: string;
  img: string;
  listData: any[];
  isBottom: boolean;
};
const props = defineProps<Partial<Props>>();
const { width } = useDisplay();
</script>
