import { defineNuxtRouteMiddleware } from "#app";
import { storeToRefs } from "pinia";
import { ROUTES_NAME } from "~/config/router";
import { useGetGameCategories } from "~/queries/gameService";
import { useGetListEntityWallets } from "~/queries/walletService";
import { useAuthStore } from "~/stores/authStore";
import { useNotificationStore } from "~/stores/notificationStore";
import { useWalletStore } from "~/stores/walletStore";

export default defineNuxtRouteMiddleware(async (to) => {
  const token = useToken();
  const walletStore = useWalletStore();
  const authStore = useAuthStore();
  const notificationStore = useNotificationStore();
  const { fetchUser } = authStore;
  const { setListWallet, setListCurrencies } = walletStore;
  const { user } = storeToRefs(authStore);
  const { listCurrencies, listEntityWallets } = storeToRefs(walletStore);
  const promises: Promise<any>[] = [];
  const { suspense, data: categories } = useGetGameCategories();

  if (!categories.value) {
    promises.push(suspense());
  }
  if (!listCurrencies.value) {
    promises.push(setListCurrencies());
  }
  if (token.value) {
    if (!listEntityWallets.value) {
      const { suspense: setListEntityWallet } = useGetListEntityWallets();
      promises.push(setListEntityWallet());
    }
    if (!Object.keys(user?.value || {}).length) {
      promises.push(fetchUser());
      promises.push(setListWallet());
    }
  }

  await Promise.all(promises);
});
