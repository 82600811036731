<template>
  <slot />
</template>
<script lang="ts" setup>
import { useNuxtApp } from "#app";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-vue-v3";
import { watch } from "vue";
import { useToken } from "~/composables/useToken";
import { ROUTES_NAME } from "~/config/router";
import { useAuthStore } from "~/stores/authStore";

const token = useToken();
const route = useRoute();

// Toggle visibility of the Telegram back button
const toggleBackButtonVisibility = () => {
  if (window.history.length > 1 && route.path != ROUTES_NAME.LOBBY) {
    window.Telegram.WebApp.BackButton.show();
  } else {
    window.history.replaceState(null, "", route.fullPath);
    window.Telegram.WebApp.BackButton.hide();
  }
};
const loader = ref<any>();

// Handle Telegram Login Logic
const handleTelegramLogin = async (user_data: any) => {
  const referenceIdCookie = useCustomCookie("reference_id");
  const affiliateTokenCookie = useCustomCookie("affiliate_token");

  const reference = referenceIdCookie.value || null;
  const affiliate_token = affiliateTokenCookie.value || null;
  const { getData } = useVisitorData({ extendedResult: true });
  const data = await getData({ ignoreCache: true });
  const visitor_id = data?.visitorId || null;

  const dataRequest = {
    user_data,
    reference,
    affiliate_token,
    visitor_id,
  };

  const authStore = useAuthStore();
  await authStore.telegramLogin(dataRequest);
};
const onCheckLoginTelegram = async () => {
  if (window.Telegram?.WebApp || route.query.tgWebAppData) {
    const user_data =
      window.Telegram.WebApp.initData || route.query.tgWebAppData;

    if (user_data && !token.value) {
      loader.value = (useNuxtApp() as any).$loading.show(
        useNuxtApp().$configLoading
      );
      await handleTelegramLogin(user_data);
      loader.value.hide?.();
    }
    toggleBackButtonVisibility();
    window.Telegram.WebApp.enableClosingConfirmation();

    window.Telegram.WebApp.BackButton.onClick(() => {
      if (window.history.length > 1) {
        window.history.back(); // Navigate to the previous page
      }
    });
    watch(() => route.fullPath, toggleBackButtonVisibility);
  }
};

onMounted(async () => {
  await onCheckLoginTelegram();
});
</script>
