<template>
  <v-dialog v-model="unVerifyEmailDialog" width="auto">
    <v-card
      class="mx-[10px] w-[calc(100vw-68px)] max-w-[560px] !rounded-[16px] !bg-main-dark !px-[32px] !py-[40px]"
    >
      <img
        src="/images/icon/security-user-icon.png"
        alt="security-user-icon"
        class="m-auto my-0 !mb-[24px] w-[102px]"
      />
      <span
        class="mb-[16px] text-center text-[24px] font-[700] uppercase leading-[32px] text-[#ffffff]"
      >
        {{ $t("common.message.verify_now") }}
      </span>
      <span
        class="m-auto mb-[32px] text-center text-[14px] font-[400] leading-[21px] text-[#F2D6FF]"
      >
        {{ $t("dialog.un_verify_email.verify_message") }}
      </span>
      <span class="line-gradient mb-[32px]"></span>
      <button
        @click="resendEmail()"
        class="mx-auto flex h-[56px] w-fit items-center gap-2 !rounded-lg bg-main-pink px-8 font-extrabold capitalize text-white"
      >
        {{ $t("common.message.resend_link") }}
        <svg
          class="size-4"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.93994 13.28L10.2866 8.93333C10.7999 8.42 10.7999 7.58 10.2866 7.06667L5.93994 2.72"
            stroke="white"
            stroke-width="1.2"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { useRoute } from "#app";
import { storeToRefs } from "pinia";
import { useAuthStore } from "~/stores/authStore";
import { useSystemStore } from "~/stores/systemStore";

const useSystem = useSystemStore();
const { unVerifyEmailDialog } = storeToRefs(useSystem);
const { changeUnVerifyEmailDialog } = useSystem;

const authStore = useAuthStore();
const route: any = useRoute();
const resendEmail = async () => {
  await authStore.resendVerifyMail(authStore.emailLogging);
  changeUnVerifyEmailDialog(false);
};
</script>
<style scoped lang="scss">
:deep(.line-gradient) {
  height: 2px;
  width: 100%;
  display: block;
  background: linear-gradient(
    90deg,
    rgba(192, 173, 216, 0) 0%,
    rgba(189, 173, 216, 0.4) 49.48%,
    rgba(192, 173, 216, 0) 100%
  );
}

:deep(.btn-gradient) {
  background: linear-gradient(
    270.46deg,
    #ff3eca 0.23%,
    #9b37ff 40%,
    #8378ff 55.55%,
    #53ccff 98.94%
  );
  font-size: 15px;
  font-weight: 700;
  line-height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-left: 12px;
    font-size: 21px;
  }

  &:hover {
    opacity: 0.8;
  }
}
</style>
