<template>
  <footer class="!bg-main-175 px-4 py-10 lg:!px-10">
    <div
      class=":!mb-10 mb-8 flex flex-col gap-8 border-b !border-main-150 pb-10 xl:flex-row xl:gap-[56px]"
    >
      <div class="grid flex-1 grid-cols-2 gap-8 lg:grid-cols-4 xl:gap-[56px]">
        <div class="flex flex-col gap-6 px-2">
          <h4 class="text-base font-medium text-white">
            {{ $t("common.casino") }}
          </h4>
          <ClientOnly>
            <ul class="flex flex-col gap-2">
              <li
                v-for="category in filteredCategories.inLobby"
                :key="category.slug"
              >
                <NuxtLink
                  :to="ROUTES_NAME.GAME_CATEGORIES_DETAIL(category.slug)"
                  class="text-sm text-neutral-75-new hover:underline"
                  aria-label="category"
                  >{{ category.name }}
                </NuxtLink>
              </li>
            </ul>
          </ClientOnly>
        </div>
        <div class="flex flex-col gap-6 px-2">
          <h4 class="text-base font-medium text-white">
            {{ $t("common.live_casino") }}
          </h4>
          <ClientOnly>
            <ul class="flex flex-col gap-2">
              <li
                v-for="category in filteredCategories.inLiveLobby"
                :key="category.slug"
              >
                <NuxtLink
                  :to="ROUTES_NAME.GAME_CATEGORIES_DETAIL(category.slug)"
                  class="text-sm text-neutral-75-new hover:underline"
                  aria-label="category"
                >
                  {{ category.name }}
                </NuxtLink>
              </li>
            </ul>
          </ClientOnly>
        </div>
        <div class="flex flex-col gap-6 px-2">
          <h4 class="text-base font-medium text-white">
            {{ $t("common.promotions") }}
          </h4>
          <ul class="flex flex-col gap-2">
            <li>
              <NuxtLink
                aria-label="category"
                :to="ROUTES_NAME.VIP_PROGRAM"
                class="text-sm text-neutral-75-new hover:underline"
                >{{ $t("footer.vip_club") }}
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                aria-label="category"
                :to="ROUTES_NAME.PROMOTION_LIST"
                class="text-sm text-neutral-75-new hover:underline"
              >
                {{ $t("common.promotions") }}
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                aria-label="category"
                :to="ROUTES_NAME.CHALLENGES"
                class="text-sm text-neutral-75-new hover:underline"
              >
                {{ $t("common.challenges") }}
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                aria-label="category"
                :to="ROUTES_NAME.TOURNAMENT_LIST"
                class="text-sm text-neutral-75-new hover:underline"
              >
                {{ $t("common.tournament") }}
              </NuxtLink>
            </li>
          </ul>
        </div>
        <div class="flex flex-col gap-6 px-2">
          <h4 class="text-base font-medium text-white">
            {{ $t("footer.support_legal") }}
          </h4>
          <ul class="flex flex-col gap-2">
            <li>
              <NuxtLink
                aria-label="category"
                :to="ROUTES_NAME.PAGE_DETAIL(PAGES.CONTACTS)"
                class="text-sm text-neutral-75-new hover:underline"
              >
                {{ $t("footer.contacts") }}
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                aria-label="category"
                :to="ROUTES_NAME.PAGE_DETAIL(PAGES.TERMS_AND_CONDITIONS)"
                class="text-sm text-neutral-75-new hover:underline"
              >
                {{ $t("common.term_and_condition") }}
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                aria-label="category"
                :to="ROUTES_NAME.PAGE_DETAIL(PAGES.FAQS)"
                class="text-sm text-neutral-75-new hover:underline"
              >
                {{ $t("common.faq") }}
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                aria-label="category"
                :to="ROUTES_NAME.PAGE_DETAIL(PAGES.PRIVACY_POLICY)"
                class="text-sm text-neutral-75-new hover:underline"
              >
                {{ $t("common.privacy_policy") }}
              </NuxtLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="mb-6 border-b !border-main-150 pb-10 lg:!mb-10">
      <h4 class="mb-6 text-center text-base font-medium text-white">
        {{ $t("footer.heading_networks") }}
      </h4>
      <ClientOnly>
        <ListCard />
      </ClientOnly>
    </div>
    <div class="mb-6 lg:!mb-10">
      <h4 class="mb-6 text-center text-base font-medium text-white">
        {{ $t("footer.heading_sponsorship") }}
      </h4>
      <div
        class="grid grid-cols-3 gap-4 sm:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5"
      >
        <div
          class="pointer-events-none flex flex-1 select-none items-center justify-center xl:px-9"
        >
          <img
            loading="lazy"
            src="/images/icon-logo/footer/cointelegraph.svg"
            alt="Logo"
            class="h-full object-contain"
          />
        </div>
        <div
          class="pointer-events-none flex flex-1 select-none items-center justify-center xl:px-9"
        >
          <img
            loading="lazy"
            src="/images/icon-logo/footer/trustpilot-logo-white.svg"
            alt="Logo"
            class="h-full object-contain"
          />
        </div>
        <div
          class="pointer-events-none flex flex-1 select-none items-center justify-center xl:px-9"
        >
          <img
            loading="lazy"
            src="/images/icon-logo/footer/askgamblers.svg"
            alt="Logo"
            class="h-full object-contain"
          />
        </div>
        <div
          class="pointer-events-none flex flex-1 select-none items-center justify-center xl:px-9"
        >
          <img
            loading="lazy"
            src="/images/icon-logo/footer/bitcoin.svg"
            alt="Logo"
            class="h-full object-contain"
          />
        </div>
        <div
          class="pointer-events-none flex flex-1 select-none items-center justify-center xl:px-9"
        >
          <img
            loading="lazy"
            src="/images/icon-logo/footer/logo-cryptonews.svg"
            alt="Logo"
            class="h-full object-contain"
          />
        </div>
      </div>
    </div>
    <div class="mb-6 border-b !border-main-150 pb-10">
      <div class="flex items-center justify-center gap-8">
        <img
          loading="lazy"
          src="/images/icon/18-plus-warning.svg"
          alt=""
          class="h-20 w-20 object-contain"
        />
        <ClientOnly>
          <FooterAnalyticTag />
        </ClientOnly>
      </div>
    </div>
    <ClientOnly>
      <FooterSocial />
    </ClientOnly>

    <div class="gap-6 text-left lg:gap-10">
      <ClientOnly>
        <FooterNote />
      </ClientOnly>
      <p class="mb-4 text-xs text-neutral-75-new">
        {{ $t("footer.bottom_footer_2", { email: "support@casinopunkz.com" }) }}
      </p>
      <p class="mb-3 text-xs text-neutral-75-new">
        {{ $t("footer.footer_copy") }}
      </p>
    </div>
  </footer>
</template>

<script setup lang="ts">
import FooterSocial from "~/components/layouts/FooterSocial.vue";
import { DISPLAY_SECTION, PAGES } from "~/config/enum";
import { ROUTES_NAME } from "~/config/router";
import { useGetGameCategories } from "~/queries/gameService";
import { IGameCategories } from "~/types/schema";
import FooterAnalyticTag from "./FooterAnalyticTag.client.vue";
import FooterNote from "./FooterNote.client.vue";

const { data: categories } = useGetGameCategories();

const filteredCategories = computed(() => {
  const inLobby: IGameCategories[] = [];
  const inLiveLobby: IGameCategories[] = [];
  categories.value?.forEach((category: IGameCategories) => {
    if (category.display_sections.includes(DISPLAY_SECTION.IN_CASINO_FOOTER)) {
      inLobby.push(category);
    }
    if (
      category.display_sections.includes(DISPLAY_SECTION.IN_LIVE_CASINO_FOOTER)
    ) {
      inLiveLobby.push(category);
    }
  });
  return {
    inLobby,
    inLiveLobby,
  };
});
</script>
