import { defineStore } from "pinia";
import { WALLET_TRANSACTION_ACTION } from "~/config/enum";
import { getGameDetail } from "~/service/gameService";
import {
  getUserWallets,
  changeSelectedWallet,
  getListWalletCurrenciesRate,
  updateWalletSetting,
  getListEntityWallets,
  getWalletsDeposit,
  getBtcLightingAddress,
} from "~/service/walletService";
import {
  wallet,
  entityWallet,
  updateWalletSettingPayload,
  lightnightPayload,
  userWalletAddress,
} from "~/types/schema";
import { useAuthStore } from "./authStore";
import { useSystemStore } from "./systemStore";

export const useWalletStore = defineStore("useWalletStore", {
  state: () => ({
    listWallet: [] as wallet[],
    listWalletOriginal: [] as wallet[],
    selectedWallet: {} as wallet,
    userWallet: null as any, // this value to check selected wallet change to reload game play in play-game page
    openWallet: false as boolean,
    listCurrencies: null as any,
    listEntityWallets: null as unknown as entityWallet[],
    gameDetail: null as any,
    walletDeposit: {} as userWalletAddress,
    btcLightningAddress: {} as userWalletAddress,
    walletTransactionParams: WALLET_TRANSACTION_ACTION.DEPOSIT,
    getWalletAddressLoading: false,
    getWalletsLoading: false,
  }),
  getters: {
    getOpenWalletState: (state) => state.openWallet,
    getListWallet: (state) => state.listWallet,
    getSelectedWallet: (state) => state.selectedWallet,
    getListCurrencies: (state) => state.listCurrencies,
    getListEntityWallets: (state) => state.listEntityWallets,
  },
  actions: {
    setSelectedWallet(value: any) {
      this.selectedWallet = value;
    },

    clearDataWallet() {
      this.userWallet = null;
      this.listWalletOriginal = [];
      this.listWallet = [];
      this.walletDeposit = {} as userWalletAddress;
      this.btcLightningAddress = {} as userWalletAddress;
    },

    updateWalletBalance(value: any) {
      if (
        this.selectedWallet.entity_wallet_code === value?.entity_wallet_code
      ) {
        this.selectedWallet = { ...value };
      }
      this.listWallet.forEach((item: any, index: number) => {
        if (item.entity_wallet_code === value?.entity_wallet_code) {
          this.listWallet[index] = { ...value };
        }
      });
      this.listWalletOriginal.forEach((item: any, index: number) => {
        if (item.entity_wallet_code === value?.entity_wallet_code) {
          this.listWalletOriginal[index] = { ...value };
        }
      });
    },

    async changeSelectedWalletApi(
      code: string,
      isRemember?: boolean,
      isMobileDevice?: boolean
    ) {
      try {
        const res = await changeSelectedWallet({ code: code });
        if (res.data.success) {
          this.selectedWallet = res?.data?.data;
          this.userWallet = res?.data?.data;
          this.openWallet = false;
        }
      } catch (e: any) {}
    },

    async setListWallet() {
      this.getWalletsLoading = true;
      try {
        const res = await getUserWallets();
        if (res.success) {
          this.listWallet = res?.data;
          this.listWalletOriginal = res?.data;
          const itemFind = this.listWallet?.find(
            (item: any) => item?.is_selected
          );
          if (itemFind) {
            this.selectedWallet = itemFind;
          } else {
            this.selectedWallet = {} as wallet;
          }
        }
      } catch (e: any) {}
      this.getWalletsLoading = false;
    },

    updateListWallet(array: wallet[]) {
      this.listWallet = array;
    },

    async setListCurrencies() {
      try {
        const res = await getListWalletCurrenciesRate();
        if (res.success) {
          this.listCurrencies = res?.data;
        }
      } catch (e: any) {}
    },

    async updateWalletSettingApi(payload: updateWalletSettingPayload) {
      const useSystem = useSystemStore();
      useSystem.changeWalletSettingsDialog(false);
      try {
        const res = await updateWalletSetting(payload);
        if (res.data.success) {
          const authStore = useAuthStore();
          authStore.fetchUser();
        }
      } catch (e: any) {}
    },

    async setListEntityWallet() {
      try {
        const res = await getListEntityWallets();
        if (res.success) {
          this.listEntityWallets = res?.data;
        }
      } catch (e: any) {}
    },

    async getWalletDeposit(entityWalletCode: string, kind: string) {
      try {
        const res = await getWalletsDeposit(entityWalletCode, kind);
        if (res.data.success) {
          this.walletDeposit = res?.data?.data;
        }
        return res;
      } catch (e: any) {
        this.walletDeposit = {} as userWalletAddress;
        (useNuxtApp() as any).$toast.error(
          e.response?.data.message || e.message
        );
      } finally {
      }
    },

    async getGameDetailApi(code: string) {
      try {
        const res = await getGameDetail(code);
        if (res.success) {
          this.gameDetail = res?.data;
        }
      } catch (e: any) {}
    },

    async getBtcLightingAddress(payload: lightnightPayload) {
      try {
        const res = await getBtcLightingAddress(payload);
        if (res.data.success && res.data?.data) {
          this.btcLightningAddress = this.walletDeposit = res.data.data;
        }

        return res.data;
      } catch (e: any) {
        this.btcLightningAddress = {} as userWalletAddress;
        return e?.response?.data;
      }
    },
  },
});
