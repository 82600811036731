import { defineStore } from "pinia";
import { TRANSACTION_LOBBY } from "~/config/enum";
import {
  getHighRollerBetList,
  getLastestBetList,
  getLastestBetListByUser,
  getTransactionHistories,
} from "~/service/transactionHistoryService";
import { transactionHistoryParams, coinHistoriesList } from "~/types/schema";
import { API_LOADING_TIMEOUT } from "./../config/constant";

export const useTransactionHistoryStore = defineStore(
  "useTransactionHistoryStore",
  {
    state: () => ({
      listHistories: [] as coinHistoriesList[],
      totalHistories: 0,
      loading: true,
      listTransactionData: [] as any,
      listTransactionDataOriginal: [] as any,
      loadingTransaction: true,
    }),
    actions: {
      async getHistories(payload: transactionHistoryParams) {
        try {
          this.loading = true;
          const res = await getTransactionHistories(payload);
          if (res.data.success) {
            this.totalHistories = res?.data?.data.total;
            this.listHistories = res?.data?.data.data;
          }
        } catch (e: any) {
        } finally {
          setTimeout(() => {
            this.loading = false;
          }, API_LOADING_TIMEOUT);
        }
      },
      async getListTransaction(type: string) {
        this.loadingTransaction = true;
        try {
          let request = null as any;
          if (type === TRANSACTION_LOBBY.USER_LASTEST_BET) {
            request = getLastestBetListByUser();
          }
          if (type === TRANSACTION_LOBBY.LASTEST_BET) {
            request = getLastestBetList();
          }
          if (type === TRANSACTION_LOBBY.HIGH_ROLLER) {
            request = getHighRollerBetList();
          }
          const res = await request;
          if (res.data.success) {
            this.listTransactionData = res.data.data;
            this.listTransactionDataOriginal = res.data.data;
          }
        } catch (e: any) {
        } finally {
          setTimeout(() => {
            this.loadingTransaction = false;
          }, API_LOADING_TIMEOUT);
        }
      },
    },
  }
);
