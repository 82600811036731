import Echo from "laravel-echo";
import Pusher from "pusher-js";

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();
  const tokenCookie = useCustomCookie("token");
  const initEcho = () => {
    if (window.Echo) window.Echo.disconnect(); // Disconnect if Echo already exists
    window.Pusher = Pusher;
    window.Echo = new Echo({
      broadcaster: config.public.BROADCAST_DRIVER as any,
      key: config.public.PUSHER_APP_KEY,
      cluster: config.public.PUSHER_APP_CLUSTER,
      forceTLS: false,
      authEndpoint: config.public.PUSHER_BROASTCAST_URL,
      auth: {
        headers: {
          Authorization: "Bearer " + tokenCookie.value,
        },
      },
    });
  };

  initEcho();
  const disconnectEcho = () => {
    if (window.Echo) {
      window.Echo.disconnect();
      window.Echo = null;
    }
  };

  watch(tokenCookie, (current) => {
    if (current) {
      if (window.Echo) {
        window.Echo.connector.options.auth.headers.Authorization =
          "Bearer " + current;
      } else {
        initEcho();
      }
    } else {
      disconnectEcho();
    }
  });

  return {
    provide: {
      initEcho,
      disconnectEcho,
    },
  };
});
