import { defineStore } from "pinia";
import { getNotifications } from "~/service/notificationService";
import { filterNotification, Notification } from "~/types/schema";

export const useNotificationStore = defineStore("useNotificationStore", {
  state: () => ({
    loading: true,
    notifications: [] as Notification[],
    totalNotification: 0,
  }),
  getters: {
    getNotifications: (state) => state.notifications,
  },
  actions: {
    async getDataNotifications(payload: filterNotification) {
      this.loading = true;
      try {
        const res = await getNotifications(payload);
        if (res.data.success) {
          this.notifications = res?.data?.data?.data;
          this.totalNotification = res?.data?.data?.total;
        }
      } catch (e: any) {
      } finally {
        this.loading = false;
      }
    },
  },
});
