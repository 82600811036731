import { PAGES } from "./enum";
import { ROUTES_NAME } from "./router";

export const siteInfo = {
  disableVerifyUser: true,
  footerPages: [
    {
      title: "common.term_and_condition",
      url: ROUTES_NAME.PAGE_DETAIL(PAGES.TERMS_AND_CONDITIONS),
    },
    {
      title: "common.privacy_policy",
      url: ROUTES_NAME.PAGE_DETAIL(PAGES.PRIVACY_POLICY),
    },
    {
      title: "common.responsible_gaming",
      url: ROUTES_NAME.PAGE_DETAIL(PAGES.RESPONSIBLE_SOCIAL_GAMING),
    },
  ],
  faqPage: {
    title: "common.faq",
    url: ROUTES_NAME.PAGE_DETAIL(PAGES.FAQS),
  },
  url: `${process.env.APP_URL}/`,
  title: "Casinopunkz",
  description: "Best slot games and promotions on Casinopunkz",
  image:
    "https://tradebrains.in/features/wp-content/uploads/2022/02/Online-Casinos-in-India-Cover-Image-1080x675.jpg",
  author: "Casinopunkz",
  site_name: "Casinopunkz",
  locale: "en_US",
  sites: {
    "casinopunkz.io": {
      src: "https://cef576e7-714a-4d4a-922f-4d4b73e76893.snippet.anjouangaming.org/anj-seal.js",
      id: "anj-cef576e7-714a-4d4a-922f-4d4b73e76893",
      dataAnjSealId: "cef576e7-714a-4d4a-922f-4d4b73e76893",
      dataAnjImageSize: "128",
      var: "anj_cef576e7_714a_4d4a_922f_4d4b73e76893",
      title: "Casinopunkz.io - Anonymous & Instant Crypto Casino",
      description:
        "The Anonymous & Instant Crypto Casino. Welcome to Casinopunkz.io, the playful crypto casino for those who value their privacy and love instant transactions.",
      umami_website_url: "https://umami.casinopunkz.games/script.js",
      umami_website_id: "fea56582-6576-43f0-a15d-e7998847e337",
    },
    "casinopunkz.games": {
      src: "https://547adbbe-ae40-4198-9be9-56562b68acd6.snippet.anjouangaming.org/anj-seal.js",
      id: "anj-547adbbe-ae40-4198-9be9-56562b68acd6",
      dataAnjSealId: "547adbbe-ae40-4198-9be9-56562b68acd6",
      dataAnjImageSize: "128",
      var: "anj_547adbbe_ae40_4198_9be9_56562b68acd6",
      title: "Casinopunkz.games - Anonymous & Instant Crypto Casino",
      description:
        "The Anonymous & Instant Crypto Casino. Welcome to Casinopunkz.games, the playful crypto casino for those who value their privacy and love instant transactions.",
      umami_website_url: "",
      umami_website_id: "",
    },
  },
};
