<template>
  <LobbySwiper
    v-if="listData?.length"
    :title="$t('common.providers')"
    :listData="listData"
    :id="'provider'"
    :link="ROUTES_NAME.PROVIDER_LIST"
    :svgIcon="icon"
    :next-slide-amount="width < 1200 ? 2 : 3"
  >
    <div
      v-for="(item, index) in listData?.slice(0, MAX_ITEM_SHOW_SLIDER)"
      :key="index"
      class="keen-slider__slide aspect-[20/11] !w-48 flex-shrink-0 px-1.5 pt-2 sm:!w-56 md:!w-60 md:px-2 lg:!w-64 xl:!w-[270px]"
      :class="`number-slide${index + 1}`"
    >
      <NuxtLink
        :to="`${ROUTES_NAME.PROVIDER_LIST}/${item?.provider}`"
        class="box-center size-full !rounded-xl bg-main-175 transition-all duration-300 hover:-translate-y-2"
      >
        <img
          loading="lazy"
          :src="item?.image"
          class="h-1/2 w-3/4 object-contain"
          alt=""
        />
      </NuxtLink>
    </div>
  </LobbySwiper>
</template>

<script setup lang="ts">
import { useDisplay } from "vuetify";
import { MAX_ITEM_SHOW_SLIDER } from "~/config/constant";
import { ROUTES_NAME } from "~/config/router";
import { useGetAllProviders } from "~/queries/gameService";

const navbarList = useNavbar();
const icon = computed(() => {
  return navbarList.value.find((item) => item.id === 11);
});

const { data: listData } = useGetAllProviders();
const { width } = useDisplay();
</script>

<style scoped></style>
