<template>
  <Scrollbar class="max-h-[40vh] py-0.5 pr-3">
    <div v-if="listHistoryRef.length" class="flex max-h-[450px] flex-col gap-4">
      <div v-for="data in listHistoryRef" :key="data.id" class="history-item">
        <div class="mb-2 flex items-center justify-between gap-2">
          <div class="flex items-center gap-3 truncate">
            <img src="/images/icon/bonus-star-icon-new.svg" alt="" />
            <p class="truncate text-sm font-bold text-white">
              {{ data.title || "" }}
            </p>
          </div>
        </div>
        <CollapseItem
          :data="data"
          :open-details="openDetails"
          @update-list-open-details="
            (id) => (openDetails = handleBonusDetailsCollapse(id, openDetails))
          "
        >
          <div class="flex flex-col overflow-hidden">
            <detail-item
              :label="$t('common.status')"
              :value="$t(getBonusStatusText(data.status))"
            />
            <detail-item :label="$t('common.id')" :value="data.id" />
            <detail-item :label="$t('common.currency')">
              <template #value>
                <div
                  v-if="data.entity_wallet_code"
                  class="h-5 w-5 flex-shrink-0"
                >
                  <img
                    loading="lazy"
                    :src="getWalletImageByCode(data.entity_wallet_code)"
                    :alt="data.entity_wallet_code"
                    class="h-full w-full object-contain"
                  />
                </div>
              </template>
            </detail-item>
            <detail-item
              :label="$t('common.activated_on')"
              :value="
                data.start_active_time
                  ? dayjs(data.start_active_time).format(
                      `YYYY-MM-DD [${$t('bonus.available.at')}] HH:mm`
                    )
                  : ''
              "
            />
            <detail-item
              :label="handleDataByStatus(data).label"
              :value="
                handleDataByStatus(data).value
                  ? dayjs(handleDataByStatus(data).value).format(
                      `YYYY-MM-DD [${$t('bonus.available.at')}] HH:mm`
                    )
                  : ''
              "
            />
            <detail-item :label="$t('bonus.history.total_wagered')">
              <template #value>
                <div class="flex items-center gap-2">
                  <div
                    v-if="data.entity_wallet_code"
                    class="h-4 w-4 flex-shrink-0"
                  >
                    <img
                      loading="lazy"
                      :src="getWalletImageByCode(data.entity_wallet_code)"
                      :alt="data.entity_wallet_code"
                      class="h-full w-full object-contain"
                    />
                  </div>
                  <p class="flex-grow text-nowrap font-bold text-white">
                    {{ handleTotalWagerd(data) }}
                  </p>
                </div>
              </template>
            </detail-item>
            <detail-item :label="$t('bonus.history.converted_bonus_amount')">
              <template #value>
                <div class="flex items-center gap-2">
                  <div
                    v-if="data.entity_wallet_code"
                    class="h-4 w-4 flex-shrink-0"
                  >
                    <img
                      loading="lazy"
                      :src="getWalletImageByCode(data.entity_wallet_code)"
                      :alt="data.entity_wallet_code"
                      class="h-full w-full object-contain"
                    />
                  </div>
                  <p class="flex-grow text-nowrap font-bold text-white">
                    {{ data.transfer_amount }}
                  </p>
                </div>
              </template>
            </detail-item>
          </div>
        </CollapseItem>
      </div>
      <div
        class="flex items-center justify-center"
        :class="[
          { hidden: isLastPage },
          loadingMore ? 'opacity-1' : 'opacity-0',
        ]"
        v-intersect="{
          handler: loadMore,
          options: {
            threshold: [1.0, 1.0, 1.0],
          },
        }"
      >
        <span class="loader"></span>
      </div>
    </div>
    <div
      v-else
      class="flex h-[300px] items-center justify-center text-sm text-white"
    >
      {{ $t("common.message.no_bonus_found") }}
    </div>
  </Scrollbar>
</template>

<script setup lang="ts">
import dayjs from "dayjs";
import { storeToRefs } from "pinia";
import { API_LOADING_TIMEOUT } from "~/config/constant";
import { BONUS_STATUS, BONUS_TAB_TYPE } from "~/config/enum";
import { useBonusStore } from "~/stores/bonusStore";
import { bonusData } from "~/types/schema";
import { getBonusStatusText, handleBonusDetailsCollapse } from "~/utils";
import CollapseItem from "./CollapseItem.vue";
import DetailItem from "./DetailItem.vue";

const { t } = useNuxtApp().$i18n || {};

const bonusStore = useBonusStore();
const { totalHistory } = storeToRefs(bonusStore);
const { getBonusListDataByType } = bonusStore;
const page = ref(1);

const loadingMore = ref(true);
const listHistoryRef = ref(bonusStore.listHistory);
const isLastPage = ref(listHistoryRef.value?.length >= totalHistory.value);
const openDetails = ref<number[]>(
  listHistoryRef.value?.map((item: bonusData) => item.id)
);

const handleDataByStatus = (bonus: bonusData) => {
  let label = "";
  let value = "";
  if (bonus.status === BONUS_STATUS.EXPIRED) {
    label = t("common.expiry_date");
    value = bonus.end_active_time;
  }
  if (bonus.status === BONUS_STATUS.COMPLETED) {
    label = t("bonus.history.complete_date");
    value = bonus.finished_at;
  }
  return {
    label,
    value,
  };
};

const handleTotalWagerd = (bonus: bonusData) => {
  const betRequirementAmount = bonus.bet_requirement_amount;
  if (betRequirementAmount == null) {
    return "";
  }

  const betAmount = roundDecimalNumber(
    Math.min(Number(bonus.bet_amount || 0), Number(betRequirementAmount)),
    8
  );
  return `${betAmount}/${betRequirementAmount}`;
};

const loadMore = async (isIntersecting: any, entries: any, observer: any) => {
  if (!isIntersecting) return;

  if (!isLastPage.value) {
    // handle load more
    loadingMore.value = true;
    page.value += 1;
    await getBonusListDataByType(BONUS_TAB_TYPE.HISTORY, page.value);
    setTimeout(() => {
      openDetails.value = [
        ...openDetails.value,
        ...bonusStore.listHistory?.map((item: bonusData) => item.id),
      ];
      listHistoryRef.value = [
        ...listHistoryRef.value,
        ...bonusStore.listHistory,
      ];
      loadingMore.value = false;
      if (listHistoryRef.value?.length >= totalHistory.value) {
        isLastPage.value = true;
      }
    }, API_LOADING_TIMEOUT);
  }
};
</script>

<style scoped lang="scss"></style>
