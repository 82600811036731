import { defineNuxtRouteMiddleware, navigateTo } from "#app";
import { ROUTES_NAME } from "~/config/router";
import { useCheckLocation } from "~/queries/common";

export default defineNuxtRouteMiddleware((to, from) => {
  if (import.meta.server) return;
  const token = useToken();
  const { refetch } = useCheckLocation();
  refetch().then((res) => {
    const { data: isBlock } = res;
    if (isBlock && to.path !== ROUTES_NAME.NOT_AVAILABLE) {
      token.value = null;
      return navigateTo({
        path: ROUTES_NAME.NOT_AVAILABLE,
        replace: true,
      });
    }
    if (isBlock === false && to.path === ROUTES_NAME.NOT_AVAILABLE) {
      return navigateTo({
        path: ROUTES_NAME.LOBBY,
        replace: true,
      });
    }
    return res as any;
  });
});
