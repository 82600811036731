import { useQuery } from "@tanstack/vue-query";
import { storeToRefs } from "pinia";
import { useBonusStore } from "~/stores/bonusStore";
import { bonusData, IDataResponse } from "~/types/schema";

export const useGetAvailableBonus = (enabled = ref(true)) => {
  const { listAvailable } = storeToRefs(useBonusStore());
  const { $api } = useNuxtApp();
  const { data, ...rest } = useQuery<bonusData[]>({
    queryKey: ["useGetAvailableBonus"],
    queryFn: async () => {
      const response = (await $api.get(
        "/api/bonuses/available-bonuses"
      )) as IDataResponse<bonusData[]>;
      listAvailable.value = response.data.data;
      return response.data.data;
    },
    staleTime: 1000,
    enabled,
  });
  return { data: listAvailable, ...rest };
};
