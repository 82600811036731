import { LoadingPlugin, useLoading } from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(LoadingPlugin);
  return {
    provide: {
      loading: useLoading(),
      configLoading: { color: "#bb2485", "background-color": "#333333" },
    },
  };
});
