<template>
  <Dropdown
    :disabled="canHover"
    popper-class="play-challenge-tooltip"
    :triggers="isMobile() ? ['click'] : ['hover']"
    :placement="'top'"
    :distance="12"
    :autoHide="!!isMobile()"
    :delay="100"
    :popperTriggers="isMobile() ? ['click'] : ['hover']"
    :aria-id="`propper-${challenge.id}`"
    class="group relative"
  >
    <Heart
      v-if="token"
      @click="handleLike($event)"
      class="absolute right-5 top-[calc(20%-8px)] z-[2] cursor-pointer transition-all duration-300"
      :class="{
        heart__active: liked,
        'hover:-translate-y-2 group-hover:-translate-y-2': canHover,
      }"
    />
    <NuxtLink
      :to="canHover ? ROUTES_NAME.PLAY_GAME(challenge.game_code) : '#'"
      class="block pb-4 pt-[calc(20%-12px)] transition-all duration-300"
      :class="{
        '!cursor-default grayscale': !canHover,
        'hover:-translate-y-2 group-hover:-translate-y-2': canHover,
      }"
    >
      <div class="wrapper relative">
        <div class="size-full">
          <div :class="[error ? 'aspect-[5/4]' : 'aspect-square']">
            <NuxtImg
              :src="challenge.image"
              class="-mt-[20%] size-full object-cover"
              alt="challenge-thumb"
              @error="handleError"
              v-if="!error"
            />
            <NuxtImg
              v-else-if="!errorGameImg"
              @error="handleErrorGameImg"
              :src="challenge.game.url_thumb"
              alt="game-thumb"
              loading="lazy"
              quality="50"
              class="size-full bg-main-125 object-cover"
            />
            <NuxtImg
              v-else
              src="/images/no_image.svg"
              alt="challenge-thumb"
              class="size-full object-cover"
            />
          </div>
          <div class="description px-1.5 pb-4 pt-2 text-center md:pb-[22px]">
            <p class="truncate text-white" :class="textTitleClass">
              {{
                t("challenges.title", {
                  min_multiplier: challenge.min_multiplier,
                  min_bet: `${getGameCurrencyDefault()?.symbol}${
                    challenge.min_bet
                  }`,
                })
              }}
            </p>
            <p class="truncate text-[#9ba5b4]" :class="textDescriptionClass">
              {{ t("challenges.rewards") }}:
              <span
                v-if="
                  challenge.promotion_amount?.type ===
                  PROMOTION_AMOUNT_TYPE.FREE_SPIN
                "
                class="text-white"
              >
                {{
                  t("tournaments.free_spins_game", {
                    num: challenge.promotion_amount?.fs_round,
                    game_name: challenge.promotion_amount?.game?.name,
                  })
                }}
              </span>
              <span v-else class="text-white">
                {{
                  `${getGameCurrencyDefault()?.symbol}${
                    challenge.promotion_amount?.fiat_amount || "0.00"
                  }`
                }}
              </span>
            </p>
            <template v-if="showMoreDetail">
              <div class="flex flex-col gap-1" v-if="showPriceAchieved">
                <p
                  class="capitalize text-[#9ba5b4]"
                  :class="textDescriptionClass"
                >
                  {{ t("challenges.prizes_achieved") }}:
                  <span class="text-white">
                    {{ challenge?.promotion_results_count || 0 }}/{{
                      challenge?.promotion_amount?.quantity || 0
                    }}
                  </span>
                </p>
              </div>

              <div class="flex flex-col gap-1" v-else>
                <p
                  class="capitalize text-[#9ba5b4]"
                  :class="textDescriptionClass"
                >
                  {{ t("challenges.challenge_achieved") }}:
                  <span class="text-white">
                    {{ _get(challenge, "challenge_achieved", 0) }}
                  </span>
                </p>
              </div>
            </template>
            <button
              :disabled="!canHover"
              class="absolute inset-x-0 -bottom-3 mx-auto w-fit rounded-md bg-main-pink px-3 py-1.5 text-sm text-white lg:-bottom-4 lg:px-4 lg:text-base"
            >
              {{ t("common.btn.play") }}
            </button>
          </div>
        </div>
      </div>
    </NuxtLink>
    <template #popper>
      <block-game-tooltip :can_play="challenge.game.can_play" />
    </template>
  </Dropdown>
</template>

<script lang="ts" setup>
import {
  _get,
  getGameCurrencyDefault,
  useGameStore,
  useLikeStore,
  useSystemStore,
} from "#imports";
import { useQueryClient } from "@tanstack/vue-query";
import { Dropdown } from "floating-vue";
import "floating-vue/dist/style.css";
import { storeToRefs } from "pinia";
import Heart from "~/components/icon/Heart.vue";
import { PLAY_STATUS, PROMOTION_AMOUNT_TYPE } from "~/config/enum";
import { ROUTES_NAME } from "~/config/router";
import { IGamesChallenges } from "~/types/schema";
import useIsMobile from "~/uses/fetchMobile";

type Props = {
  challenge: IGamesChallenges;
  showMoreDetail?: boolean;
  showPriceAchieved?: boolean;
};
const props = defineProps<Props>();
const { railNav } = storeToRefs(useSystemStore());
const textTitleClass = computed(() => {
  if (props.showMoreDetail) {
    return "text-sm";
  }
  return railNav.value
    ? "text-[10px] md:text-xs lg:text-sm xxl:text-base"
    : "text-[10px] md:text-xs xl:text-base";
});
const textDescriptionClass = computed(() => {
  if (props.showMoreDetail) {
    return "text-[10px] xl:text-[11px]";
  }
  return railNav.value
    ? "text-[9px] md:text-[11px] lg:text-[12px] xxl:text-[13px]"
    : "text-[9px] md:text-[11px] xl:text-[13px]";
});
const { t } = useI18n();
const token = useToken();
const { isMobile } = useIsMobile();
const canHover = computed(() => {
  return props.challenge.game.can_play === PLAY_STATUS.CAN_PLAY || !token.value;
});
const error = ref(!props.challenge.image);

const handleError = () => {
  error.value = true;
};

const errorGameImg = ref(false);

const handleErrorGameImg = () => {
  errorGameImg.value = true;
};

const liked = ref(false);
const gameStore = useGameStore();
const { likeObject } = storeToRefs(useLikeStore());
const { emitLike } = useLikeStore();
const queryClient = useQueryClient();
const gameId = computed(() => String(props.challenge.game.id));

watch([gameId, likeObject], ([gameId, likeObject]) => {
  if (likeObject && String(gameId) === String(likeObject.id)) {
    liked.value = likeObject?.like || false;
  }
});

watch(
  () => props.challenge.game.is_favorite,
  (isLiked) => {
    liked.value = isLiked;
  },
  { immediate: true }
);

const handleLike = async (event: MouseEvent) => {
  const newLike = !liked.value;
  event.stopPropagation();
  await gameStore
    .likeGamePlayGame(props.challenge.game.code, newLike)
    .then(() => {
      void queryClient.invalidateQueries({ queryKey: ["useGetFavorite"] });
      void queryClient.invalidateQueries({ queryKey: ["useGetGames"] });
      emitLike(gameId.value, newLike);
    });
  return;
};
</script>
<style lang="scss" scoped>
$bg: #080808;

.wrapper {
  background:
    radial-gradient(circle at 100% 100%, $bg 0, $bg 5px, transparent 5px) 0% 0%/12px
      12px no-repeat,
    radial-gradient(circle at 0 100%, $bg 0, $bg 5px, transparent 5px) 100% 0%/12px
      12px no-repeat,
    radial-gradient(circle at 100% 0, $bg 0, $bg 5px, transparent 5px) 0% 100%/12px
      12px no-repeat,
    radial-gradient(circle at 0 0, $bg 0, $bg 5px, transparent 5px) 100% 100%/12px
      12px no-repeat,
    linear-gradient($bg, $bg) 50% 50% / calc(100% - 14px) calc(100% - 24px)
      no-repeat,
    linear-gradient($bg, $bg) 50% 50% / calc(100% - 24px) calc(100% - 14px)
      no-repeat,
    linear-gradient(0deg, #343843 26%, #ee3dc1 100%);
  border-radius: 12px;
  padding: 7px;
  box-sizing: border-box;
}

.description span {
  font-size: inherit;
}
</style>
