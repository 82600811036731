<template>
  <Dropdown
    :disabled="canHover"
    popper-class="play-game-tooltip"
    :triggers="isMobile() ? ['click'] : ['hover']"
    :placement="'top'"
    :distance="12"
    :autoHide="!!isMobile()"
    :delay="100"
    :popperTriggers="isMobile() ? ['click'] : ['hover']"
    :aria-id="`propper-${game.id}-${slug}`"
  >
    <div
      class="relative flex aspect-square size-full flex-col overflow-hidden rounded-xl bg-main-150 transition-all duration-300"
      :class="[
        className,
        {
          'hover:-translate-y-2': canHover,
        },
      ]"
    >
      <NuxtLink
        :to="canHover ? ROUTES_NAME.PLAY_GAME(gameRef?.code) : '#'"
        class="size-full flex-1 overflow-hidden"
        :class="[
          linkClass,
          {
            '!cursor-default grayscale': !canHover,
          },
        ]"
      >
        <NuxtImg
          v-if="!isError"
          :key="game?.url_thumb"
          loading="lazy"
          :src="game?.url_thumb || game?.game_image"
          class="size-full object-cover"
          alt="game-title"
          sizes="160px md:176px lg:192px xl:208px"
          height="170"
          width="170"
          quality="50"
          @error="handleError"
          :class="imageClass"
        />
        <NuxtImg
          height="100%"
          width="100%"
          src="/images/no_image.svg"
          v-else
          class="size-full object-cover"
          alt="game-title"
          loading="lazy"
          :class="imageClass"
        />
      </NuxtLink>
      <div
        v-if="
          (gameRef?.provider_information?.name || gameRef?.provider) &&
          !hideProvider
        "
        class="box-border h-8 flex-shrink-0 cursor-pointer truncate px-2 text-center text-[10px] font-medium !leading-8 text-white sm:text-xs md:h-11 md:!leading-[44px] lg:!px-3"
      >
        {{ gameRef?.provider_information?.name || gameRef?.provider }}
      </div>
      <Heart
        v-if="token"
        @click="handleLike($event)"
        class="absolute right-3 top-3 cursor-pointer"
        :class="{ heart__active: liked }"
      />
      <slot />
    </div>
    <template #popper>
      <block-game-tooltip :can_play="gameRef?.can_play" />
    </template>
  </Dropdown>
</template>
<script setup lang="ts">
import { useQueryClient } from "@tanstack/vue-query";
import { Dropdown } from "floating-vue";
import "floating-vue/dist/style.css";
import { storeToRefs } from "pinia";
import BlockGameTooltip from "~/components/BlockGameTooltip.vue";
import Heart from "~/components/icon/Heart.vue";
import { PLAY_STATUS, PROVIDER_TYPE_FILTER } from "~/config/enum";
import { ROUTES_NAME } from "~/config/router";
import { useGameStore } from "~/stores/gameStore";
import { useLikeStore } from "~/stores/likeStore";
import useIsMobile from "~/uses/fetchMobile";

type Props = {
  game: any;
  slug: string;
  refreshProvider: boolean;
  className: string;
  imageClass: string;
  linkClass: string;
  hideProvider: boolean;
};
const props = defineProps<Partial<Props>>();

const { isMobile } = useIsMobile();
const token = useToken();
const gameStore = useGameStore();
const { listGameByCategory } = storeToRefs(gameStore);
const { getListProvidersByType } = gameStore;
const { likeObject } = storeToRefs(useLikeStore());
const { emitLike } = useLikeStore();
const liked = ref(false);

const gameRef = computed(() => {
  if (!props.slug) {
    // If slug is null or undefined, return the passed game directly
    return props.game;
  }
  const itemFind = listGameByCategory.value[props.slug]?.listGame?.games?.find(
    (game: any) => game.id === props?.game?.id
  );
  if (itemFind) return itemFind;
  return props.game;
});
const canHover = computed(
  () => gameRef.value?.can_play === PLAY_STATUS.CAN_PLAY || !token.value
);

const gameId = computed(() => gameRef.value?.id);

watch([gameId, likeObject], ([gameId, likeObject]) => {
  if (likeObject && gameId === likeObject.id) {
    liked.value = likeObject?.like || false;
  }
});

watch(
  () => gameRef.value?.is_favorite,
  (isLiked) => {
    liked.value = isLiked;
  },
  { immediate: true }
);

const queryClient = useQueryClient();

const handleLike = async (event: MouseEvent) => {
  const newLike = !liked.value;
  event.stopPropagation();
  //handle like
  if (!props.slug) {
    await gameStore.likeGamePlayGame(gameRef.value.code, newLike).then(() => {
      void queryClient.invalidateQueries({ queryKey: ["useGetFavorite"] });
      void queryClient.invalidateQueries({ queryKey: ["useGetGames"] });
      emitLike(gameId.value, newLike);
    });
    return;
  }
  await gameStore.likeGame(props.slug, gameRef.value.code, newLike).then(() => {
    void queryClient.invalidateQueries({ queryKey: ["useGetFavorite"] });
    void queryClient.invalidateQueries({ queryKey: ["useGetGames"] });
    emitLike(gameId.value, newLike);
  });
  if (props.refreshProvider) {
    await getListProvidersByType(PROVIDER_TYPE_FILTER.TYPE_FAVORITES);
  }
};
const isError = ref(false);
const handleError = () => {
  isError.value = true;
};
const id = useId();
</script>
<style scoped lang="scss">
:deep(.v-img__img--preload) {
  filter: none;
}
</style>
