import { useInfiniteQuery, useQuery } from "@tanstack/vue-query";
import { AxiosError, AxiosResponse } from "axios";
import { storeToRefs } from "pinia";
import qs from "qs";
import { Reactive, Ref } from "vue";
import { CHALLENGE_TYPE, PLAYGAME_TYPE } from "~/config/enum";
import { playGame, playGameDemo } from "~/service/gameService";
import { useGameStore } from "~/stores/gameStore";
import {
  gameProvider,
  GameType,
  IGame,
  IGameCategories,
  IGameCategoryWithGame,
  IGamesChallenges,
  IImageSlider,
  IMostGameWins,
  resCategoryGameList,
} from "~/types/schema";

export const useGetAllProviders = () => {
  const { $api2 } = useNuxtApp();
  const { listProviders } = storeToRefs(useGameStore());
  const { data, ...rest } = useQuery<gameProvider[]>({
    queryKey: ["useGetAllProviders"],
    queryFn: async () => {
      const response = (await $api2("api/games/providers")) as AxiosResponse<
        gameProvider[]
      >;
      const providers = response.data;
      listProviders.value = providers;
      return providers;
    },
    staleTime: Infinity,
  });
  return { data: listProviders, ...rest };
};

export const useGetGameCategories = () => {
  const { $api2 } = useNuxtApp();
  const { categories } = storeToRefs(useGameStore());
  return useQuery<IGameCategories[]>({
    queryKey: ["useGetGameCategories"],
    queryFn: async () => {
      const response = (await $api2("/api/games/categories")) as AxiosResponse<
        IGameCategories[]
      >;
      const listCategories = response.data;
      categories.value = listCategories;
      return listCategories;
    },
    staleTime: 60000,
  });
};

export const useGetListImageSliders = () => {
  const { $api2 } = useNuxtApp();
  return useQuery<AxiosResponse<IImageSlider[]>, AxiosError, IImageSlider[]>({
    queryKey: ["useGetListImageSliders"],
    queryFn: () => $api2("/api/media/image-sliders"),
    select: (response) => response.data,
    staleTime: 60000,
  });
};

export const useGetGameCategoriesWithGames = (type: GameType) => {
  const { $api2 } = useNuxtApp();
  return useQuery<
    AxiosResponse<IGameCategoryWithGame[]>,
    AxiosError,
    IGameCategoryWithGame[]
  >({
    queryKey: ["useGetGameCategoriesWithGames", type],
    queryFn: () =>
      $api2("/api/games", {
        params: {
          type,
        },
      }),
    select: (response) => response.data,
    staleTime: 15000,
  });
};

export const useGetPlayAgain = () => {
  const { $api2 } = useNuxtApp();
  return useQuery<
    AxiosResponse<{ data: resCategoryGameList[] }>,
    AxiosError,
    resCategoryGameList[]
  >({
    queryKey: ["useGetPlayAgain"],
    queryFn: () =>
      $api2("/api/games/recently-played/list", {
        params: {
          page: 1,
        },
      }),
    select: (response) => _get(response, "data.data", []),
  });
};

export const useGetFavorite = () => {
  const { $api2 } = useNuxtApp();
  return useQuery<
    AxiosResponse<{ data: resCategoryGameList[] }>,
    AxiosError,
    resCategoryGameList[]
  >({
    queryKey: ["useGetFavorite"],
    queryFn: () =>
      $api2("/api/games/user/favorites", {
        params: {
          page: 1,
        },
      }),
    select: (response) => _get(response, "data.data", []),
  });
};

export const useGetBiggestWin = () => {
  const { $api2 } = useNuxtApp();
  const token = useToken();
  return useQuery<
    AxiosResponse<resCategoryGameList[]>,
    AxiosError,
    resCategoryGameList[]
  >({
    queryKey: ["useGetBiggestWin", token],
    queryFn: () => {
      if (!token.value) return Promise.resolve({ data: [] } as any);
      return $api2("api/games/highest-win-amount/list");
    },
    select: (response) => response?.data,
  });
};

export const useGetListChallengesGame = (query: Ref<CHALLENGE_TYPE>) => {
  const { $api2 } = useNuxtApp();
  const token = useToken();
  return useInfiniteQuery({
    queryKey: ["useGetListChallengesGame", query, token],
    queryFn: async ({ pageParam = 1 }) => {
      const { data } = await $api2(
        query.value !== CHALLENGE_TYPE.USER_COMPLETED
          ? `api/challenges/${query.value}`
          : `api/challenges/user/${query.value}`,
        {
          params: {
            page: pageParam,
          },
        }
      );
      return data as {
        data: IGamesChallenges[];
        total: number;
        current_page: number;
      };
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, _, lastPageParam) => {
      if (lastPageParam * 20 >= lastPage.total) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    staleTime: 4000,
  });
};

export const useGetCategoriesByProvider = (query: Reactive<any>) => {
  const finalQuery = computed(() => ({
    keyword: query.keyword,
    provider: query.provider,
  }));
  const { $api2 } = useNuxtApp();
  return useQuery<
    any,
    Error,
    Array<{ id: number; games_count: number; name: string }>
  >({
    queryKey: ["useGetCategoriesByProvider", finalQuery],
    queryFn: () =>
      $api2("api/games/categories/list", { params: finalQuery.value }),
    select: (res) => res.data,
  });
};

export const useGetGamesByProvider = (params: Reactive<any>) => {
  const { $api2 } = useNuxtApp();
  return useQuery<any, Error, IGame[]>({
    queryKey: ["useGetGamesByProvider", params],
    queryFn: () =>
      $api2(
        `api/games/provider/games?${qs.stringify(params, { arrayFormat: "brackets" })}`
      ),
    select: (res) => res.data,
  });
};

export const useGetGameDetail = (code = ref("")) => {
  const { $api2 } = useNuxtApp();
  return useQuery<any, Error, IMostGameWins>({
    queryKey: ["useGetGameDetail", code],
    queryFn: () => $api2(`api/games/${code.value}`),
    select: (res) => res.data,
    enabled: toRef(() => !!code.value),
    staleTime: 15000,
  });
};

export const useGetGameUrl = (
  type: Ref<PLAYGAME_TYPE | undefined>,
  gameSlug = ref(""),
  selectedCurrency = ref(""),
  version: Ref<number>,
  enabled = ref(true)
) => {
  const token = useToken();
  const checkEnabled = computed(() => {
    if (type.value === PLAYGAME_TYPE.REAL && !token.value) return false;
    return !!gameSlug.value && !!type.value && enabled.value;
  });
  return useQuery<any, any, string>({
    queryKey: [
      "useGetGameUrl",
      type,
      gameSlug,
      selectedCurrency,
      version,
      token,
    ],
    queryFn: () => {
      return type.value === PLAYGAME_TYPE.REAL
        ? playGame(gameSlug.value, selectedCurrency.value)
        : playGameDemo(gameSlug.value);
    },
    select: (res) => {
      return res.data?.game_url;
    },
    enabled: checkEnabled,
    staleTime: 22000,
  });
};
