import { defineStore } from "pinia";
import { API_LOADING_TIMEOUT } from "~/config/constant";
import { BONUS_TAB_TYPE } from "~/config/enum";
import { getBonusByType, readBonus } from "~/service/bonusService";
import { bonusData } from "~/types/schema";

export const useBonusStore = defineStore("useBonusStore", {
  state: () => ({
    listAvailable: [] as bonusData[],
    listActive: [] as bonusData[],
    listHistory: [] as bonusData[],
    totalHistory: 0,
    loading: true,
    isFirstLoading: true,
    bonusTypeParams: null as number | null,
    allowFetchAvailableBonus: true, // handle fetch api when socket event type === ALERT_TYPE.UPDATE_AVAILABLE_BONUS
  }),
  actions: {
    async getBonusListDataByType(type: BONUS_TAB_TYPE, page?: number) {
      try {
        if (this.isFirstLoading) {
          this.loading = true;
        }
        const res = await getBonusByType(type, page);
        if (res.data.success) {
          if (type === BONUS_TAB_TYPE.AVAILABLE) {
            this.listAvailable = res?.data?.data;
          }
          if (type === BONUS_TAB_TYPE.ACTIVE) {
            this.listActive = res?.data?.data;
          }
          if (type === BONUS_TAB_TYPE.HISTORY) {
            this.listHistory = res?.data?.data?.data;
            this.totalHistory = res?.data?.data?.total || 0;
          }
        }
        return res?.data?.data;
      } catch (e: any) {
      } finally {
        const listTypeCheck = [BONUS_TAB_TYPE.ACTIVE, BONUS_TAB_TYPE.HISTORY];
        // if type === "AVAILABLE", keep loading state = true
        if (this.isFirstLoading && listTypeCheck.includes(type)) {
          // setTimeout(() => {
          this.loading = false;
          // }, API_LOADING_TIMEOUT);
          if (type === BONUS_TAB_TYPE.HISTORY) {
            this.isFirstLoading = false;
          }
        }
      }
    },

    setLoading(state: boolean) {
      this.loading = state;
    },

    resetFirstLoading() {
      this.isFirstLoading = true;
    },

    setAllowFetch(state: boolean) {
      this.allowFetchAvailableBonus = state;
    },

    async handleReadBonus() {
      try {
        this.loading = true;
        const response = await readBonus();
        if (response.data.success) {
          await this.getBonusListDataByType(BONUS_TAB_TYPE.AVAILABLE);
        }
      } finally {
        this.loading = false;
      }
    },
  },
});
