<template>
  <div
    class="smooth-scrollbar custom-scrollbar flex w-fit max-w-full items-center gap-4 overflow-auto rounded-lg bg-main-150 transition-all"
    ref="smooth-scrollbar"
  >
    <div class="flex h-[54px] items-center gap-4 px-2">
      <slot />
    </div>
  </div>
</template>
<script setup lang="ts">
import Scrollbar from "smooth-scrollbar";

const scrollbar = useTemplateRef<HTMLDivElement>("smooth-scrollbar");

watch(scrollbar, () => {
  if (isClient() && scrollbar.value) {
    Scrollbar.init(scrollbar.value, {
      alwaysShowTracks: true,
      damping: 0.9,
    });
  }
});
</script>
