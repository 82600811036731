<template>
  <v-card
    class="mx-[10px] w-[calc(100vw-68px)] max-w-[560px] !rounded-[16px] !bg-main-dark !px-[16px] !pb-[24px] !pt-4 lg:!px-8 lg:!pb-10"
  >
    <div class="relative left-0 flex justify-end lg:left-4">
      <button
        @click="emit('close')"
        type="button"
        class="rounded-full hover:bg-main-125"
      >
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.0884 15.0877L24.9123 24.9116"
            stroke="white"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M15.0877 24.9116L24.9116 15.0877"
            stroke="white"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>

    <div class="relative mb-8 flex justify-center">
      <div
        class="text-center text-[18px] font-bold leading-[132%] text-white lg:text-[24px]"
      >
        {{ $t("bonus.confirm_claim.select_wallet") }}
      </div>
    </div>
    <v-form class="overflow-hidden">
      <div class="text-sm text-white lg:text-base">
        {{ $t("bonus.confirm_claim.please_select_message") }}
      </div>
      <div class="line-x my-6 h-[2px]"></div>
      <div class="custom-scrollbar h-[140px] overflow-y-auto pr-3">
        <div class="grid grid-cols-2 gap-4 lg:grid-cols-3">
          <div
            v-for="(coin, index) in bonus?.valid_coins"
            :key="coin"
            class="flex items-center"
          >
            <input
              v-model="selectedCoin"
              type="radio"
              class="hidden"
              :id="`wallet-bonus-${index + 1}`"
              :value="coin"
            />
            <label
              class="flex items-center gap-[14px]"
              :for="`wallet-bonus-${index + 1}`"
            >
              <div
                class="flex h-6 w-6 cursor-pointer items-center justify-center !rounded-md border !border-main-125 bg-main-150"
              >
                <svg
                  v-if="coin === selectedCoin"
                  width="18"
                  height="14"
                  viewBox="0 0 18 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.6667 2L6.58333 12.0833L2 7.5"
                    stroke="#FD3FCC"
                    stroke-width="2.4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="flex cursor-pointer items-center">
                <img
                  class="mr-2 block h-4 w-4 flex-shrink-0 rounded-full"
                  :src="getWalletImageByCode(coin)"
                  :alt="coin"
                />
                <div
                  class="text-base font-medium uppercase leading-[150%] text-white"
                >
                  {{ coin }}
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
      <div class="mt-8 text-center">
        <button
          type="button"
          @click="
            () => {
              emit('close');
              emit('claim', selectedCoin);
            }
          "
          class="mx-auto h-[56px] rounded-lg !bg-linear-primary px-8 font-extrabold text-white"
        >
          {{ $t("common.btn.confirm") }}
        </button>
      </div>
    </v-form>
  </v-card>
</template>

<script setup lang="ts">
const emit = defineEmits(["close", "claim"]);
const props = defineProps(["bonus"]);
const selectedCoin = ref(props.bonus.valid_coins[0]);
</script>

<style scoped lang="scss">
:deep(.v-selection-control-group) {
  display: inline-block;
  column-count: 2;
  @media (min-width: 992px) {
    column-count: 3;
  }
}

:deep(.mdi-radiobox-blank) {
  border: 2px solid #6626c1;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font-size: unset;
  &:before {
    content: "";
  }
}

:deep(.mdi-radiobox-marked) {
  border: 2px solid #6626c1;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font-size: unset;
  &:before {
    content: "";
    width: 10px;
    height: 10px;
    background: #c184ff;
    border-radius: 50%;
  }
}

:deep(.v-selection-control) {
  .v-label {
    opacity: 1;
  }
}
.btn-gradient {
  background: linear-gradient(
    270.46deg,
    #ff3eca 0.23%,
    #9b37ff 40%,
    #8378ff 55.55%,
    #53ccff 98.94%
  );
  font-size: 15px;
  font-weight: 700;
  line-height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-left: 12px;
    font-size: 21px;
  }
}

:deep(.line-x) {
  background: linear-gradient(
    90deg,
    rgba(192, 173, 216, 0) 0%,
    rgba(189, 173, 216, 0.4) 49.48%,
    rgba(192, 173, 216, 0) 100%
  );
}
</style>
