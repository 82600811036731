import { defineStore } from "pinia";

export const useSystemStore = defineStore("useSystemStore", {
  state: () => {
    return {
      showNav: true,
      railNav: false,

      //state for dialog
      verifyUserInfoDialog: false,
      logoutDialog: false,
      sendMailVerifyDialog: false,
      sendOtpVerifyDialog: false,
      updateUserInfoDialog: false,
      walletSettingsDialog: false,
      unVerifyEmailDialog: false,
      newPromotionResultDialog: false,
      bonusDialog: false,
      listWalletDialog: false,
      walletTransactionState: false,
      preload: false,
      isFirstView: true,
      //end state for dialog
    };
  },

  getters: {
    getListWalletDialog: (state) => state.listWalletDialog,
  },
  actions: {
    changeShowNav(status: boolean) {
      this.showNav = status;
    },
    changeRailNav(status: boolean) {
      this.railNav = status;
    },
    changeVerifyUserInfoDialog(status: boolean) {
      this.verifyUserInfoDialog = status;
    },
    changeLogoutDialog(status: boolean) {
      this.logoutDialog = status;
    },
    changeSendMailVerifyDialog(status: boolean) {
      this.sendMailVerifyDialog = status;
    },
    changeSendOtpVerifyDialog(status: boolean) {
      this.sendOtpVerifyDialog = status;
    },
    changeUpdateUserInfoDialog(status: boolean) {
      this.updateUserInfoDialog = status;
    },
    changeWalletSettingsDialog(status: boolean) {
      this.walletSettingsDialog = status;
    },
    changeUnVerifyEmailDialog(status: boolean) {
      this.unVerifyEmailDialog = status;
    },
    changeNewPromotionResultDialog(status: boolean) {
      this.newPromotionResultDialog = status;
    },
    changeBonusDialog(status: boolean) {
      this.bonusDialog = status;
    },
    changeListWalletDialog(status: boolean) {
      this.listWalletDialog = status;
    },
    changeWalletTransactionDialog(status: boolean) {
      this.walletTransactionState = status;
    },
    setPreload(state: boolean) {
      this.preload = state;
    },
    setFirstView(state: boolean) {
      this.isFirstView = state;
    },
  },
});
