<template>
  <v-card
    max-height="700"
    class="mx-[10px] flex w-[calc(100vw-68px)] max-w-[600px] flex-col !rounded-[16px] !bg-main-dark !px-[16px] !pb-[24px] !pt-4 lg:!px-8 lg:!pb-10"
  >
    <div class="relative left-0 mb-2 flex justify-end lg:left-4">
      <button
        @click="close"
        type="button"
        class="rounded-full hover:bg-main-125"
      >
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.0884 15.0877L24.9123 24.9116"
            stroke="white"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M15.0877 24.9116L24.9116 15.0877"
            stroke="white"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>

    <div class="relative mb-8 flex justify-center">
      <div class="bonus-img">
        <img
          class="w-full object-contain"
          src="/images/icon/icon-open-bonus-new.png"
          alt="bonus"
        />
      </div>
    </div>

    <div class="flex-1 space-y-10">
      <div class="flex justify-center">
        <NavbarSwiper>
          <div
            v-for="(item, index) in listBonusType"
            :key="index"
            class="flex h-[54px] items-center justify-center"
          >
            <button
              type="button"
              @click="chooseAction(item.value)"
              class="tab-item flex h-[42px] w-full items-center justify-center gap-2 rounded-lg px-4 text-neutral-50-new transition-all"
              draggable="false"
              :class="
                item.value === selectedType
                  ? [activeClass, 'bg-main-pink text-white']
                  : ''
              "
            >
              <span
                class="truncate break-words text-center text-[12px] font-bold capitalize"
              >
                {{ $t(item.title) }}
              </span>
            </button>
          </div>
        </NavbarSwiper>
      </div>

      <div
        class="flex h-[44vh] flex-grow items-center justify-center"
        v-if="loading"
      >
        <span class="loader"></span>
      </div>
      <template v-else>
        <AvailableTab
          v-if="selectedType === BONUS_TAB_TYPE.AVAILABLE"
          @update-bonus-tab="selectedType = BONUS_TAB_TYPE.ACTIVE"
        />
        <ActiveTab v-else-if="selectedType === BONUS_TAB_TYPE.ACTIVE" />
        <HistoryTab v-else-if="selectedType === BONUS_TAB_TYPE.HISTORY" />
      </template>
    </div>
  </v-card>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { BONUS_TAB_TYPE } from "~/config/enum";
import { useBonusStore } from "~/stores/bonusStore";
import { useSystemStore } from "~/stores/systemStore";
import ActiveTab from "./ActiveTab.vue";
import AvailableTab from "./AvailableTab.vue";
import HistoryTab from "./HistoryTab.vue";

const systemStore = useSystemStore();
const bonusStore = useBonusStore();
const { changeBonusDialog } = systemStore;
const { getBonusListDataByType, resetFirstLoading, setAllowFetch } = bonusStore;
const { loading } = storeToRefs(bonusStore);

const activeClass = "bonus-active";

const listBonusType = [
  {
    value: BONUS_TAB_TYPE.AVAILABLE,
    title: "bonus.tabs.available",
  },
  {
    value: BONUS_TAB_TYPE.ACTIVE,
    title: "bonus.tabs.active",
  },
  {
    value: BONUS_TAB_TYPE.HISTORY,
    title: "bonus.tabs.history",
  },
];

const selectedType = ref<BONUS_TAB_TYPE>(BONUS_TAB_TYPE.AVAILABLE);

const fetchBonus = async (selectedType: BONUS_TAB_TYPE) => {
  resetFirstLoading();
  if (selectedType === BONUS_TAB_TYPE.HISTORY) {
    getBonusListDataByType(selectedType, 1);
  } else {
    if (selectedType === BONUS_TAB_TYPE.AVAILABLE) {
      // const listData = await getBonusListDataByType(selectedType);
      // if (!isNewBonusAvailable(listData)) {
      bonusStore.loading = false;
      // }
    } else {
      getBonusListDataByType(selectedType);
    }
  }
};

watch(
  selectedType,
  async (current: BONUS_TAB_TYPE) => {
    setAllowFetch(false);
    await fetchBonus(current);
    setTimeout(() => {
      setAllowFetch(current === BONUS_TAB_TYPE.AVAILABLE);
    }, 1000);
  },
  {
    immediate: true,
  }
);

const chooseAction = async (value: BONUS_TAB_TYPE) => {
  selectedType.value = value;
};

const close = () => {
  changeBonusDialog(false);
};
</script>

<style scoped lang="scss">
:deep(.v-list-item.v-list-item--active) {
  background: linear-gradient(180deg, #bb52f1 0%, #ff7bfa 100%);
  border-radius: 100px;
  color: #ffffff !important;
}

.v-card {
  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }
}

:deep(.grid-template-rows-0) {
  grid-template-rows: 0fr;
}

:deep(.grid-template-rows-1) {
  grid-template-rows: 1fr;
}

:deep(.transition-collapse) {
  transition: grid-template-rows 0.3s ease-out;
}
</style>
<style>
.term-and-condition a {
  text-decoration: underline;
}
</style>
