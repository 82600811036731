<template>
  <div class="box-border overflow-hidden rounded-lg border border-main-150 p-4">
    <div
      @click="emit('updateListOpenDetails', data.id)"
      type="button"
      class="flex w-full cursor-pointer items-center justify-between gap-1.5 text-white"
    >
      <p class="text-sm font-bold">
        {{ $t("common.details") }}
      </p>
      <v-icon
        class="flex-shrink-0"
        :class="{
          'rotate-180': !openDetails.includes(data.id),
        }"
      >
        mdi-chevron-down
      </v-icon>
    </div>
    <div class="my-4 border-b border-main-125" />
    <div
      class="overflow-hidden transition-all duration-300"
      :class="openDetails.includes(data.id) ? 'max-h-vh' : 'max-h-0'"
    >
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { bonusData } from "~/types/schema";

interface CollapseItemProps {
  data: bonusData;
  openDetails: Array<number>;
}

defineProps<CollapseItemProps>();

const emit = defineEmits(["updateListOpenDetails"]);
</script>

<style scoped></style>
