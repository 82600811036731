import { defineStore } from "pinia";
import {
  getListCategories,
  getListPromotions,
  getDetailPromotion,
  getLastPromotionResult,
  getPromotionResults,
} from "~/service/promotionService";
import { useGameStore } from "~/stores/gameStore";
import {
  promotionCategories,
  promotionList,
  promotionParams,
  promotionDetail,
  promotionResult,
  payloadPromotionResults,
} from "~/types/schema";

export const usePromotionStore = defineStore("usePromotionStore", {
  state: () => ({
    listCategories: [] as promotionCategories[],
    listPromotions: [] as promotionList[],
    promotionDetail: {} as promotionDetail,
    promotionResult: {} as promotionResult,
    promotionResults: [] as promotionResult[],
    loading: true,
    totalPromotionResult: 0,
  }),
  getters: {
    getCategoriesList: (state) => state.listCategories,
    getPromotionList: (state) => state.listPromotions,
    getPromotionDetail: (state) => state.promotionDetail,
  },
  actions: {
    async getAllCategories() {
      try {
        const res = await getListCategories();
        if (res.data.success) {
          this.listCategories = res?.data?.data;
          this.listCategories.unshift({
            slug: "",
            name: "common.all",
          });
        }
      } catch (e: any) {}
    },

    async getAllPromotionsOrCategory(payload: promotionParams) {
      try {
        const res = await getListPromotions(payload);
        if (res.data.success) {
          this.listPromotions = res?.data?.data;
          return res.data.data;
        }

        return [];
      } catch (e: any) {}
    },

    async getDetailPromotion(slug: string) {
      try {
        const res = await getDetailPromotion(slug);
        if (res.data.success) {
          this.promotionDetail = res?.data?.data;
          const gameStore = useGameStore();
          gameStore.setGamesByCategory(
            "promotion-game-store-slug",
            this.promotionDetail.active_games
          );
        }
      } catch (e: any) {}
    },

    async getLastPromotionResult() {
      try {
        const res = await getLastPromotionResult();
        if (res.data.success) {
          this.promotionResult = res?.data?.data;
        }
      } catch (e: any) {}
    },

    async getPromotionResults(payload: payloadPromotionResults) {
      try {
        this.loading = true;
        const res = await getPromotionResults(payload);
        if (res.data.success) {
          this.promotionResults = res?.data?.data.data;
          this.totalPromotionResult = res?.data?.data?.total || 0;
        }
      } catch (e: any) {
      } finally {
        this.loading = false;
      }
    },
  },
});
