<template>
  <v-card
    class="h-full w-full !rounded-[16px] !bg-main-dark !px-[16px] !pb-[24px] !pt-4 lg:!px-8 lg:!pb-10"
  >
    <div class="relative left-0 flex justify-end lg:left-4">
      <button
        @click="close"
        type="button"
        class="rounded-full hover:bg-main-125"
      >
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.0884 15.0877L24.9123 24.9116"
            stroke="white"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M15.0877 24.9116L24.9116 15.0877"
            stroke="white"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>

    <div class="relative mb-8 flex justify-center">
      <div
        class="text-center text-[18px] font-extrabold leading-[132%] text-white sm:text-[24px]"
      >
        {{ $t("common.wallet") }}
      </div>
    </div>

    <div class="flex flex-grow flex-col overflow-hidden">
      <div
        class="custom-scrollbar mb-6 flex w-fit max-w-full flex-shrink-0 gap-4 overflow-x-auto overflow-y-hidden !rounded-lg bg-main-150 p-1.5"
      >
        <div
          class="flex h-[42px] items-center justify-center"
          v-for="(action, index) in listTransactionAction"
          :key="index"
        >
          <button
            type="button"
            @click="chooseAction(action.value)"
            class="tab-item flex h-full w-full items-center justify-center gap-2 rounded-lg px-4 text-neutral-50-new"
            draggable="false"
            :class="{
              active: action.value === selectedAction,
            }"
          >
            <span
              class="truncate break-words text-center text-[12px] font-bold capitalize"
            >
              {{ $t(action.title) }}
            </span>
          </button>
        </div>
      </div>

      <Scrollbar class="h-full flex-1 overflow-auto pr-3">
        <wallet-deposit-tab
          v-if="
            selectedAction === WALLET_TRANSACTION_ACTION.DEPOSIT &&
            !getWalletAddressLoading
          "
        />
        <div
          class="flex h-[450px] flex-grow items-center justify-center"
          v-if="
            selectedAction === WALLET_TRANSACTION_ACTION.DEPOSIT &&
            getWalletAddressLoading
          "
        >
          <span class="loader"></span>
        </div>
        <wallet-withdraw-tab
          v-if="selectedAction === WALLET_TRANSACTION_ACTION.WITHDRAW"
        />
        <WalletSettings
          v-if="selectedAction === WALLET_TRANSACTION_ACTION.SETTING"
          hide-header
        />
      </Scrollbar>
    </div>
  </v-card>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { WALLET_TRANSACTION_ACTION } from "~/config/enum";
import { useSystemStore } from "~/stores/systemStore";
import { useWalletStore } from "~/stores/walletStore";
import WalletDepositTab from "./WalletDepositTab.vue";
import WalletWithdrawTab from "./WalletWithdrawTab.vue";

const systemStore = useSystemStore();
const walletStore = useWalletStore();
const { changeWalletTransactionDialog } = systemStore;

const { walletTransactionParams: selectedAction, getWalletAddressLoading } =
  storeToRefs(walletStore);

const listTransactionAction = [
  {
    value: WALLET_TRANSACTION_ACTION.DEPOSIT,
    title: "wallet_transaction.tabs.deposit",
  },
  {
    value: WALLET_TRANSACTION_ACTION.WITHDRAW,
    title: "wallet_transaction.tabs.withdraw",
  },
  {
    value: WALLET_TRANSACTION_ACTION.SETTING,
    title: "common.wallet_settings",
  },
];

const chooseAction = (value: any) => {
  selectedAction.value = value;
};

const close = () => {
  changeWalletTransactionDialog(false);
};
</script>

<style scoped lang="scss">
:deep(.v-list-item.v-list-item--active) {
  background: linear-gradient(180deg, #bb52f1 0%, #ff7bfa 100%);
  border-radius: 100px;
  color: #ffffff !important;
}

.v-card {
  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }
}

:deep(.v-input) {
  &.custom-rounded .v-field {
    border-radius: 100px;
  }

  .v-field {
    border-radius: 12px;
    overflow: hidden;

    &__input {
      min-height: 42px !important;
      padding-block: 0px !important;
      font-size: 14px;
      font-weight: 500;
      mask-image: none !important;
      -webkit-mask-image: none !important;
      position: relative;
    }
  }
}

:deep(.v-field__append-inner) .v-select__menu-icon {
  display: none;
}

:deep(.wallet-address) {
  background: linear-gradient(180deg, #bb52f1 0%, #ff7bfa 100%);
}

:deep(.active) {
  background: rgb(253, 63, 204) !important;
  color: white !important;
}
</style>
