<template>
  <div
    class="mb-6 border-b border-main-150 pb-6 lg:!mb-10"
    v-if="listData?.length"
  >
    <div class="flex flex-wrap justify-center gap-x-4 gap-y-4 sm:gap-x-8">
      <NuxtLink
        :to="item.link || '#'"
        v-for="item in listData"
        :key="item.link"
        target="_blank"
        aria-label="social-media"
      >
        <NuxtImg
          quality="50"
          :src="item.img"
          class="!flex-grow-0"
          height="40"
          width="40"
          :alt="item.img"
        />
      </NuxtLink>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useGetSocialService } from "~/queries/socialService";

const { data } = useGetSocialService();

const listData = computed(() =>
  [
    {
      img: "/images/icon/social/x.webp",
      link: data.value?.social_media_x,
    },
    {
      img: "/images/icon/social/telegram.webp",
      link: data.value?.social_media_telegram,
    },
    {
      img: "/images/icon/social/facebook.svg",
      link: data.value?.social_media_facebook,
    },
    {
      img: "/images/icon/social/tiktok.svg",
      link: data.value?.social_media_tiktok,
    },
    {
      img: "/images/icon/social/instagram.svg",
      link: data.value?.social_media_instagram,
    },
    {
      img: "/images/icon/social/reddit.svg",
      link: data.value?.social_media_reddit,
    },
  ].filter((item) => item.link)
);
</script>
