<template>
  <div
    class="group-tooltip group-custom !pointer-events-auto !cursor-default"
    :class="{
      disabled: item.disabled,
      challenge: item.url === ROUTES_NAME.CHALLENGES,
    }"
  >
    <NuxtLink
      @click="
        () => {
          if (typeof item.callback === 'function') item.callback();
        }
      "
      :to="item.disabled ? '#' : item.url"
      class="flex items-center justify-center gap-3 !rounded-xl p-3 max-xl:w-full"
      :class="[
        railNav ? 'w-full' : 'h-[45px]',
        [NavbarEnum.CHALLENGES, NavbarEnum.TOURNAMENTS].includes(item.id) && !railNav && item.hasOwnProperty('count') ? 'has-count relative' : '',
        {
          'pointer-events-none': item.disabled,
          'icon-main-pink-active [&_.menu-title]:text-main-pink':
            checkActiveRoute(route.path, item.url),
        },
      ]"
      :aria-label="item.name"
    >
      <LazyNavbarSvg v-if="!item.image" :item="item" />
      <div class="h-5 w-5" v-else>
        <img
          loading="lazy"
          class="icon-main-pink-hover h-full w-full object-contain"
          :src="item.image"
          alt=""
          sizes="20px"
          height="20"
          width="20"
        />
      </div>

      <div
        class="truncate max-[1279.98px]:flex max-[1279.98px]:flex-1 max-[1279.98px]:items-center max-[1279.98px]:justify-between max-[1279.98px]:pl-2"
        :class="
          !railNav ? 'hidden' : 'flex flex-1 items-center justify-between'
        "
      >
        <div
          class="menu-title flex-1 truncate text-sm font-semibold leading-[150%] flex justify-between items-center"
          :class="[
            checkActiveRoute(route.path, item.url)
              ? 'text-main-pink'
              : 'text-white',
            {
              '!text-disable-100': item.disabled,
            },
          ]"
        >
          {{ $t(item.name) }}
          <span class="bg-main-pink text-xs font-medium px-1.5 py-0.5 rounded !text-white" v-if="item.hasOwnProperty('count')">
            {{ item?.count }}
          </span>
        </div>
      </div>
    </NuxtLink>

    <!-- tooltip -->
    <v-tooltip
      activator="parent"
      class="custom-tooltip"
      :class="{
        '!hidden': railNav,
      }"
    >
      <NuxtLink
        @click="
          () => {
            if (typeof item.callback === 'function') item.callback();
          }
        "
        :to="item.disabled ? '' : item.url"
        class="hidden h-[45px] w-[160px] items-center justify-center !rounded-xl bg-white p-3 shadow-lg duration-200 xl:flex"
      >
        <div class="flex flex-1 items-center justify-between pl-2">
          <div
            class="menu-title flex-1 text-sm font-semibold leading-[150%] text-main-dark"
          >
            {{ $t(item.name) }}
          </div>
        </div>
      </NuxtLink>
    </v-tooltip>
  </div>
</template>
<script setup lang="ts">
import { ROUTES_NAME } from "~/config/router";
import { INavBarItem, NavbarEnum } from "~/types/schema";

type Props = {
  item: INavBarItem;
  railNav: boolean;
}

const settingStore = useSettingStore();
const { getChallengeCount, getTournamentCount } = storeToRefs(settingStore);

const props = defineProps<Props>();
const railNav = computed(() => props.railNav);
const item = ref<INavBarItem>(props.item);

watch([getChallengeCount, getTournamentCount], ([newChallengeCount, newTournamentCount]) => {
  if (item.value.id == NavbarEnum.CHALLENGES && newChallengeCount) item.value.count = newChallengeCount;
  else if (item.value.id == NavbarEnum.TOURNAMENTS && newTournamentCount) item.value.count = newTournamentCount;
}, { immediate: true });

const route = useRoute();
const checkActiveRoute = (currentRoute: string, targetRoute: string) => {
  if (targetRoute === ROUTES_NAME.LOBBY) {
    return currentRoute === ROUTES_NAME.LOBBY;
  }
  return currentRoute.includes(targetRoute);
};
</script>

<style scoped>
  .has-count::before {
      content: "";
      right: 0px;
      top: 20px;
      position: absolute;
      width: 5px;
      height: 5px;
      background: rgb(253, 63, 204);
      border-radius: 50%;
  }
</style>