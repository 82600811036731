export const ROUTES_NAME = {
  COMPLETE_INFORMATION: "/complete-information",
  CUSTOMER_SUPPORT: "/customer-support",
  EMAIL_VERIFY: "/email/verify",
  FORGOT_PASSWORD: "/forgot-password",
  FORGOT_PASSWORD_SUCCESS: "/forgot-password/send-success",
  GAMING_HISTORY: "/gaming-history",
  PRIZES: "/prizes",
  LOBBY: "/",
  LIVE_CASINO: "/live-casino",
  LOGIN: "/login",
  UPDATE_MAIL: "/login/update-email",
  NOT_AVAILABLE: "/not-available",
  PAGE_DETAIL: (id?: any) => (id ? `/page-detail/${id}` : "/page-detail/:id"),
  PASSWORD_RESET: (token?: any) =>
    token ? `/password/reset/${token}` : "/password/reset/:token",
  PLAY_GAME: (id?: any) => (id ? `/play-game/${id}` : "/play-game/:id"),
  CHANGE_PASSWORD: "/profile/change-password",
  PROFILE: "/profile/my-profile",
  INBOXES: "/inboxes",
  PROMOTION_LIST: "/promotion",
  PROMOTION_DETAIL: (id?: any) => (id ? `/promotion/${id}` : "/promotion/:id"),
  SIGN_UP: "/sign-up",
  TRANSACTION_HISTORY: "/transaction-history",
  VIP_PROGRAM: "/vip-program",
  BLOG_TAGS: "/blogs/tags",
  BLOGS: "/blogs",
  BLOG_DETAIL: (id?: any) => (id ? `/blogs/${id}` : "/blogs/:id"),
  SIGN_UP_WELCOME: "/sign-up/welcome",
  PROVIDER_LIST: "/provider",
  GAME_CATEGORIES_DETAIL: (id?: any) =>
    id ? `/categories/${id}` : "/categories/:id",
  FAVORITE: "/favorite",
  PLAY_AGAIN: "/play-again",
  CHALLENGES: "/challenges",
  TOURNAMENT_LIST: "/tournaments",
  TOURNAMENT_DETAIL: (slug: any) => `/tournaments/${slug}`,
};
