<template>
  <IntersectionLayout class="px-4 py-4 lg:px-10 lg:py-6" v-model="isIntersect">
    <div
      class="mb-6 flex flex-col items-center justify-between gap-2 sm:flex-row"
    >
      <LazyListTab
        :list="listTab.filter((item) => !item.hide)"
        v-model="selectedTab"
        class="w-full sm:w-auto"
        buttonClass="!w-full sm:!w-fit"
      />
      <div class="relative w-full sm:w-auto">
        <CommonDropdown
          disable-search
          :listData="listNumberToShow"
          v-model="showDropdown"
          :wrap-class="'w-full sm:w-[136px] h-[42px] lg:h-[54px]'"
          :content-class="'w-full max-w-full lg:w-full left-0'"
        >
          <template #selected>
            <span class="text-sm text-white">{{ numberToShow }}</span>
          </template>
          <template #list-dropdown-item>
            <div
              v-for="(item, index) in listNumberToShow"
              :key="index"
              class="group cursor-pointer px-3 py-[11px] hover:bg-main-125"
              :class="{
                'bg-main-125': numberToShow === item,
                'border-b !border-main-125':
                  index !== listNumberToShow.length - 1,
              }"
              @click="
                () => {
                  numberToShow = item;
                  showDropdown = false;
                }
              "
            >
              <div class="flex items-center justify-between">
                <p
                  class="truncate text-sm text-white group-hover:!text-main-pink"
                  :class="{
                    '!text-main-pink': numberToShow === item,
                  }"
                >
                  {{ item }}
                </p>
                <div class="h-6 w-6 flex-shrink-0">
                  <svg
                    v-if="numberToShow === item"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5Z"
                      fill="#73DD87"
                    />
                    <path
                      d="M10.5799 16.08C10.3799 16.08 10.1899 16 10.0499 15.86L7.21994 13.03C6.92994 12.74 6.92994 12.26 7.21994 11.97C7.50994 11.68 7.98994 11.68 8.27994 11.97L10.5799 14.27L15.7199 9.13001C16.0099 8.84001 16.4899 8.84001 16.7799 9.13001C17.0699 9.42001 17.0699 9.90001 16.7799 10.19L11.1099 15.86C10.9699 16 10.7799 16.08 10.5799 16.08Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </template>
        </CommonDropdown>
      </div>
    </div>
    <div class="overflow-auto">
      <table class="w-full">
        <thead class="table-header">
          <tr>
            <td
              class="hidden md:table-cell"
              :class="{
                '!hidden': selectedTab === TRANSACTION_LOBBY.USER_LASTEST_BET,
              }"
            >
              {{ $t("components.lobby_transaction.headers.user") }}
            </td>
            <td>{{ $t("components.lobby_transaction.headers.game") }}</td>
            <td class="hidden md:table-cell">
              {{ $t("components.lobby_transaction.headers.bet_amount") }}
            </td>
            <td class="hidden md:table-cell">
              {{ $t("components.lobby_transaction.headers.multiplier") }}
            </td>
            <td>{{ $t("components.lobby_transaction.headers.payout") }}</td>
          </tr>
        </thead>
        <tbody
          :class="[
            'table-body',
            isActiveOddRow ? 'active-odd' : 'active-even',
            { 'hide-user': selectedTab === TRANSACTION_LOBBY.USER_LASTEST_BET },
          ]"
        >
          <template v-if="loadingTransaction">
            <tr v-for="item in 10" :key="item">
              <td
                class="hidden md:table-cell"
                :class="{
                  '!hidden': selectedTab === TRANSACTION_LOBBY.USER_LASTEST_BET,
                }"
              >
                <CustomSkeleton class="h-5 w-2/5" />
              </td>
              <td>
                <CustomSkeleton class="h-5 w-2/5" />
              </td>
              <td class="hidden md:table-cell">
                <CustomSkeleton class="h-5 w-1/2" />
              </td>
              <td class="hidden md:table-cell">
                <CustomSkeleton class="h-5 w-1/2" />
              </td>
              <td>
                <CustomSkeleton class="h-5 w-1/2" />
              </td>
            </tr>
          </template>
          <template v-else>
            <tr
              v-for="(data, index) in displayTransactions"
              :key="index"
              @click="openDialog(data)"
            >
              <td
                class="hidden md:table-cell"
                :class="{
                  '!hidden': selectedTab === TRANSACTION_LOBBY.USER_LASTEST_BET,
                }"
              >
                <div class="flex items-center gap-2 truncate">
                  <div class="size-4 flex-shrink-0">
                    <img
                      loading="lazy"
                      :src="data.level_image"
                      class="h-full w-full object-contain"
                      alt=""
                    />
                  </div>
                  <span class="truncate">{{ data.username }}</span>
                </div>
              </td>
              <td>
                <NuxtLink
                  :to="ROUTES_NAME.PLAY_GAME(data.game_code)"
                  @click.stop
                  class="truncate hover:text-neutral-50-new"
                >
                  {{ data.game_name }}
                </NuxtLink>
              </td>
              <td class="hidden md:table-cell">
                <div class="flex items-center gap-2 truncate">
                  <div class="h-4 w-4 flex-shrink-0">
                    <img
                      loading="lazy"
                      :src="getAmountDisplay(data, 'bet_amount').image"
                      class="h-full w-full object-contain"
                      alt=""
                    />
                  </div>
                  <span class="truncate">
                    {{ getAmountDisplay(data, "bet_amount").amount }}
                  </span>
                </div>
              </td>
              <td class="hidden md:table-cell">
                <span
                  class="truncate"
                  :class="
                    Number(data.multiplier) >= 1
                      ? 'font-bold text-white'
                      : 'text-neutral-50-new'
                  "
                >
                  {{ data.multiplier }}x
                </span>
              </td>
              <td>
                <div class="flex items-center gap-2 truncate">
                  <div class="size-4 flex-shrink-0">
                    <img
                      loading="lazy"
                      :src="getAmountDisplay(data, 'payout_amount').image"
                      class="h-full w-full object-contain"
                      alt=""
                    />
                  </div>
                  <span class="truncate">
                    {{ getAmountDisplay(data, "payout_amount").amount }}
                  </span>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <DelayHydration>
      <DetailTransactionDialog :selected-item="selectedItem" v-model="isOpen" />
    </DelayHydration>
  </IntersectionLayout>
</template>
<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { ListType } from "~/components/ListTab.vue";
import DetailTransactionDialog from "~/components/lobby/DetailTransactionDialog.vue";
import { ALERT_TYPE, TRANSACTION_LOBBY } from "~/config/enum";
import { ROUTES_NAME } from "~/config/router";
import { getDetailTransaction } from "~/service/transactionHistoryService";
import { useTransactionHistoryStore } from "~/stores/transactionHistoryStore";

const route = useRoute();
const { t } = useI18n();
const transactionStore = useTransactionHistoryStore();
const { getListTransaction } = transactionStore;
const { listTransactionDataOriginal, loadingTransaction } =
  storeToRefs(transactionStore);
const isIntersect = ref(false);
const selectedTab = ref(TRANSACTION_LOBBY.LASTEST_BET);
const token = useToken();
const isOpen = ref(false);
const selectedItem = ref();
const listTab = computed((): ListType[] => [
  {
    label: t("lobby_transaction_tabs.my_bets"),
    value: TRANSACTION_LOBBY.USER_LASTEST_BET,
    hide: !token.value,
  },
  {
    label: t("lobby_transaction_tabs.latest_bet"),
    value: TRANSACTION_LOBBY.LASTEST_BET,
  },
  {
    label: t("lobby_transaction_tabs.high_rollers"),
    value: TRANSACTION_LOBBY.HIGH_ROLLER,
  },
]);

watch(
  [selectedTab, isIntersect],
  ([tab, intersect]) => {
    if (intersect) {
      getListTransaction(tab);
    }
  },
  { immediate: true }
);

const displayTransactions = computed(() =>
  listTransactionDataOriginal.value.slice(0, numberToShow.value)
);

const showDropdown = ref(false);
const numberToShow = ref(10);
const listNumberToShow = [10, 20, 30, 40, 50];
const isActiveOddRow = ref(false);

const handleTransaction = (responseValue: any) => {
  const ulidsOriginal = new Set(
    listTransactionDataOriginal.value?.map((item: any) => item.ulid)
  );
  const newItems =
    responseValue?.filter((item: any) => !ulidsOriginal.has(item.ulid)) || [];
  listTransactionDataOriginal.value = newItems
    .concat(listTransactionDataOriginal.value)
    .slice(0, 50);
  isActiveOddRow.value = !isActiveOddRow.value;
};

const openDialog = (data: any) => {
  selectedItem.value = data;
  isOpen.value = true;
};

watch(isIntersect, () => {
  if (isIntersect.value) {
    // if disconnect or lost internet connection then try to reconnect
    window?.Echo?.connector?.pusher?.connection?.bind("disconnected", () => {
      window?.Echo?.connect();
    });
    (window?.Echo?.channel("public_alert") as any)?.on(
      "PublicAlertEvent",
      async (response: any) => {
        if (response.type === ALERT_TYPE.TRANSACTION_LATEST_BET) {
          if (selectedTab.value === TRANSACTION_LOBBY.LASTEST_BET) {
            handleTransaction(response.value);
          }
        }
        if (response.type === ALERT_TYPE.TRANSACTION_HIGH_ROLLER_BET) {
          if (selectedTab.value === TRANSACTION_LOBBY.HIGH_ROLLER) {
            handleTransaction(response.value);
          }
        }
      }
    );
  }
});

const checkOpenModal = async () => {
  const query = route.query;
  if (query.modal === "bet" && query["md-id"]) {
    const ulid = query["md-id"].toString();
    try {
      const response = await getDetailTransaction(ulid);
      selectedItem.value = response.data.data;
      isOpen.value = true;
    } catch {
      selectedItem.value = null;
    }
  }
};
onMounted(() => {
  void checkOpenModal();
});
</script>
<style lang="postcss">
.table-header {
  td {
    @apply px-4 py-2.5 text-xs text-[#828998];
  }
}

.table-body {
  tr:hover td {
    @apply bg-main-150;
  }

  td {
    @apply cursor-pointer p-4 text-sm transition-all;
  }

  td:first-child {
    @apply rounded-none md:rounded-l-xl;
  }

  td:nth-child(2) {
    @apply rounded-none max-md:rounded-l-xl;
  }

  td:last-child {
    @apply rounded-r-xl;
  }
}

.hide-user {
  td:nth-child(2) {
    @apply rounded-l-xl;
  }
}

.table-body.active-odd {
  tr:nth-child(2n) {
    td {
      @apply bg-[#121418];
    }

    td:nth-child(2) > * {
      @apply !w-3/4;
    }
  }
}

.table-body.active-even {
  tr:nth-child(2n + 1) {
    td {
      @apply bg-[#121418];
    }

    td:nth-child(2) > * {
      @apply !w-3/4;
    }
  }
}
</style>
