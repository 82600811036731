<template>
  <NavbarSwiper>
    <button
      type="button"
      @click="handleRedirect('')"
      class="flex h-[42px] items-center gap-2 rounded-lg px-4 text-neutral-50-new transition-all hover:bg-main-pink hover:text-white"
      :class="!selectedCategory ? activeClass : ''"
    >
      <svg
        v-if="!mainIcon"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.73673 2.07301C9.47324 1.86807 9.34149 1.7656 9.19601 1.72622C9.06765 1.69146 8.93235 1.69146 8.80399 1.72622C8.65851 1.7656 8.52677 1.86807 8.26327 2.07301L3.17654 6.02936C2.83652 6.29382 2.6665 6.42606 2.54402 6.59166C2.43552 6.73835 2.3547 6.9036 2.30552 7.0793C2.25 7.27765 2.25 7.49304 2.25 7.9238V13.35C2.25 14.1901 2.25 14.6101 2.41349 14.931C2.5573 15.2132 2.78677 15.4427 3.06901 15.5865C3.38988 15.75 3.80992 15.75 4.65 15.75H6.15C6.36002 15.75 6.46503 15.75 6.54525 15.7091C6.61581 15.6732 6.67317 15.6158 6.70913 15.5453C6.75 15.465 6.75 15.36 6.75 15.15V10.2C6.75 9.77996 6.75 9.56994 6.83175 9.40951C6.90365 9.26839 7.01839 9.15365 7.15951 9.08175C7.31994 9 7.52996 9 7.95 9H10.05C10.47 9 10.6801 9 10.8405 9.08175C10.9816 9.15365 11.0963 9.26839 11.1683 9.40951C11.25 9.56994 11.25 9.77996 11.25 10.2V15.15C11.25 15.36 11.25 15.465 11.2909 15.5453C11.3268 15.6158 11.3842 15.6732 11.4548 15.7091C11.535 15.75 11.64 15.75 11.85 15.75H13.35C14.1901 15.75 14.6101 15.75 14.931 15.5865C15.2132 15.4427 15.4427 15.2132 15.5865 14.931C15.75 14.6101 15.75 14.1901 15.75 13.35V7.9238C15.75 7.49304 15.75 7.27765 15.6945 7.0793C15.6453 6.9036 15.5645 6.73835 15.456 6.59166C15.3335 6.42606 15.1635 6.29382 14.8235 6.02936L9.73673 2.07301Z"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <!-- dynamic icon -->
      <span class="block size-[18px]" v-else>
        <img
          loading="lazy"
          class="h-full w-full object-contain"
          :src="mainIcon"
          alt=""
        />
      </span>

      <span
        class="truncate break-words text-center text-xs font-bold capitalize"
      >
        {{ mainHomeLabel }}
      </span>
    </button>

    <button
      v-for="(category, i) in categoriesRef"
      :key="i"
      type="button"
      @click="handleRedirect(category.slug)"
      class="flex h-[42px] items-center gap-2 rounded-lg px-4 text-neutral-50-new transition-all hover:bg-main-pink hover:text-white"
      draggable="false"
      :class="category.slug === selectedCategory ? activeClass : ''"
    >
      <span class="block size-[18px]" v-if="category.image">
        <img class="h-full w-full object-cover" :src="category.image" alt="" />
      </span>

      <span
        class="truncate break-words text-center text-xs font-bold capitalize"
      >
        {{ category.name }}
      </span>
    </button>
  </NavbarSwiper>
</template>
<script setup lang="ts">
type Props = {
  selectedCategory: string;
  categoriesRef?: any[];
  mainRoute: string;
  mainHomeLabel: string;
  mainIcon?: string;
};
defineProps<Props>();

const activeClass = "category-active";

const emit = defineEmits(["updateData"]);

const handleRedirect = (value: string) => {
  emit("updateData", value);
};
</script>
<style scoped lang="postcss">
:deep(.category-active) {
  @apply h-[42px] rounded-lg bg-main-pink !text-white;
}
</style>
