import { defineNuxtPlugin } from "#imports";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import duration from "dayjs/plugin/duration";
import relative from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";

export default defineNuxtPlugin({
  name: "fetch",
  setup: () => {
    dayjs.extend(duration);
    dayjs.extend(utc);
    dayjs.extend(relative);
    dayjs.extend(advancedFormat);
  },
});
