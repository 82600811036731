<template>
  <div class="flex-grow">
    <v-form @submit.prevent="onRequestWithdrawl" ref="form">
      <div class="flex flex-col gap-4 sm:flex-row">
        <div class="flex flex-grow flex-col gap-1">
          <p class="text-xs font-medium text-white">
            {{ $t("common.currency") }}
          </p>
          <select-wallet-dropdown
            :currency="userWalletCurrency"
            :selectedWalletRef="selected"
            @onWalletChange="onWalletChange"
            @updateSelectedWallet="(value) => (selected = value)"
            :showAmount="true"
          />
        </div>

        <div class="flex w-full flex-col gap-1 sm:w-[160px]">
          <p class="required white text-xs font-medium text-white">
            {{ $t("common.network") }}
          </p>
          <select-wallet-network
            :selectedWalletRef="selected"
            :selectedNetWork="selectedNetWork"
            @on-network-change="onNetworkChange"
          />
        </div>
      </div>
      <p class="my-6 text-center text-sm text-white">
        {{
          $t("wallet_transaction.withdraw.please_enter", {
            code: selected?.entity_wallet_code,
          })
        }}
      </p>
      <div class="flex flex-col gap-1">
        <div class="text-xs font-medium text-white">
          <span class="required white">{{ $t("common.wallet_address") }}</span>
        </div>
        <v-text-field
          v-model="walletAddress"
          variant="outlined"
          bg-color="#202329"
          color="#ffffff"
          :placeholder="$t('common.placeholder.wallet_address')"
          class="textfield-base w-[100%]"
          :rules="[
            validation.required,
            (value) =>
              validation.max_length(
                value,
                handleLabelByKind(selectedNetWork?.kind),
                selectedNetWork?.kind === WALLET_NETWORK_KIND.BTC_LIGHTNING
                  ? 512
                  : 191
              ),
          ]"
        />
      </div>
      <div
        v-if="selectedNetWork?.kind === WALLET_NETWORK_KIND.XRP"
        class="mt-4 flex flex-col gap-1"
      >
        <div class="text-xs font-medium text-white">
          <span>{{ $t("wallet_transaction.withdraw.destination_tag") }}</span>
        </div>
        <v-text-field
          v-model="walletDestinationTag"
          variant="outlined"
          bg-color="#202329"
          color="#ffffff"
          :placeholder="$t('common.placeholder.destination_tag')"
          class="textfield-base w-[100%]"
          v-numeric-input="NUMBERIC_TYPE.WITHOUT_DOT"
          type="number"
          :rules="[
            (value) =>
              validation.max_length(
                value,
                $t('wallet_transaction.withdraw.destination_tag'),
                15
              ),
          ]"
        />
      </div>
      <div class="my-6 !rounded-xl border !border-main-150 p-6">
        <div class="flex flex-col gap-4">
          <p class="font-extrabold text-white">
            {{
              $t("wallet_transaction.withdraw.min_withdraw_amount", {
                value: userWalletCurrency
                  ? `${symbol}${roundDecimalNumber(Number(minAmount), 2)}`
                  : roundDecimalNumber(Number(minAmount), 8),
              })
            }}
          </p>
          <p class="text-sm text-neutral-50-new">
            {{ $t("wallet_transaction.withdraw.transaction_fee") }}
            {{
              userWalletCurrency
                ? `${symbol}${roundDecimalNumber(Number(feeAmount), 2)}`
                : roundDecimalNumber(Number(feeAmount), 8)
            }}
          </p>
          <div class="flex flex-col gap-1">
            <div class="flex flex-wrap items-center justify-between gap-2">
              <div class="text-xs font-medium capitalize text-white">
                <span class="required white">
                  {{
                    $t("wallet_transaction.withdraw.withdrawal_amount")
                  }}</span
                >
              </div>
              <div class="text-xs font-medium text-white">
                <span
                  >{{
                    userWalletCurrency
                      ? roundDecimalNumber(Number(withdrawalAmountRaw), 8)
                      : `${roundDecimalNumber(Number(withdrawalAmountRaw), 2)}`
                  }}
                  {{
                    userWalletCurrency
                      ? selected?.entity_wallet_code
                      : `${getCurrencyData()?.name}`
                  }}</span
                >
              </div>
            </div>
            <v-text-field
              type="number"
              v-model="withdrawalAmount.input"
              variant="outlined"
              bg-color="#202329"
              color="#ffffff"
              :placeholder="$t('common.placeholder.withdrawal_amount')"
              class="wallet-amount-input textfield-base w-[100%]"
              :rules="[
                (value) =>
                  validation.min(
                    value,
                    userWalletCurrency
                      ? Number(roundDecimalNumber(minAmount, 2))
                      : minAmount,
                    $t('validation_message.min', {
                      min: userWalletCurrency
                        ? `${symbol}${roundDecimalNumber(minAmount, 2)}`
                        : `${roundDecimalNumber(minAmount, 8)} ${symbol}`,
                    })
                  ),
                (value) =>
                  validation.max(
                    value,
                    userWalletCurrency
                      ? Number(roundDecimalNumber(maxAmount, 2))
                      : maxAmount,
                    $t('validation_message.max', {
                      max: userWalletCurrency
                        ? `${symbol}${roundDecimalNumber(maxAmount, 2)}`
                        : `${roundDecimalNumber(maxAmount, 8)} ${symbol}`,
                    })
                  ),
              ]"
              v-numeric-input="NUMBERIC_TYPE.DOT"
              @blur="(event: any) => onBlurWithdrawalAmount(event)"
              @input="onInputWithdrawalAmount"
            />
          </div>
        </div>
      </div>
      <p class="px-8 text-center text-sm text-neutral-25-new">
        {{
          $t("wallet_transaction.withdraw.receive_amount", {
            code: selected?.entity_wallet_code,
          })
        }}
        <br />
        {{ $t("wallet_transaction.withdraw.fee_apply") }}
      </p>
      <div class="mb-8 mt-6 h-[1px] bg-main-150"></div>
      <div class="flex justify-center">
        <button
          type="submit"
          :disabled="
            !walletAddress ||
            !withdrawalAmount.input ||
            handling ||
            Number(withdrawalAmount.input) > maxAmount ||
            Number(withdrawalAmount.input) < minAmount
          "
          class="h-[56px] w-[237px] max-w-full !rounded-lg bg-main-pink font-extrabold text-white"
        >
          {{ $t("common.btn.request_withdrawal") }}
        </button>
      </div>
    </v-form>
  </div>
</template>
<script setup lang="ts">
import { NUMBERIC_TYPE, WALLET_NETWORK_KIND } from "~/config/enum";
import { useGetListEntityWallets } from "~/queries/walletService";
import { requestWithdrawal } from "~/service/walletService";
import { useAuthStore } from "~/stores/authStore";
import { useWalletStore } from "~/stores/walletStore";
import { withdrawalPayload } from "~/types/schema";
import {
  handleLabelByKind,
  roundDecimalNumber,
  getRawWalletValueFromCurrency,
  getCurrencyData,
} from "~/utils";
import SelectWalletDropdown from "./SelectWalletDropdown.vue";
import SelectWalletNetwork from "./SelectWalletNetwork.vue";

const walletStore = useWalletStore();
const authStore = useAuthStore();
const { data: listEntityWallets } = useGetListEntityWallets();
const { selectedWallet, listCurrencies, setListWallet, setListCurrencies } =
  walletStore;
const { user } = authStore;
const userWalletCurrency = computed(
  () => user?.player_information?.wallet_currency
);

const { $toast, $i18n } = useNuxtApp();
const { t } = $i18n || {};
const selected = ref(selectedWallet);
const walletAddress = ref("");
const withdrawalAmount = ref({
  raw: userWalletCurrency.value ? "0.00" : "0.00000000",
  input: userWalletCurrency.value ? "0.00" : "0.00000000",
});
const withdrawalAmountRaw = ref(0);
const minAmount = ref(0);
const maxAmount = ref(0);
const feeAmount = ref(0);
const form = ref();
const symbol = ref("");
const selectedNetWork = ref();
const walletDestinationTag = ref("");
const handling = ref(false);

watch(
  () => withdrawalAmount.value.raw,
  () => {
    if (userWalletCurrency.value) {
      withdrawalAmountRaw.value = getRawWalletValueFromCurrency(
        withdrawalAmount.value.raw,
        selected.value?.entity_wallet_code,
        userWalletCurrency.value
      );
    } else {
      const rate = listCurrencies?.rate[selected.value?.entity_wallet_code];
      const value =
        Number(withdrawalAmount.value.raw) *
        Number(rate[getCurrencyData()?.name]);
      withdrawalAmountRaw.value = value;
    }
  }
);

watch(
  () => selected.value,
  () => {
    getSymbol();
  }
);

const getSymbol = () => {
  symbol.value = listCurrencies?.currencies?.find(
    (item: any) => item?.name === userWalletCurrency.value
  )?.symbol;

  if (!symbol.value) {
    symbol.value = selected.value?.entity_wallet_code;
  }
};
getSymbol();

// get raw min withdrawal and fee amount
const getAmountByField = (walletSelected: any, field: string) => {
  let result = 0;
  const itemFind: any = listEntityWallets.value?.find(
    (item) => item?.code === walletSelected?.entity_wallet_code
  );
  if (itemFind) {
    result = itemFind[field];
  }
  return Number(result);
};

// handle min withdrawal and fee amount by currency rate
const getValueByRate = (wallet: any, field: any) => {
  const rate = listCurrencies?.rate[wallet?.entity_wallet_code];
  const balance = getAmountByField(selected.value, field);
  const value = balance * Number(rate[userWalletCurrency.value]);
  return Number(value);
};

const getMinAmount = (wallet: any) => {
  const minPopup = userWalletCurrency.value
    ? getValueByRate(wallet, "min_topup")
    : getAmountByField(wallet, "min_topup");
  if (userWalletCurrency.value) {
    const offset = Math.trunc(minPopup * 1000) / 1000;
    const lastNumber = Number(offset.toString().slice(-1));
    if (lastNumber < 5) {
      minAmount.value = minPopup + 0.001 * (5 - lastNumber);
    } else {
      minAmount.value = minPopup;
    }
  } else {
    minAmount.value = minPopup;
  }
};
getMinAmount(selected.value);

const getFeeAmount = (wallet: any) => {
  feeAmount.value = userWalletCurrency.value
    ? getValueByRate(wallet, "withdrawal_fee")
    : getAmountByField(wallet, "withdrawal_fee");
};
getFeeAmount(selected.value);

// handle max withdraw amount
const getMaxAmount = (wallet: any) => {
  function getMaxAmountByBalanceFee() {
    const result = balanceRaw - feeAmountRaw;
    if (result > 0) {
      if (userWalletCurrency.value) {
        const rate = listCurrencies?.rate[wallet?.entity_wallet_code];
        return result * Number(rate[userWalletCurrency.value]);
      } else {
        return Number(roundDecimalNumber(Number(result), 8));
      }
    }
    return 0;
  }

  // raw value
  const balanceRaw = Number(wallet?.balance);
  const maxAmountConfigRaw = getAmountByField(wallet, "max_topup");
  const feeAmountRaw = getAmountByField(wallet, "withdrawal_fee");
  if (balanceRaw > 0) {
    if (balanceRaw - feeAmountRaw > maxAmountConfigRaw) {
      maxAmount.value = userWalletCurrency.value
        ? getValueByRate(wallet, "max_topup")
        : maxAmountConfigRaw;
    } else {
      maxAmount.value = getMaxAmountByBalanceFee();
    }
  } else {
    maxAmount.value = 0;
  }
};
getMaxAmount(selected.value);

watch(
  [listEntityWallets, selected],
  ([wallets, selected]) => {
    const selectedWallet = wallets?.find(
      (item) => item?.code === selected?.entity_wallet_code
    );
    if (selectedWallet)
      selectedNetWork.value = selectedWallet.networks[0] || "";
  },
  {
    immediate: true,
  }
);

const resetAllForm = () => {
  form.value?.reset();
  // if field not required then must set varibale value = '' after reset form, otherwise it will become null then trigger validation in some unexpected case
  walletDestinationTag.value = "";
  withdrawalAmount.value.input = userWalletCurrency.value
    ? "0.00"
    : "0.00000000";
  withdrawalAmount.value.raw = withdrawalAmount.value.input;
};

const onNetworkChange = (value: any) => {
  if (selectedNetWork.value !== value) {
    selectedNetWork.value = value;
    resetAllForm();
  }
};

const onWalletChange = (value: any) => {
  if (selected.value !== value) {
    selected.value = value;
    // getSelectedNetWork();
    getMinAmount(selected.value);
    getFeeAmount(selected.value);
    getMaxAmount(selected.value);
    resetAllForm();
  }
};

const setMaxWithdrawalAmount = (event: any) => {
  if (userWalletCurrency.value) {
    if (Number(roundDecimalNumber(maxAmount.value, 2)) > 0) {
      withdrawalAmount.value.raw = maxAmount.value.toString();
      withdrawalAmount.value.input = roundDecimalNumber(
        Number(maxAmount.value),
        2
      ).toString();
      // re-assign old value
      const input = event.target.nextElementSibling;
      input.setAttribute("data-old-value", withdrawalAmount.value.input);
    }
  } else {
    withdrawalAmount.value.raw = maxAmount.value.toString();
    withdrawalAmount.value.input = roundDecimalNumber(
      Number(maxAmount.value),
      8
    ).toString();
    // re-assign old value
    const input = event.target.nextElementSibling;
    input.setAttribute("data-old-value", withdrawalAmount.value.input);
  }
};

const formatWithdrawalRawAmount = (inputValue: string) => {
  if (userWalletCurrency.value) {
    if (
      Number(roundDecimalNumber(maxAmount.value, 2)) > 0 &&
      Number(inputValue) === Number(roundDecimalNumber(maxAmount.value, 2))
    ) {
      withdrawalAmount.value.raw = maxAmount.value.toString();
    } else {
      withdrawalAmount.value.raw = inputValue;
    }
  } else {
    withdrawalAmount.value.raw = inputValue;
  }
};

const onBlurWithdrawalAmount = (event: any) => {
  if (!isNaN(Number(withdrawalAmount.value.input))) {
    withdrawalAmount.value.input = userWalletCurrency.value
      ? roundDecimalNumber(Number(withdrawalAmount.value.input), 2).toString()
      : roundDecimalNumber(Number(withdrawalAmount.value.input), 8).toString();
    formatWithdrawalRawAmount(withdrawalAmount.value.input);
  } else {
    if (userWalletCurrency.value) {
      withdrawalAmount.value.input = "0.00";
    } else {
      withdrawalAmount.value.input = "0.00000000";
    }
  }
  event.target.setAttribute("data-old-value", withdrawalAmount.value.input);
};

const onInputWithdrawalAmount = (event: any) => {
  formatWithdrawalRawAmount(event.target.value);
};

const onRequestWithdrawl = async (event: any) => {
  handling.value = true;
  const results = await event;
  if (results?.valid) {
    try {
      const payload: withdrawalPayload = {
        received_amount: userWalletCurrency.value
          ? withdrawalAmountRaw.value
          : Number(withdrawalAmount.value.raw),
        entity_wallet_code: selected.value?.entity_wallet_code,
        kind: selectedNetWork.value?.kind,
      };
      if (selectedNetWork.value?.kind === WALLET_NETWORK_KIND.BTC_LIGHTNING) {
        payload.payment_request = walletAddress.value;
      } else {
        payload.wallet_address = walletAddress.value;
        if (
          selectedNetWork.value?.kind === WALLET_NETWORK_KIND.XRP &&
          walletDestinationTag.value
        ) {
          payload.destination_tag = Number(walletDestinationTag.value);
        }
      }

      const response = await requestWithdrawal(payload);
      if (response?.data?.success) {
        resetAllForm();
        await setListCurrencies();
        await setListWallet();
        getMinAmount(selected.value);
        getFeeAmount(selected.value);
        getMaxAmount(selected.value);
        $toast.success(t("common.message.request_withdrawal_success"));
      }
    } catch (error: any) {
      const message = error?.response?.data?.message;
      $toast.error(message || t("common.message.request_withdrawal_failed"));
    }
  }
  handling.value = false;
};
</script>

<style scoped lang="scss">
:deep(.wallet-amount-input) {
  .v-field__input {
    padding-right: 50px;
  }
}

.btn-gradient {
  font-size: 15px;
  font-weight: 700;
  line-height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-left: 12px;
    font-size: 21px;
  }

  &:not(:disabled) {
    background: linear-gradient(
      270.46deg,
      #ff3eca 0.23%,
      #9b37ff 40%,
      #8378ff 55.55%,
      #53ccff 98.94%
    );
    color: #fff;
  }

  &:disabled {
    background: #544274;
    color: #afa8bf;
  }
}
</style>
