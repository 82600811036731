<template>
  <div class="relative mx-auto w-full px-4 lg:px-10">
    <div class="flex items-center justify-between">
      <NuxtLinkScrollToTop :to="ROUTES_NAME.LOBBY">
        <img
          src="/images/logo.svg"
          class="h-[20px] xxs:h-[25px] xs:h-[30px]"
          alt="CasinoPunkz"
          loading="lazy"
        />
      </NuxtLinkScrollToTop>
      <div class="flex items-center gap-2 sm:gap-4" v-if="!isWebAppUrl()">
        <ClientOnly>
          <NuxtLink
            key="client-login"
            :to="ROUTES_NAME.LOGIN"
            @click="
              (e) => {
                e.preventDefault();
                navigateTo(`${ROUTES_NAME.LOGIN}?${getRandomLetter()}`);
              }
            "
            class="flex h-10 min-w-[74px] items-center justify-center rounded-lg bg-main-125 px-2 text-[10px] font-medium text-white xs:text-xs sm:!px-3 sm:text-sm"
          >
            {{ $t("header.login") }}
          </NuxtLink>
          <NuxtLink
            key="client-signup"
            :to="ROUTES_NAME.SIGN_UP"
            @click="
              (e) => {
                e.preventDefault();
                navigateTo(`${ROUTES_NAME.SIGN_UP}?${getRandomLetter()}`);
              }
            "
            class="pulse-button flex h-10 min-w-[74] items-center justify-center rounded-lg bg-main-pink px-2 text-[10px] font-medium capitalize text-white xs:text-sm sm:!px-3"
          >
            {{ $t("common.register") }}
          </NuxtLink>
          <template #fallback>
            <NuxtLink
              :to="ROUTES_NAME.LOGIN"
              class="flex h-10 min-w-[74px] items-center justify-center rounded-lg bg-main-125 px-2 text-[10px] font-medium text-white xs:text-xs sm:!px-3 sm:text-sm"
            >
              {{ $t("header.login") }}
            </NuxtLink>
            <NuxtLink
              :to="ROUTES_NAME.SIGN_UP"
              class="pulse-button flex h-10 min-w-[74] items-center justify-center rounded-lg bg-main-pink px-2 text-[10px] font-medium capitalize text-white xs:text-sm sm:!px-3"
            >
              {{ $t("common.register") }}
            </NuxtLink>
          </template>
        </ClientOnly>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { navigateTo, useRoute } from "#app";
import { storeToRefs } from "pinia";
import { watch } from "vue";
import { QUERY_PARAMS } from "~/config/enum";
import { ROUTES_NAME } from "~/config/router";
import { useSystemStore } from "~/stores/systemStore";
import { getRandomLetter } from "~/utils";

const route = useRoute();

const useSystem = useSystemStore();
const { changeWalletTransactionDialog } = useSystem;
const { walletTransactionState } = storeToRefs(useSystem);
watch(walletTransactionState, () => {
  if (walletTransactionState.value) {
    const backToUrl = `${route.path}?${QUERY_PARAMS.OPEN_DEPOSIT}=true`;
    navigateTo({
      path: ROUTES_NAME.LOGIN,
      query: { backToUrl },
    });
    changeWalletTransactionDialog(false);
  }
});
</script>
<style scoped lang="scss"></style>
