<template>
  <v-dialog v-model="useSystem.sendMailVerifyDialog" width="auto">
    <v-card
      class="mx-[10px] w-[calc(100vw-68px)] max-w-[560px] !rounded-[16px] !bg-[#49198E] !px-[32px] !py-[40px]"
    >
      <img
        src="/images/icon/security-safe-icon.png"
        alt="security-safe-icon"
        class="m-auto my-0 !mb-[24px] w-[102px]"
      />
      <span
        class="mb-[16px] text-center text-[24px] font-[700] uppercase leading-[32px] text-[#ffffff]"
      >
        {{ $t("common.message.verify_now") }}
      </span>
      <span
        class="mb-[32px] text-center text-[14px] font-[400] leading-[21px] text-[#F2D6FF]"
      >
        {{ $t("dialog.mail_verify_success.check_email") }}
      </span>
      <span class="line-gradient mb-[32px]"></span>
      <div
        @click="verifyEmail()"
        class="btn-gradient m-auto flex h-[56px] w-[198px] cursor-pointer items-center gap-2 rounded-[100px] uppercase text-white hover:opacity-90"
      >
        {{ $t("common.message.resend_link") }}
        <v-icon class="ml-2">mdi-chevron-right</v-icon>
      </div>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { useAuthStore } from "~/stores/authStore";
import { useSystemStore } from "~/stores/systemStore";

const useSystem = useSystemStore();
const authStore = useAuthStore();

const userInfo: any = authStore.getUser;

const verifyEmail = async () => {
  await authStore.resendVerifyMail(userInfo.email);
};
</script>
<style scoped lang="scss">
.line-gradient {
  height: 2px;
  width: 100%;
  display: block;
  background: linear-gradient(
    90deg,
    rgba(192, 173, 216, 0) 0%,
    rgba(189, 173, 216, 0.4) 49.48%,
    rgba(192, 173, 216, 0) 100%
  );
}

.btn-gradient {
  background: linear-gradient(
    270.46deg,
    #ff3eca 0.23%,
    #9b37ff 40%,
    #8378ff 55.55%,
    #53ccff 98.94%
  );
  font-size: 15px;
  font-weight: 700;
  line-height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
