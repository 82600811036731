import { transactionHistoryParams } from "~/types/schema";

export const getTransactionHistories = (payload: transactionHistoryParams) => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/user/transactions`,
    method: "get",
    params: payload,
  });
};

export const cancelWithdrawal = (transactionId: number) => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/user/cancel-withdrawal/${transactionId}`,
    method: "patch",
  });
};

export const getLastestBetListByUser = () => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/transactions/user-latest-bet/list`,
    method: "get",
  });
};

export const getLastestBetList = () => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/transactions/latest-bet/list`,
    method: "get",
  });
};

export const getHighRollerBetList = () => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/transactions/highest-roller-bet/list`,
    method: "get",
  });
};

export const getDetailTransaction = (ulid: string) => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/transactions/detail/${ulid}`,
    method: "get",
  });
};
