<template>
  <div class="smooth-scrollbar-2 custom-scrollbar" ref="smooth-scrollbar">
    <slot />
  </div>
</template>
<script setup lang="ts">
import Scrollbar from "smooth-scrollbar";

const scrollbar = useTemplateRef<HTMLDivElement>("smooth-scrollbar");
let scrollbarInstance: any = null;

const scrollToBottom = async () => {
  await nextTick();
  scrollbarInstance?.addMomentum(0, scrollbarInstance.contentEl.offsetHeight);
  scrollbarInstance?.update();
  scrollbarInstance?.scrollTo(0, scrollbarInstance.limit.y, 300);
};

watch(scrollbar, () => {
  if (isClient() && scrollbar.value) {
    scrollbarInstance = Scrollbar.init(scrollbar.value, {
      alwaysShowTracks: true,
      damping: 0.8,
    });

    window.addEventListener("scroll-to-bottom", scrollToBottom);
  }
});

onUnmounted(() => {
  if (scrollbarInstance) {
    scrollbarInstance.destroy();
  }
  window.removeEventListener("scroll-to-bottom", scrollToBottom);
});
</script>
<style lang="scss">
.smooth-scrollbar-2 {
  .scrollbar-track {
    @apply size-1.5 bg-transparent;
  }

  .scrollbar-thumb {
    @apply size-1.5 bg-main-pink/50;
  }
}
</style>
