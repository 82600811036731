import {
  defineNuxtPlugin,
  useRuntimeConfig,
  deleteOldCookie,
  navigateTo,
} from "#imports";
import { RESPONSE_STATUS } from "~/config/enum";
import { ROUTES_NAME } from "~/config/router";

export default defineNuxtPlugin({
  name: "fetch",
  dependsOn: ["pinia"],
  setup: () => {
    const config = useRuntimeConfig();

    const api2 = $fetch.create({
      baseURL: config.public.BASE_URL_API,
      onRequest: ({ options }) => {
        const tokenCookie = useToken();
        const currentLang = useCurrentLanguage();
        const url = useRequestURL();
        const rawHeaders = useRequestHeaders();
        const xForwardedFor = String(
          rawHeaders["x-forwarded-for"]?.split(",")[0] || ""
        ).trim();
        if (xForwardedFor && xForwardedFor !== "127.0.0.1") {
          options.headers.set("X-Forwarded-For", xForwardedFor);
          options.headers.set("X-Forwarded-For-2", xForwardedFor);
        }
        options.headers.set("Accept", "application/json");
        options.headers.set("Access-Control-Allow-Origin", "*");
        options.headers.set("X-localization", currentLang.value as any);
        options.headers.set("X-Domain", url.hostname);
        if (tokenCookie.value) {
          options.headers.set("Authorization", `Bearer ${tokenCookie.value}`);
        }
      },

      onResponseError: async ({ error, response }) => {
        const url = useRequestURL();
        if (error) {
          return Promise.reject(error);
        }
        (response as any).data = response._data;
        const status = response?.status;
        if (status === RESPONSE_STATUS.UNAUTHORIZED) {
          const tokenCookie = useToken();
          tokenCookie.value = null;

          deleteOldCookie("token");

          const pathLogin = `${
            ROUTES_NAME.LOGIN
          }?backToUrl=${encodeURIComponent(url.pathname + url.search)}`;
          const route = isWebApp() ? ROUTES_NAME.LOBBY : pathLogin;

          await navigateTo({ path: route }, { external: true });
          return Promise.reject(response);
        }
        return Promise.reject(response);
      },
    });

    return {
      provide: {
        api2,
      },
    };
  },
});
