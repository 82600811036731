<template>
  <div :class="{ hidden: !props.item }" class="h-5 w-5 flex-shrink-0">
    <IconLobby :getStyles="getStyles" v-if="props?.item?.id === 1" />
    <IconFavorite :getStyles="getStyles" v-if="props?.item?.id === 2" />
    <IconPropmotion :getStyles="getStyles" v-if="props?.item?.id === 3" />
    <IconProvider :getStyles="getStyles" v-if="props?.item?.id === 11" />
    <IconVipProgram :getStyles="getStyles" v-if="props?.item?.id === 12" />
    <IconBlog :getStyles="getStyles" v-if="props?.item?.id === 13" />
    <IconAffiliate :getStyles="getStyles" v-if="props?.item?.id === 14" />
    <IconVpnInfo :getStyles="getStyles" v-if="props?.item?.id === 23" />
    <IconLiveSupport :getStyles="getStyles" v-if="props?.item?.id === 15" />
    <IconPlayAgain :getStyles="getStyles" v-if="props?.item?.id === 16" />
    <IconBiggestWin :getStyles="getStyles" v-if="props?.item?.id === 17" />
    <IconProfile :getStyles="getStyles" v-if="props?.item?.id === 18" />
    <IconMyBonus :getStyles="getStyles" v-if="props?.item?.id === 19" />
    <IconWallet :getStyles="getStyles" v-if="props?.item?.id === 20" />
    <IconTransaction :getStyles="getStyles" v-if="props?.item?.id === 21" />
    <IconChallenge :getStyles="getStyles" v-if="props?.item?.id === 22" />
    <IconTournament :getStyles="getStyles" v-if="props?.item?.id === 4" />
    <IconNotification :getStyles="getStyles" v-if="props?.item?.id === 24" />
  </div>
</template>

<script setup lang="ts">
import { ROUTES_NAME } from "~/config/router";

const props = defineProps(["item", "active", "default"]);
const route = useRoute();

const getStyles = computed(() => {
  if (props.item.disabled) {
    return "#5A5B5E";
  } else if (props.default) {
    return "#fff";
  } else if (checkActiveRoute(route.path, props.item?.url) || props.active) {
    return "#FD3FCC";
  } else {
    return "#fff";
  }
});
const checkActiveRoute = (currentRoute: string, targetRoute: string) => {
  if (targetRoute === ROUTES_NAME.LOBBY) {
    return currentRoute === ROUTES_NAME.LOBBY;
  }
  return currentRoute.includes(targetRoute);
};
</script>

<style scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
