<template>
  <svg
    class="size-10"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1.05273"
      y="1.55261"
      width="37.8947"
      height="37.8947"
      rx="18.9474"
      fill="#343843"
    />
    <path
      d="M23.2966 25.3982H16.7001C16.4054 25.3982 16.0756 25.1667 15.9773 24.886L13.0721 16.7596C12.658 15.5947 13.1423 15.2368 14.1387 15.9526L16.8756 17.9105C17.3317 18.2263 17.851 18.0649 18.0475 17.5526L19.2826 14.2614C19.6756 13.2088 20.3282 13.2088 20.7212 14.2614L21.9563 17.5526C22.1528 18.0649 22.6721 18.2263 23.1212 17.9105L25.6896 16.0789C26.7844 15.293 27.3107 15.693 26.8616 16.9632L24.0265 24.9C23.9212 25.1667 23.5914 25.3982 23.2966 25.3982Z"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.1387 27.5175H23.858"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.2441 21.9035H21.7529"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
