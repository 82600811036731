import { filterNotification } from "~/types/schema";

export const getNotifications = (payload: filterNotification) => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/notifications`,
    method: "get",
    params: payload,
  });
};
