import { computed } from "vue";
import { ROUTES_NAME } from "./router";

export const ITEM_PER_PAGE = 20;

export const passwordRegex =
  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!%@#&\-()_[\]{}:;`'",.+*?\\/~$^=<>|\\])[0-9A-Za-z!%@#&\-()_[\]{}:;`'",.+*?\\/~$^=<>|\\]{8,32}$/;

export const pwRegexObject = {
  numberic: {
    message: "validation_message.numeric",
    regex: /(?=.*[0-9])/,
  },
  length: {
    message: "validation_message.pw_length",
    regex: /^.{8,32}$/,
  },
  lowercase: {
    message: "validation_message.lowercase",
    regex: /(?=.*[a-z])/,
  },
  uppercase: {
    message: "validation_message.uppercase",
    regex: /(?=.*[A-Z])/,
  },
  special_character: {
    message: "validation_message.special_character",
    regex: /(?=.*[!%@#&\-()_[\]{}:;`'",.+*?\\/~$^=<>|\\])/,
  },
};

export const DATE_FORMAT = {
  YYYY_MM_DD_UTC: "YYYY-MM-DD[T]HH:mm:ss[Z]",
  YYYY_MM_DD_UTCS: "YYYY-MM-DD[T]00:00:00[Z]",
  YYYY_MM_DD_UTCE: "YYYY-MM-DD[T]23:59:59[Z]",
};

export const API_LOADING_TIMEOUT = 700;
export const noBonusValue = -1;

export const MAX_ITEM_SHOW_SLIDER = 10;

export const listLangs = computed(() => [
  {
    title: useNuxtApp().$i18n.t("common.languages.english"),
    value: "en",
  },
  {
    title: useNuxtApp().$i18n.t("common.languages.germany"),
    value: "de",
  },
  {
    title: useNuxtApp().$i18n.t("common.languages.japanese"),
    value: "ja",
  },
  {
    title: useNuxtApp().$i18n.t("common.languages.french"),
    value: "fr",
  },
  {
    title: useNuxtApp().$i18n.t("common.languages.dutch"),
    value: "nl",
  },
  {
    title: useNuxtApp().$i18n.t("common.languages.portuguese"),
    value: "pt",
  },
  {
    title: useNuxtApp().$i18n.t("common.languages.turkish"),
    value: "tr",
  },
  {
    title: useNuxtApp().$i18n.t("common.languages.spanish"),
    value: "es",
  },
]);

export const notAllowFetchUserGuestList = (slug: any) => [
  ROUTES_NAME.LOBBY,
  ROUTES_NAME.LIVE_CASINO,
  ROUTES_NAME.PROVIDER_LIST,
  ROUTES_NAME.CHALLENGES,
  ROUTES_NAME.BLOGS,
  ROUTES_NAME.BLOG_TAGS,
  ROUTES_NAME.PROMOTION_LIST,
  ROUTES_NAME.TOURNAMENT_LIST,
  ROUTES_NAME.BLOG_DETAIL(slug),
  ROUTES_NAME.PAGE_DETAIL(slug),
  ROUTES_NAME.PLAY_GAME(slug),
  ROUTES_NAME.GAME_CATEGORIES_DETAIL(slug),
  ROUTES_NAME.PROMOTION_DETAIL(slug),
  ROUTES_NAME.TOURNAMENT_DETAIL(slug),
];
