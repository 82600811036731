import { defineNuxtRouteMiddleware, navigateTo } from "#app";
import dayjs from "dayjs";
import { configEnum, QUERY_PARAMS, SOCIAL_PROVIDER } from "~/config/enum";
import { ROUTES_NAME } from "~/config/router";
import { useAuthStore } from "~/stores/authStore";
import { deleteOldCookie, getMainMirrorUrl } from "~/utils";

// Set login type based on the full URL
async function setLoginType(route) {
  const loginTypeCookie = useCustomCookie("login_type", {
    maxAge: configEnum.COOKIE_EXPIRED,
  });

  if (!isClient()) return false; // Only run this on the client-side

  const isTelegramWebApp = isWebAppUrl();

  // Set the login type cookie
  loginTypeCookie.value = isTelegramWebApp
    ? SOCIAL_PROVIDER.TELEGRAM
    : SOCIAL_PROVIDER.EMAIL;
  if (
    isTelegramWebApp &&
    !(window.Telegram?.WebApp?.initData || route.query.tgWebAppData)
  ) {
    // Redirect if Telegram WebApp data is missing
    window.location.href = getMainMirrorUrl();
    return true; // Indicate that a redirect occurred
  }

  return false;
}

// Handle open deposit logic and authentication
async function handleOpenDeposit(to) {
  if (to.query[QUERY_PARAMS.OPEN_DEPOSIT] === "true") {
    const authStore = useAuthStore();
    const tokenCookie = useCustomCookie("token");

    // Redirect to login if user is not authenticated
    if (!authStore.user || !tokenCookie.value) {
      return navigateTo({
        path: ROUTES_NAME.LOGIN,
        query: { backToUrl: to.fullPath },
        replace: true,
      });
    }
  }
  return false; // Indicate no redirect occurred
}

// Handle showing the bonus code and update the query parameters
async function handleShowBonusCode(to) {
  const bonusCode = to.query[QUERY_PARAMS.TXTBONUSCODE];

  if (bonusCode) {
    const bonusCodeCookie = useCustomCookie(QUERY_PARAMS.TXTBONUSCODE, {
      maxAge: 60 * 60 * 24,
    }); // 1 day expiry
    bonusCodeCookie.value = bonusCode;

    // Remove the bonus code from query parameters and navigate to the updated URL
    const newQuery = { ...to.query };
    delete newQuery[QUERY_PARAMS.TXTBONUSCODE];

    return navigateTo({ path: to.path, query: newQuery }, { replace: true });
  }

  return false; // Indicate no redirect occurred
}

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (import.meta.server) return;
  // Delete old cookies tempararily
  const clearCookie = useCustomCookie("clear_cookie", {
    expires: dayjs().add(6, "months").toDate(),
  });

  if (!clearCookie.value) {
    deleteOldCookie("lang");
    deleteOldCookie("previousRoute");
    deleteOldCookie("login_type");
    clearCookie.value = true as any;
  }

  // Set login type based on the hostname and handle any redirect
  if (await setLoginType(to)) return; // Await for the redirect

  // Handle bonus code logic if query parameter is present
  const bonusCodeRedirect = await handleShowBonusCode(to);
  if (bonusCodeRedirect) return bonusCodeRedirect; // Await for the redirect

  // Handle open deposit logic if query parameter is present
  const openDepositRedirect = await handleOpenDeposit(to);
  if (openDepositRedirect) return openDepositRedirect; // Await for the redirect
});
