import { useQuery } from "@tanstack/vue-query";
import { getSocialUrls } from "~/service/socialService";
import { socialUrls } from "~/types/schema";

export const useGetSocialService = () => {
  return useQuery<any, Error, socialUrls>({
    queryFn: () => getSocialUrls(),
    queryKey: ["useGetSocialService"],
    select: (res) => res?.data?.data,
  });
};

export const useGetPageDetail = (slug: string) => {
  const { $api2 } = useNuxtApp();
  return useQuery<
    any,
    Error,
    { image: string; title: string; excerpt: any; body: string }
  >({
    queryFn: () => $api2(`/api/media/page/slug?slug=${slug}`),
    queryKey: ["useGetPageDetail", slug],
    select: (res) => res?.data,
  });
};
