import payload_plugin_qLmFnukI99 from "/var/lib/jenkins/workspace/FE/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/var/lib/jenkins/workspace/FE/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/lib/jenkins/workspace/FE/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/lib/jenkins/workspace/FE/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import navigation_repaint_client_bs2bWDGRIE from "/var/lib/jenkins/workspace/FE/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/lib/jenkins/workspace/FE/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/var/lib/jenkins/workspace/FE/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/var/lib/jenkins/workspace/FE/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/var/lib/jenkins/workspace/FE/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/lib/jenkins/workspace/FE/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_6wEQMY3tee from "/var/lib/jenkins/workspace/FE/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import switch_locale_path_ssr_5csfIgkrBP from "/var/lib/jenkins/workspace/FE/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/var/lib/jenkins/workspace/FE/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import axios_client_uIOGlBw5Dx from "/var/lib/jenkins/workspace/FE/client/plugins/axios.client.ts";
import dayjs_0prGeUjNDa from "/var/lib/jenkins/workspace/FE/client/plugins/dayjs.ts";
import fetch_ouSwaSLYHo from "/var/lib/jenkins/workspace/FE/client/plugins/fetch.ts";
import fingerprint_client_g8ledvHgBL from "/var/lib/jenkins/workspace/FE/client/plugins/fingerprint.client.ts";
import laravel_echo_client_eAwewjcapo from "/var/lib/jenkins/workspace/FE/client/plugins/laravel-echo.client.ts";
import loading_client_UQYQiZwb7K from "/var/lib/jenkins/workspace/FE/client/plugins/loading.client.ts";
import numberic_client_hbY5fUVzIO from "/var/lib/jenkins/workspace/FE/client/plugins/numberic.client.ts";
import reference_client_9xgfuumHJo from "/var/lib/jenkins/workspace/FE/client/plugins/reference.client.ts";
import vue_gtm_client_tctdFD8Cww from "/var/lib/jenkins/workspace/FE/client/plugins/vue-gtm.client.ts";
import vue_query_MbKQr1Q0EG from "/var/lib/jenkins/workspace/FE/client/plugins/vue-query.ts";
import vue3_toastify_client_0seyFTtWvX from "/var/lib/jenkins/workspace/FE/client/plugins/vue3-toastify.client.ts";
import vuetify_7Prac0A2JL from "/var/lib/jenkins/workspace/FE/client/plugins/vuetify.ts";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  nuxt_plugin_6wEQMY3tee,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  axios_client_uIOGlBw5Dx,
  dayjs_0prGeUjNDa,
  fetch_ouSwaSLYHo,
  fingerprint_client_g8ledvHgBL,
  laravel_echo_client_eAwewjcapo,
  loading_client_UQYQiZwb7K,
  numberic_client_hbY5fUVzIO,
  reference_client_9xgfuumHJo,
  vue_gtm_client_tctdFD8Cww,
  vue_query_MbKQr1Q0EG,
  vue3_toastify_client_0seyFTtWvX,
  vuetify_7Prac0A2JL
]