import qs from "qs";
import { CHALLENGE_TYPE, PAGES, PROVIDER_TYPE_FILTER } from "~/config/enum";
import { gameLikeRequest, listFilterParams } from "~/types/schema";
import { removeFieldEmptyValue } from "~/utils";

export const getGameCategories = () => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/games/categories`,
    method: "get",
  });
};

export const getGameList = (params: listFilterParams) => {
  const { $api2 } = useNuxtApp();
  return $api2(
    `/api/games/list?${qs.stringify(params, { arrayFormat: "brackets" })}`
  );
};

export const getGameCategory = (slug: string) => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/games/category/${slug}`,
    method: "get",
  });
};

export const updateGameLike = (gameLikeRequest: gameLikeRequest) => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/games/like`,
    method: "patch",
    data: gameLikeRequest,
  });
};

export const getFavoriteUserGame = (params: any) => {
  const { $api2 } = useNuxtApp();
  return $api2(
    `/api/games/user/favorites?${qs.stringify(params, { arrayFormat: "brackets" })}`
  );
};

export const getListImageSliders = () => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/media/image-sliders`,
    method: "get",
  });
};

export const getListLiveWins = (type: string) => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/games/live-wins/list?type=${type}`,
    method: "get",
  });
};

export const getRecentlyPlayed = (params: any) => {
  const { $api2 } = useNuxtApp();
  return $api2(
    `/api/games/recently-played/list?${qs.stringify(params, { arrayFormat: "brackets" })}`
  );
};

export const getAboutUs = () => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/media/page/slug?slug=` + PAGES.ABOUT_US,
    method: "get",
  });
};

export const playGame = (slug: string, currency: string) => {
  const { $api2 } = useNuxtApp();
  return $api2(`api/games/${slug}/game-url?currency=${currency}`);
};

export const isGameFavorite = (code: string) => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `api/games/${code}/check-favorite`,
    method: "get",
  });
};

export const getGameDetail = (code: string) => {
  const { $api2 } = useNuxtApp();
  return $api2(`api/games/${code}`);
};

export const playGameDemo = (slug?: string) => {
  const { $api2 } = useNuxtApp();
  return $api2(`api/games/${slug}/game-demo-url`);
};

export const getAllProviders = () => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `api/games/providers`,
    method: "get",
  });
};

export const getProvidersByType = (
  type: PROVIDER_TYPE_FILTER,
  params?: listFilterParams
) => {
  const { $api2 } = useNuxtApp();
  return $api2(`api/games/providers/${type}`, {
    params: params ? removeFieldEmptyValue(params) : null,
  });
};

export const getMostGameWins = () => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `api/games/highest-win-amount/list`,
    method: "get",
  });
};

export const getGameCategoriesForProvider = (params?: listFilterParams) => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `api/games/categories/list`,
    method: "get",
    params: params ? removeFieldEmptyValue(params) : null,
  });
};

export const getGamesByProvider = (params?: listFilterParams) => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `api/games/provider/games`,
    method: "get",
    params: params ? removeFieldEmptyValue(params) : null,
  });
};

export const getChallengesByType = (
  type: CHALLENGE_TYPE,
  params?: { page: number }
) => {
  const url =
    type !== CHALLENGE_TYPE.USER_COMPLETED
      ? `api/challenges/${type}`
      : `api/challenges/user/${type}`;

  const { $api } = useNuxtApp();
  return $api.request({
    url: url,
    method: "get",
    params: params ? removeFieldEmptyValue(params) : null,
  });
};
