import { defineStore } from "pinia";

export const useLikeStore = defineStore("useLikeStore", () => {
  const likeObject = ref<{
    id: string;
    like: boolean;
  }>();

  const emitLike = (id: string, like: boolean) => {
    likeObject.value = { id, like };
  };
  return {
    likeObject,
    emitLike,
  };
});
