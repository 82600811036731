import { defineNuxtPlugin } from "#app";
import Vue3Toastify, { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(Vue3Toastify, {
    bodyClassName: "toast-content",
    type: toast.TYPE.INFO,
    icon: true,
    autoClose: 9000,
    theme: "dark",
    hideProgressBar: true,
  });

  return {
    provide: { toast },
  };
});
