<template>
  <div
    class="flex min-h-[500px] flex-col overflow-hidden !rounded-xl bg-main-dark shadow-[0_20px_40px_0_rgba(0,0,0,0.25)]"
  >
    <div class="p-4">
      <div class="flex items-center gap-2">
        <input
          :value="search"
          @input="setInputValue"
          :placeholder="$t('common.placeholder.search_for_game')"
          class="min-w-24 flex-grow rounded-full border !border-[#F238C1] px-6 py-4 text-base text-white outline-none md:text-sm"
        />
        <button
          @click="close"
          type="button"
          class="rounded-full hover:bg-main-125"
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.0884 15.0877L24.9123 24.9116"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15.0877 24.9116L24.9116 15.0877"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>

    <div class="border border-main-175 bg-main-175 p-4 text-white">
      <NavbarSwiper
        :active-class="`.${activeClass}`"
        class="w-fit max-w-full bg-[unset]"
      >
        <div class="flex h-[54px] items-center">
          <button
            @click="selectedCategory = 'all'"
            :disabled="disableButton && selectedCategory !== 'all'"
            type="button"
            class="flex items-center gap-2 !rounded-lg bg-main-150 px-4 py-3 text-neutral-50-new [&:disabled]:!bg-main-150 [&:not(:disabled)]:hover:bg-main-pink [&:not(:disabled)]:hover:text-white"
            :class="selectedCategory === 'all' ? activeClass : ''"
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.73673 2.07303C9.47324 1.86809 9.34149 1.76562 9.19601 1.72623C9.06765 1.69148 8.93235 1.69148 8.80399 1.72623C8.65851 1.76562 8.52677 1.86809 8.26327 2.07303L3.17654 6.02937C2.83652 6.29384 2.6665 6.42607 2.54402 6.59167C2.43552 6.73836 2.3547 6.90362 2.30552 7.07932C2.25 7.27767 2.25 7.49305 2.25 7.92382V13.35C2.25 14.1901 2.25 14.6101 2.41349 14.931C2.5573 15.2132 2.78677 15.4427 3.06901 15.5865C3.38988 15.75 3.80992 15.75 4.65 15.75H6.15C6.36002 15.75 6.46503 15.75 6.54525 15.7091C6.61581 15.6732 6.67317 15.6158 6.70913 15.5453C6.75 15.465 6.75 15.36 6.75 15.15V10.2C6.75 9.77998 6.75 9.56996 6.83175 9.40953C6.90365 9.2684 7.01839 9.15367 7.15951 9.08176C7.31994 9.00002 7.52996 9.00002 7.95 9.00002H10.05C10.47 9.00002 10.6801 9.00002 10.8405 9.08176C10.9816 9.15367 11.0963 9.2684 11.1683 9.40953C11.25 9.56996 11.25 9.77998 11.25 10.2V15.15C11.25 15.36 11.25 15.465 11.2909 15.5453C11.3268 15.6158 11.3842 15.6732 11.4548 15.7091C11.535 15.75 11.64 15.75 11.85 15.75H13.35C14.1901 15.75 14.6101 15.75 14.931 15.5865C15.2132 15.4427 15.4427 15.2132 15.5865 14.931C15.75 14.6101 15.75 14.1901 15.75 13.35V7.92382C15.75 7.49305 15.75 7.27767 15.6945 7.07932C15.6453 6.90362 15.5645 6.73836 15.456 6.59167C15.3335 6.42607 15.1635 6.29384 14.8235 6.02937L9.73673 2.07303Z"
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span
              class="truncate break-words text-center text-[12px] font-normal capitalize"
            >
              {{ $t("common.all_game") }}
            </span>
          </button>
        </div>

        <div
          v-for="(category, i) in filteredCategories"
          :key="i"
          class="flex items-center"
        >
          <button
            @click="selectedCategory = category.slug"
            :disabled="disableButton && selectedCategory !== category.slug"
            type="button"
            class="flex items-center gap-2 !rounded-lg bg-main-150 px-4 py-3 text-neutral-50-new [&:disabled]:!bg-main-150 [&:not(:disabled)]:hover:bg-main-pink [&:not(:disabled)]:hover:!text-white"
            draggable="false"
            :class="selectedCategory === category.slug ? activeClass : ''"
          >
            <!-- dynamic icon -->
            <div class="h-[18px] w-[18px]" v-if="category.image">
              <img
                loading="lazy"
                class="h-full w-full object-contain"
                :src="category.image"
                alt=""
              />
            </div>
            <div
              class="truncate break-words text-center text-[12px] font-normal capitalize"
            >
              {{ category.name }}
            </div>
          </button>
        </div>
      </NavbarSwiper>
    </div>

    <Scrollbar class="flex max-h-[500px] flex-grow flex-col p-4">
      <div
        v-if="!isFirstLoading && gameListRef.length"
        class="grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7"
      >
        <div v-for="game in gameListRef" :key="game.id" class="flex flex-col">
          <SlideViewGame
            :game="game"
            :slug="selectedCategory === '' ? 'all' : selectedCategory"
          />
        </div>
      </div>

      <ClientOnly>
        <LazyLoadMoreData
          v-if="!isFirstLoading && gameListRef.length"
          :selected-category="selectedCategory"
          :keyword="search"
          :game-list="gameListRef"
          :allow-fetch="true"
          :loading-more="loadingMore"
          @update-game-list="
            (newList: any) => {
              gameListRef = newList;
              loadingMore = false;
            }
          "
          @set-loading-more="(value: boolean) => (loadingMore = value)"
        />

        <div
          v-if="!isFirstLoading && !gameListRef.length"
          class="text-lg font-extrabold text-white"
        >
          {{ $t("common.message.no_search_found", { search }) }}
        </div>

        <div
          class="flex flex-grow items-center justify-center"
          v-if="isFirstLoading"
        >
          <span class="loader"></span>
        </div>
      </ClientOnly>
    </Scrollbar>
  </div>
</template>

<script setup lang="ts">
import { API_LOADING_TIMEOUT } from "~/config/constant";
import { DISPLAY_SECTION } from "~/config/enum";
import { useGetGameCategories } from "~/queries/gameService";
import { useGameStore } from "~/stores/gameStore";
import { resCategoryGameList } from "~/types/schema";

const emit = defineEmits(["close"]);

const storeGame = useGameStore();
const { getGameByCategory } = storeGame;
const { data: categories } = useGetGameCategories();

const gameListRef = ref<resCategoryGameList[] | []>([]);

const isFirstLoading = ref(true);

const disableButton = ref(false);

const search = ref("");
const selectedCategory = ref("all");
const loadingMore = ref(false);

const activeClass = "navbar-search-active text-white bg-main-pink";

onMounted(async () => {
  disableButton.value = true;
  gameListRef.value = await getGameByCategory(selectedCategory.value, 1, false);
  setTimeout(() => {
    disableButton.value = false;
    isFirstLoading.value = false;
  }, API_LOADING_TIMEOUT);
});

watch(selectedCategory, async (current) => {
  scrollIntoActiveElement(`.${activeClass}`);
  isFirstLoading.value = true;
  disableButton.value = true;
  gameListRef.value = await getGameByCategory(current, 1, false, search.value);
  setTimeout(() => {
    disableButton.value = false;
    isFirstLoading.value = false;
  }, 200);
});

watch(search, async (current) => {
  isFirstLoading.value = true;
  disableButton.value = true;
  gameListRef.value = await getGameByCategory(
    selectedCategory.value,
    1,
    false,
    current
  );
  setTimeout(() => {
    disableButton.value = false;
    isFirstLoading.value = false;
  }, 200);
});

const route = useRoute();
watch(
  () => route.path,
  () => {
    close();
  }
);

const setInputValue = _debounce((event: any) => {
  search.value = event.target.value;
}, 1000);

const filteredCategories = computed(() => {
  return categories.value?.filter((category) => {
    return category.display_sections.includes(DISPLAY_SECTION.IN_LOBBY_NAV);
  }, []);
});

const close = () => {
  emit("close");
};
</script>
