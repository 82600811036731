import { computed } from "vue";
import { PAGES } from "~/config/enum";
import { ROUTES_NAME } from "~/config/router";
import { INavBarItem, NavbarEnum } from "~/types/schema";

export const useNavbar = () => {
  const token = useToken();
  const config = useRuntimeConfig();

  return computed((): INavBarItem[] => [
    {
      id: NavbarEnum.HOME,
      name: "common.home",
      url: ROUTES_NAME.LOBBY,
      disabled: false,
    },
    {
      id: NavbarEnum.FAVORITES,
      name: "common.favorites",
      url: ROUTES_NAME.FAVORITE,
      disabled: !token.value,
    },
    {
      id: NavbarEnum.CHALLENGES,
      name: "common.challenges",
      url: "javascript: void(0)",
      disabled: false,
      callback: () => {
        navigateTo(ROUTES_NAME.CHALLENGES + `?k=` + Math.random());
      },
    },
    {
      id: NavbarEnum.PROMOTIONS,
      name: "common.promotions",
      url: ROUTES_NAME.PROMOTION_LIST,
      disabled: false,
    },
    {
      id: NavbarEnum.TOURNAMENTS,
      name: "common.tournament",
      url: "javascript: void(0)",
      callback: () => {
        navigateTo(ROUTES_NAME.TOURNAMENT_LIST + `?k=` + Math.random());
      },
      disabled: false,
    },
    {
      id: NavbarEnum.PROVIDERS,
      name: "common.providers",
      url: ROUTES_NAME.PROVIDER_LIST,
      disabled: false,
    },
    {
      id: NavbarEnum.VIP,
      name: "common.vip",
      url: ROUTES_NAME.VIP_PROGRAM,
      disabled: !token.value,
    },
    {
      id: NavbarEnum.VPN_INFO,
      name: "common.vpn_info",
      url: ROUTES_NAME.PAGE_DETAIL(PAGES.VPN_INSTRUCTION),
      disabled: false,
    },
    {
      id: NavbarEnum.AFFILIATE,
      name: "common.affiliate",
      url: `${config.public.AFFILIATE_PARTNER_URL}`,
      disabled: false,
    },
    {
      id: NavbarEnum.LIVE_SUPPORT,
      name: "common.live_support",
      url: "javascript: void(0)",
      disabled: false,
      callback: () => {
        if (window.LiveChatWidget) window.LiveChatWidget.call("maximize");
      },
    },
    {
      id: NavbarEnum.PLAY_AGAIN,
      name: "common.play_again",
      url: ROUTES_NAME.PLAY_AGAIN,
      disabled: false,
      hidden: true,
    },
    {
      id: NavbarEnum.YOUR_BIGGEST_WIN,
      name: "common.your_biggest_win",
      url: "",
      disabled: false,
      hidden: true,
    },
    {
      id: NavbarEnum.PROFILE,
      name: "components.menu_profile.profile",
      url: "",
      disabled: false,
      hidden: true,
    },
    {
      id: NavbarEnum.MY_BONUS,
      name: "common.my_bonus",
      url: "",
      disabled: false,
      hidden: true,
    },
    {
      id: NavbarEnum.WALLET,
      name: "common.wallet",
      url: "",
      disabled: false,
      hidden: true,
    },
    {
      id: NavbarEnum.TRANSACTIONS,
      name: "common.transactions",
      url: "#",
      disabled: false,
      hidden: true,
    },
    {
      id: NavbarEnum.INBOXES,
      name: "common.inboxes",
      url: "#",
      disabled: false,
      hidden: true,
    },
  ]);
};
