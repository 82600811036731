import { CookieOptions, useCookie } from "#app";
import dayjs from "dayjs";
import { navigateTo } from "nuxt/app";
import { storeToRefs } from "pinia";
import { passwordRegex, pwRegexObject } from "~/config/constant";
import {
  BONUS_STATUS,
  PAGES,
  PLAY_STATUS,
  SOCIAL_PROVIDER,
  SYMBOLS,
  TRANSACTION_STATUS,
  WALLET_NETWORK_KIND,
} from "~/config/enum";
import { ROUTES_NAME } from "~/config/router";
import { useAuthStore } from "~/stores/authStore";
import { useWalletStore } from "~/stores/walletStore";
import { bonusData } from "~/types/schema";
import useIsMobile from "~/uses/fetchMobile";

export const validation = {
  rules_pass: {
    format: (value: string) => {
      return (
        passwordRegex.test(value) ||
        useNuxtApp().$i18n.t("validation_message.password_wrong_format")
      );
    },
    numeric: (value: string) => {
      return (
        pwRegexObject.numberic.regex.test(value) ||
        useNuxtApp().$i18n.t(pwRegexObject.numberic.message)
      );
    },
    length: (value: string) => {
      return (
        pwRegexObject.length.regex.test(value) ||
        useNuxtApp().$i18n.t(pwRegexObject.length.message)
      );
    },
    lowercase: (value: string) => {
      return (
        pwRegexObject.lowercase.regex.test(value) ||
        useNuxtApp().$i18n.t(pwRegexObject.lowercase.message)
      );
    },
    uppercase: (value: string) => {
      return (
        pwRegexObject.uppercase.regex.test(value) ||
        useNuxtApp().$i18n.t(pwRegexObject.uppercase.message)
      );
    },
    special_character: (value: string) => {
      return (
        pwRegexObject.special_character.regex.test(value) ||
        useNuxtApp().$i18n.t(pwRegexObject.special_character.message)
      );
    },
  },
  rules_email_username: {
    email_username: (value: string) => {
      const patternEmail =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*))@((?!-)[A-Za-z0-9-]+(?<!-)(\.[A-Za-z]{2,})+)$/i;
      const patternUsername = /^[a-z0-9_.]+$/;
      return patternEmail.test(value) || patternUsername.test(value)
        ? true
        : useNuxtApp().$i18n.t("validation_message.email_username_invalid");
    },
    email: (value: string) => {
      const patternEmail =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*))@((?!-)[A-Za-z0-9-]+(?<!-)(\.[A-Za-z]{2,})+)$/i;
      return (
        patternEmail.test(value) ||
        useNuxtApp().$i18n.t("validation_message.email_invalid")
      );
    },
    username: (value: string) => {
      const patternUsername = /^[a-z0-9_.]+$/;
      return (
        patternUsername.test(value) ||
        useNuxtApp().$i18n.t("validation_message.username_invalid")
      );
    },
  },
  rule_phone: {
    phone_number: (value: string) => {
      const patternPhone = /^[0-9]{9,15}$/;
      return (
        patternPhone.test(value) ||
        useNuxtApp().$i18n.t("validation_message.phone_wrong_format")
      );
    },
  },
  rule_zip_code: (value: string) => {
    const patternZipCode = /^[0-9]{5,9}$/;
    return (
      patternZipCode.test(value) ||
      useNuxtApp().$i18n.t("validation_message.zipcode_wrong_format")
    );
  },
  required: (value: string) => {
    return value && String(value)?.trim()?.length > 0
      ? true
      : useNuxtApp().$i18n.t("validation_message.required");
  },
  max_length: (value: string, fieldName: string, max: number) =>
    value?.length <= max ||
    useNuxtApp().$i18n.t("validation_message.max_length", {
      field: fieldName,
      max,
    }),
  match_password: (password: string, confirmPassword: any) =>
    password === confirmPassword ||
    useNuxtApp().$i18n.t("validation_message.password_not_match"),
  min_length: (value: string, fieldName: string, min: number) =>
    value?.length >= min ||
    useNuxtApp().$i18n.t("validation_message.min_length", {
      field: fieldName,
      min,
    }),
  between: (value: string, fieldName: string, min: number, max: number) => {
    if (value?.length < min || value?.length > max) {
      return useNuxtApp().$i18n.t("validation_message.between", {
        field: fieldName,
        min,
        max,
      });
    }
    return true;
  },
  min: (value: string, min: number, message: string) =>
    Number(value) >= min ||
    message ||
    useNuxtApp().$i18n.t("validation_message.min", {
      min,
    }),
  max: (value: string, max: number, message: string) =>
    Number(value) <= max ||
    message ||
    useNuxtApp().$i18n.t("validation_message.max", {
      max,
    }),
};

export const formatWithWalletCode = (
  amount: number | string,
  code: string,
  fixed = 2,
  showToZero = true
) => {
  if (!isNaN(Number(amount))) {
    return `${roundDecimalNumber(Number(amount), fixed)} ${code}`;
  }
  if (!showToZero) {
    return "";
  }
  return "";
};

export const formatDate = (value: string, format: string) => {
  if (value) {
    const lang = useCurrentLanguage();
    return dayjs(value)
      .locale(lang.value || defaultLanguage())
      .format(format || "DD MMM YYYY");
  }
  return "";
};

export const handleDataShowLevel = (
  listLevel: [],
  listLevelKey: string,
  name: string
) => {
  const item = { name: "", item: [] as any, key: "" };

  item["name"] = name;
  item["key"] = listLevelKey;
  listLevel
    .slice()
    .reverse()
    .forEach((levelData: any) => {
      item.item.push(levelData[listLevelKey]);
    });

  return item;
};

export const handlePlayGame = (
  code: string,
  { forceReload = false, query = {} }
) => {
  navigateTo(
    { path: ROUTES_NAME.PLAY_GAME(code), query },
    { external: forceReload }
  );
};

// handle element overflow outside parent element when using text editor cms
export const handleOverflowByEditor = (element: any) => {
  const floatElements = element?.querySelectorAll("[style*='float']");
  if (floatElements?.length > 0) {
    floatElements.forEach((el: any) => {
      const parent = el?.parentElement;
      parent.style.overflow = "auto";
    });
  }
};

export const handleTableStyle = (element: any) => {
  handleOverflowByEditor(element);
  const tableList = element?.querySelectorAll("table");
  if (tableList?.length > 0) {
    tableList?.forEach((table: any) => {
      const divElement = document.createElement("div");
      element?.insertBefore(divElement, table);
      divElement?.appendChild(table);
      divElement?.classList?.add("overflow-auto");
      divElement?.classList?.add("my-3");
      const tableCells = table?.querySelectorAll("td, th");
      if (Number(table?.border)) {
        table.style.border = `${table?.border}px solid #fff`;
        tableCells?.forEach((cell: any) => {
          cell.style.border = `1px solid #fff`;
        });
      } else {
        table.style.border = `1px dotted #fff`;
        tableCells?.forEach((cell: any) => {
          cell.style.border = `1px dotted #fff`;
        });
      }
      if (Number(table?.cellSpacing)) {
        table.style.borderSpacing = `${table?.cellSpacing}px`;
        table.style.borderCollapse = "separate";
      }
      if (tableCells?.length > 0 && Number(table?.cellPadding)) {
        tableCells?.forEach((cell: any) => {
          cell.style.padding = `${table?.cellPadding}px`;
        });
      }
    });
  }
};

export const handleCurrencyValue = (wallet: any, field = "balance") => {
  const authStore = useAuthStore();
  const walletStore = useWalletStore();
  const { user } = authStore;
  const { listCurrencies } = walletStore;
  let result = "";
  let symbol = "";
  // get user wallet currency
  const userWalletCurrency = user?.player_information?.wallet_currency;

  if (userWalletCurrency) {
    // get currency rate by wallet code
    const rate = listCurrencies?.rate[wallet?.entity_wallet_code];
    // get currency symbol by user wallet currency
    symbol = listCurrencies?.currencies?.find(
      (item: any) => item?.name === userWalletCurrency
    )?.symbol;
    const balance = wallet[field] || 0;
    const value = balance * Number(rate?.[userWalletCurrency]);
    result = value.toString();
  }
  return {
    result,
    symbol,
  };
};

export const handleConvertCurrencyValue = (wallet: any, field) => {
  const authStore = useAuthStore();
  const walletStore = useWalletStore();
  const { user } = authStore;
  const { listCurrencies } = walletStore;

  const userCurrency = user?.player_information?.wallet_currency;
  const rate = listCurrencies?.rate[wallet?.entity_wallet_code];
  const balance = wallet[field] || 0;

  let symbol =
    listCurrencies?.currencies?.find((item: any) => item?.name === userCurrency)
      ?.symbol || "";

  let value = balance;
  if (userCurrency === SYMBOLS.EUR) {
    return { result: value.toString(), symbol };
  }

  value = balance / Number(rate[SYMBOLS.EUR]);
  if (userCurrency === SYMBOLS.USD) {
    value *= Number(rate[userCurrency]);
  }

  return { result: value.toString(), symbol };
};

export const getAmountDisplay = (item: any, field: string) => {
  let image = "";
  let amount = "";
  const authStore = useAuthStore();
  const { user } = authStore;
  const userWalletCurrency = user?.player_information?.wallet_currency;

  if (
    item.hasOwnProperty("status") &&
    item.status != TRANSACTION_STATUS.PAID_OUT
  ) {
    return getNotPaidOutDisplay(item);
  }

  if (userWalletCurrency) {
    image = item?.entity_wallet_code
      ? getWalletImageByCode(item?.entity_wallet_code)
      : getCurrencyImageByCode(userWalletCurrency);
    let symbol = handleCurrencyValue(item, field).symbol;
    let result = handleCurrencyValue(item, field).result;
    if (Number(result) >= 0) {
      amount = `${symbol}${roundDecimalNumber(Number(result), 2)}`;
    } else {
      amount = `-${symbol}${roundDecimalNumber(Number(result) * -1, 2)}`;
    }
  } else {
    image = getWalletImageByCode(item?.entity_wallet_code);
    amount = formatWithWalletCode((item as any)[field] || "", "", 8);
  }
  return {
    image,
    amount,
  };
};

export const getNotPaidOutDisplay = (item, field = "fiat_amount") => {
  let image = "";
  let amount = "";
  const authStore = useAuthStore();
  const { user } = authStore;
  const userWalletCurrency = user?.player_information?.wallet_currency;

  image = item?.entity_wallet_code
    ? getWalletImageByCode(item?.entity_wallet_code)
    : getCurrencyImageByCode(userWalletCurrency);
  let symbol = handleConvertCurrencyValue(item, field).symbol;
  let result = handleConvertCurrencyValue(item, field).result;

  if (userWalletCurrency) {
    if (Number(result) >= 0) {
      amount = `${symbol}${roundDecimalNumber(Number(result), 2)}`;
    } else {
      amount = `-${symbol}${roundDecimalNumber(Number(result) * -1, 2)}`;
    }
  } else {
    amount = formatWithWalletCode(result || "", "", 8);
  }

  return {
    image,
    amount,
  };
};

export const getWalletImageByCode = (code: string) => {
  let image = "";
  const walletStore = useWalletStore();
  const { listEntityWallets } = walletStore;
  const itemFind = listEntityWallets?.find((item) => item.code === code);
  if (itemFind) {
    image = itemFind?.coin_image;
  }
  return image;
};

export const getCurrencyImageByCode = (code: string) => {
  let image = "";
  const walletStore = useWalletStore();
  const { listCurrencies } = walletStore;
  const itemFind = listCurrencies?.currencies?.find(
    (item: any) => item?.name === code
  );
  if (itemFind) {
    image = itemFind?.image;
  }
  return image;
};

export const scrollIntoActiveElement = (activeClass: string) => {
  setTimeout(() => {
    try {
      const menuActive = document.querySelector(activeClass);
      if (menuActive) {
        menuActive?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }
    } catch (e) {
      //
    }
  }, 10);
};

// export const timezoneDifference = () => {
//     let result = 0;
//     // this calculate the difference between client timezone/value and timezone/value using in application (UTC+0)
//     const offsetTimezone = 0;
//     // new Date().getTimezoneOffset() negative result = positive timezone and vice versa
//     const clientLocalTimezone = (new Date().getTimezoneOffset() / 60) * -1;
//     result = clientLocalTimezone - offsetTimezone;
//     return result;
// }

export const roundDecimalNumber = (value: number, fixed: number) => {
  let result = "";
  let multiplier = Math.pow(10, fixed);
  result = (
    Math.round((value + Number.EPSILON) * multiplier) / multiplier
  ).toFixed(fixed);
  return result;
};

export const handleLabelByKind = (kind: string) => {
  if (kind === WALLET_NETWORK_KIND.BTC_LIGHTNING) {
    return useNuxtApp().$i18n.t("common.lightning_request");
  }
  return useNuxtApp().$i18n.t("common.payment_request_kind", { kind });
};

export const onClickOutsideDialog = ({ callback }: any) => {
  const toastify = document.querySelector(".Toastify__toast");
  if (!toastify && callback) {
    callback();
  }
};

export const handleClickOutside = ({ refValue, target, callback }: any) => {
  if (!refValue || !refValue?.contains(target)) {
    callback && callback();
    return;
  }
};

export const handleBonusDetailsCollapse = (
  id: number,
  currentArray: number[]
) => {
  let array: number[] = [...currentArray];
  if (!array?.includes(id)) {
    array?.push(id);
  } else {
    array = array?.filter((item: number) => item !== id);
  }
  return array;
};

export const startCountdownBonus = ({
  data,
  field,
  callback,
}: {
  data: bonusData[];
  field: string;
  callback?: () => void;
}) => {
  return data?.forEach((item: bonusData) => {
    let dateByStatus = dayjs((item as any)[field]).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    let countdownDate = new Date(dateByStatus);
    let now = new Date().getTime();
    let timeDifference = countdownDate.getTime() - now;
    item.days = 0;
    item.hours = 0;
    item.mins = 0;
    if (timeDifference > 0) {
      let interval = setInterval(async () => {
        now = new Date().getTime();
        timeDifference =
          countdownDate.getTime() - now > 0 ? countdownDate.getTime() - now : 0;
        item.days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        item.hours = Math.floor(
          (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        item.mins = Math.floor(
          (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
        );
        if (timeDifference <= 0) {
          clearInterval(interval);
          callback && callback();
        }
      }, 1000);
    }
  });
};

export const getRawWalletValueFromCurrency = (
  value: string,
  entity_wallet_code: string,
  currency: string
) => {
  const walletStore = useWalletStore();
  const { listCurrencies } = storeToRefs(walletStore);
  const rate = listCurrencies.value?.rate[entity_wallet_code];
  const result = Number(value) / Number(rate[currency]);
  return Number(result);
};

export const getCurrencyData = () => {
  const walletStore = useWalletStore();
  const { listCurrencies } = storeToRefs(walletStore);

  const itemFind = listCurrencies.value?.currencies?.find(
    (item: any) => item.is_default
  );
  if (itemFind) {
    const { symbol, name } = itemFind || {};
    return {
      symbol,
      name,
    };
  }
};

export const getGameCurrencyDefault = () => {
  const walletStore = useWalletStore();
  const { listCurrencies } = storeToRefs(walletStore);

  const itemFind = listCurrencies.value?.currencies?.find(
    (item: any) => item.is_game_currency
  );
  if (itemFind) {
    const { symbol, name } = itemFind || {};
    return {
      symbol,
      name,
    };
  }
};

export const removeFieldEmptyValue = (obj: any) => {
  const newObj = Object.keys(obj)
    .filter((key) => !!obj[key])
    .reduce((current, key) => ({ ...current, [key]: obj[key] }), {});
  return newObj;
};

export const getErrorMessagesListByForm = async (fieldRef: any) => {
  const field = fieldRef.value as any;
  const result = await field.validate();
  return result;
};

export const getHeaderHeight = () => {
  const { isMobile } = useIsMobile();
  return isMobile() ? 64 : 94;
};

export const formatLanguageCode = (code: string) => {
  return code.split("-")[0];
};

export const getBonusStatusText = (status: BONUS_STATUS) => {
  if (status === BONUS_STATUS.ACTIVE) {
    return "bonus_status.active";
  }
  if (status === BONUS_STATUS.AVAILABLE) {
    return "bonus_status.available";
  }
  if (status === BONUS_STATUS.CLAIMED) {
    return "bonus_status.claimed";
  }
  if (status === BONUS_STATUS.COMPLETED) {
    return "bonus_status.completed";
  }
  if (status === BONUS_STATUS.EXPIRED) {
    return "bonus_status.expired";
  }
  if (status === BONUS_STATUS.BUSTED) {
    return "bonus_status.busted";
  }
  if (status === BONUS_STATUS.UNCLAIMED) {
    return "bonus_status.unclaimed";
  }
  return "";
};

export const getMessageBlockGame = (canPlay: string) => {
  let results = {
    message: "",
    url: "",
  };
  switch (canPlay) {
    case PLAY_STATUS.LOCATION_BLOCKED:
      results.message = "common.message.not_available_in_location";
      results.url = ROUTES_NAME.PAGE_DETAIL(PAGES.VPN_INSTRUCTION);
      break;
    case PLAY_STATUS.HIDDEN_WHEN_BONUS:
      results.message = "common.message.not_available_when_active_bonus";
      break;
    default:
      results.message = "common.message.not_available_to_play";
      break;
  }
  return results;
};

export const defaultFiatAmountDisplay = (value: any) => {
  const unit = getCurrencyData()?.symbol;
  return `${unit}${value}`;
};

export const isWebApp = () => {
  const login_type = useCustomCookie("login_type");
  return login_type.value == SOCIAL_PROVIDER.TELEGRAM;
};

export const defaultLanguage = () => {
  return "en";
};

export function isClient() {
  return typeof window !== "undefined";
}

export function isProduction() {
  const config = useRuntimeConfig();
  return config.public.APP_ENV === "production";
}

export function useCustomCookie(name: string, options: CookieOptions = {}) {
  const defaultOptions: any = {
    secure: isProduction(),
    sameSite: isWebAppUrl() ? "None" : "Lax", // Apply domain only for production,
    ...options, // Merge any options passed by the caller
  };
  return useCookie(name, defaultOptions);
}

export function isWebAppUrl() {
  const config = useRuntimeConfig();
  const url = useRequestURL();
  return `${url.hostname}` === config.public.WEB_APP_URL;
}

export function deleteOldCookie(name, options = {}) {
  const oldOptions = {
    domain: window.location.hostname,
    secure: isProduction() ? true : false,
    sameSite: "None", // Apply domain only for production
    ...options, // Merge any options passed by the caller
  };
  const oldCookie = useCookie(name, oldOptions);
  oldCookie.value = null;
}

export function appendSchema(domain) {
  if (typeof window === "undefined") return null; // Ensure it's only run on the client-side

  const scheme = window.location.protocol === "https:" ? "https://" : "http://";
  return scheme + domain;
}

export function getMainMirrorUrl(path = "") {
  const config = useRuntimeConfig();
  // Check if multiple domains are enabled
  const domain = config.public.MULTIPLE_DOMAINS
    ? appendSchema(config.public.MAIN_MIRROR_DOMAIN)
    : config.public.APP_URL;

  return `${domain}/${path}`;
}

export function getDomainFromSubdomain() {
  const url = useRequestURL();
  const hostParts = url.hostname.split(".");

  if (hostParts.length > 2) {
    return hostParts.slice(-2).join(".");
  } else {
    return url.hostname;
  }
}

export const scrollToTop = () => {
  if (isClient()) {
    const main = document.getElementById("main");
    if (main) main.scrollTop = 0;
  }
};

export const scrollToBottom = (id: "app" | "main" = "app") => {
  if (isClient()) {
    setTimeout(() => {
      const main = document.getElementById(id);
      if (main) main.scrollTop = 99999;
    }, 300);
  }
};
export const zoomOutMobile = () => {
  const viewport: any = document.querySelector('meta[name="viewport"]');
  if (viewport) {
    viewport.content = "initial-scale=1";
    viewport.content = "width=device-width";
  }
};

export function formatStateCount(count: number): string {
  if (!count) return "";
  return count < 100 ? count.toString() : "99+";
}
export function getRandomLetter(): string {
  const letters = "abcdefghijklmnopqrstuvwxyz";
  const randomIndex = Math.floor(Math.random() * 26);
  const randomLetter = letters[randomIndex];
  return Math.random() < 0.5 ? randomLetter.toUpperCase() : randomLetter;
}
