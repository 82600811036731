<template>
  <LobbySwiper
    class="mb-8"
    :title="$t('common.challenges')"
    :listData="challengesList?.pages?.[0].data"
    :link="ROUTES_NAME.CHALLENGES"
    :next-slide-amount="width < 800 ? 1 : 2"
    v-if="challengesList?.pages?.[0].data.length"
    no-query
    :svgIcon="icon"
    hide-empty
  >
    <div
      v-for="(challenge, index) in challengesList?.pages?.[0].data?.slice(
        0,
        10
      )"
      :key="challenge.id"
      class="keen-slider__slide flex-shrink-0 px-1.5 pt-2 md:px-2"
      :class="[`number-slide${index + 1}`, '!w-64 md:!w-72 lg:!w-80 xl:!w-96']"
    >
      <SlideViewChallenge :challenge="challenge" />
    </div>
  </LobbySwiper>
</template>
<script setup lang="ts">
import { useDisplay } from "vuetify";
import LobbySwiper from "~/components/LobbySwiper.vue";
import { CHALLENGE_TYPE } from "~/config/enum";
import { ROUTES_NAME } from "~/config/router";
import { useGetListChallengesGame } from "~/queries/gameService";
import { NavbarEnum } from "~/types/schema";

const { width } = useDisplay();

const { data: challengesList, suspense: prefetch } = useGetListChallengesGame(
  ref(CHALLENGE_TYPE.LIVE)
);

await prefetch();
const navbarList = useNavbar();
const icon = computed(() => {
  return navbarList.value.find((item) => item.id === NavbarEnum.CHALLENGES);
});
</script>
