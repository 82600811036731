<template>
  <div class="relative text-white">
    <CommonDropdown
      :disable-search="disableSearch"
      :listData="listWalletRef"
      v-model="isOpen"
      v-model:search="search"
      :no-data-message="$t('common.message.no_wallet_found')"
      :wrap-class="`h-[42px] bg-main-150 ${wrapClass}`"
      content-class="w-full left-0"
    >
      <template #selected>
        <div
          v-if="allCoinOptions && !selectedWalletRef"
          class="flex-grow-1 overflow-x-auto"
        >
          <slot name="placeholder" />
        </div>

        <div v-else class="flex items-center gap-2 truncate">
          <img
            class="block h-4 w-4 flex-shrink-0 rounded-full"
            :src="getWalletImageByCode(selectedWalletRef.entity_wallet_code)"
            alt=""
          />
          <div v-if="currency" class="relative top-[1px] text-sm">
            {{
              `${
                handleCurrencyValue(selectedWalletRef).symbol
              }${roundDecimalNumber(
                Number(handleCurrencyValue(selectedWalletRef).result),
                2
              )}`
            }}
          </div>
          <div
            v-else
            class="relative top-[1px] flex items-center gap-1 truncate text-sm"
          >
            <div v-if="showAmount" class="truncate">
              {{
                formatWithWalletCode(selectedWalletRef?.balance || "", "", 8)
              }}
            </div>
            <span>{{ selectedWalletRef.entity_wallet_code }}</span>
          </div>
        </div>
      </template>

      <template #list-dropdown-item>
        <div
          v-if="allCoinOptions"
          class="group cursor-pointer border-b !border-main-125 p-3 hover:bg-main-125"
          :class="{
            'bg-main-125': !selectedWalletRef?.entity_wallet_code,
          }"
          @click="changeSelectedWallet('')"
        >
          <div class="flex items-center justify-between gap-4">
            <div
              class="flex flex-grow items-center justify-between gap-[16px] truncate text-sm font-[500] text-white"
            >
              <div class="flex items-center gap-[8px] truncate">
                <svg
                  class="flex-shrink-0"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.9377 15.9377C19.3603 15.4795 22 12.548 22 9C22 5.13401 18.866 2 15 2C11.452 2 8.52049 4.63967 8.06227 8.06227M16 15C16 18.866 12.866 22 9 22C5.13401 22 2 18.866 2 15C2 11.134 5.13401 8 9 8C12.866 8 16 11.134 16 15Z"
                    stroke="white"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div
                  class="flex items-center gap-1 truncate group-hover:!text-main-pink"
                  :class="{
                    'text-main-pink': !selectedWalletRef?.entity_wallet_code,
                  }"
                >
                  <span class="text-sm">{{ $t("common.all_coins") }}</span>
                </div>
              </div>
            </div>
            <div class="h-6 w-6">
              <svg
                v-if="!selectedWalletRef?.entity_wallet_code"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5Z"
                  fill="#73DD87"
                />
                <path
                  d="M10.5799 16.08C10.3799 16.08 10.1899 16 10.0499 15.86L7.21994 13.03C6.92994 12.74 6.92994 12.26 7.21994 11.97C7.50994 11.68 7.98994 11.68 8.27994 11.97L10.5799 14.27L15.7199 9.13001C16.0099 8.84001 16.4899 8.84001 16.7799 9.13001C17.0699 9.42001 17.0699 9.90001 16.7799 10.19L11.1099 15.86C10.9699 16 10.7799 16.08 10.5799 16.08Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        </div>

        <div
          v-for="(wallet, index) in listWalletRef"
          :key="index"
          class="group cursor-pointer border-b !border-main-125 p-3 hover:bg-main-125"
          :class="{
            'bg-main-125':
              selectedWalletRef?.entity_wallet_code ===
              wallet?.entity_wallet_code,
          }"
          @click="changeSelectedWallet(wallet)"
        >
          <div class="flex items-center justify-between gap-4">
            <div
              class="flex flex-grow items-center justify-between gap-[16px] truncate text-sm font-[500] text-white"
            >
              <div class="flex items-center gap-[8px] truncate">
                <div class="relative h-4 w-4 flex-shrink-0">
                  <img
                    loading="lazy"
                    class="h-full w-full flex-shrink-0 rounded-full"
                    :src="getWalletImageByCode(wallet?.entity_wallet_code)"
                    alt=""
                  />
                </div>
                <div
                  class="flex items-center gap-1 truncate group-hover:!text-main-pink"
                  :class="{
                    'text-main-pink':
                      selectedWalletRef?.entity_wallet_code ===
                      wallet?.entity_wallet_code,
                  }"
                >
                  <span v-if="showAmount" class="truncate">{{
                    formatWithWalletCode(wallet?.balance || "", "", 8)
                  }}</span>
                  <span>{{ wallet.entity_wallet_code }}</span>
                </div>
              </div>
              <div class="truncate" v-if="currency">
                {{
                  `${handleCurrencyValue(wallet).symbol}${roundDecimalNumber(
                    Number(handleCurrencyValue(wallet).result),
                    2
                  )}`
                }}
              </div>
            </div>
            <div
              v-if="
                selectedWalletRef?.entity_wallet_code ===
                wallet?.entity_wallet_code
              "
              class="h-6 w-6"
            >
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5Z"
                  fill="#73DD87"
                />
                <path
                  d="M10.5799 16.08C10.3799 16.08 10.1899 16 10.0499 15.86L7.21994 13.03C6.92994 12.74 6.92994 12.26 7.21994 11.97C7.50994 11.68 7.98994 11.68 8.27994 11.97L10.5799 14.27L15.7199 9.13001C16.0099 8.84001 16.4899 8.84001 16.7799 9.13001C17.0699 9.42001 17.0699 9.90001 16.7799 10.19L11.1099 15.86C10.9699 16 10.7799 16.08 10.5799 16.08Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        </div>
      </template>
    </CommonDropdown>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useWalletStore } from "~/stores/walletStore";
import { getWalletImageByCode, handleCurrencyValue } from "~/utils";

const walletStore = useWalletStore();
const { listWalletOriginal, selectedWallet } = storeToRefs(walletStore);

const emit = defineEmits(["onWalletChange", "updateSelectedWallet"]);
const props = defineProps([
  "currency",
  "selectedWalletRef",
  "showAmount",
  "allCoinOptions",
  "disableSearch",
  "wrapClass",
]);

const walletRef = ref();
const isOpen = ref(false);
const search = ref("");
const selectedWalletRef = ref(props.selectedWalletRef);
const listWalletRef = ref([...listWalletOriginal.value]); // list to display UI

watch(
  listWalletOriginal,
  (current) => {
    listWalletRef.value = current;
  },
  { deep: true }
);

watch(
  selectedWallet,
  () => {
    let itemFind = listWalletOriginal.value.find(
      (item) =>
        item.entity_wallet_code === selectedWalletRef.value?.entity_wallet_code
    );
    if (itemFind) {
      selectedWalletRef.value = { ...itemFind };
      emit("updateSelectedWallet", itemFind);
    }
  },
  { deep: true }
);

// props modified from parent, so child component need to watch change then re-update value
watch(
  () => props.selectedWalletRef,
  (current) => {
    selectedWalletRef.value = current;
  }
);

// directly modify the value on dropdown
const changeSelectedWallet = (wallet: any) => {
  selectedWalletRef.value = wallet;
  emit("onWalletChange", wallet);
  isOpen.value = false;
};

watch(search, (current: string) => {
  if (current) {
    const result = [...listWalletOriginal.value].filter((item) => {
      const code = item?.entity_wallet_code?.toLowerCase()?.trim();
      const formatCurrent = current.toLowerCase()?.trim();
      return code?.includes(formatCurrent);
    });
    listWalletRef.value = result;
  } else {
    listWalletRef.value = [...listWalletOriginal.value];
  }
});
</script>

<style scoped></style>
