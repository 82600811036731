<template>
  <Scrollbar class="max-h-[40vh] py-0.5 pr-3">
    <div v-if="listActive.length" class="space-y-4">
      <div v-for="data in listActive" :key="data.id" class="active-item">
        <div class="mb-4 flex items-center justify-between gap-2">
          <div class="flex items-center gap-3 truncate">
            <img
              src="/images/icon/bonus-star-icon-new.svg"
              alt=""
              loading="lazy"
            />
            <p class="truncate text-sm font-bold text-white">
              {{ data.title || "" }}
            </p>
          </div>
          <button
            v-if="data.type === BONUS_TYPE.FREE_SPINS_OFFER"
            type="button"
            @click="handleClick(data)"
            class="rounded-full bg-main-pink px-6 py-2 text-xs font-semibold text-white"
          >
            {{ buttonLabel(data) }}
          </button>
        </div>

        <CollapseItem
          :data="data"
          :open-details="openDetails"
          @update-list-open-details="
            (id) => (openDetails = handleBonusDetailsCollapse(id, openDetails))
          "
        >
          <div class="flex flex-col overflow-hidden">
            <detail-item
              :label="$t('bonus.active.progression')"
              :keepColumn="true"
            >
              <template #value>
                <div
                  class="flex flex-col items-center gap-x-4 gap-y-2 sm:flex-row"
                >
                  <div
                    class="w-full flex-1 rounded-[100px] !border !border-solid !border-main-125 px-[8px] py-[7px]"
                  >
                    <ProgressLevel
                      :progress="handleBonusProgression(data)"
                      class="!h-2"
                    />
                  </div>
                  <div class="flex flex-shrink-0 items-center gap-2">
                    <div
                      v-if="data.entity_wallet_code"
                      class="h-4 w-4 flex-shrink-0"
                    >
                      <img
                        :src="getWalletImageByCode(data.entity_wallet_code)"
                        :alt="data.entity_wallet_code"
                        class="h-full w-full object-contain"
                      />
                    </div>
                    <div class="text-sm font-bold text-white">
                      {{
                        `${data.bet_amount || 0}/${
                          data.bet_requirement_amount || 0
                        }`
                      }}
                    </div>
                  </div>
                </div>
              </template>
            </detail-item>
            <detail-item
              :label="$t('common.status')"
              :value="$t(getBonusStatusText(data.status))"
            />
            <detail-item :label="$t('common.id')" :value="data.id" />
            <detail-item :label="$t('common.currency')">
              <template #value>
                <div
                  v-if="data.entity_wallet_code"
                  class="h-5 w-5 flex-shrink-0"
                >
                  <img
                    :src="getWalletImageByCode(data.entity_wallet_code)"
                    :alt="data.entity_wallet_code"
                    class="h-full w-full object-contain"
                  />
                </div>
              </template>
            </detail-item>
            <detail-item
              :label="$t('common.activated_on')"
              :value="
                data.start_active_time
                  ? dayjs(data.start_active_time).format(
                      `YYYY-MM-DD [${$t('bonus.available.at')}] HH:mm`
                    )
                  : ''
              "
            />
            <detail-item :label="$t('common.expiry_date')">
              <template #value>
                <p class="font-bold text-white">
                  {{ data.days }}
                  <span class="font-normal">{{ $t("common.days") }}</span>
                  :
                  {{ data.hours }}
                  <span class="font-normal">{{ $t("common.hours") }}</span>
                  :
                  {{ data.mins }}
                  <span class="font-normal">{{ $t("common.mins") }}</span>
                </p>
              </template>
            </detail-item>
            <detail-item
              v-if="data.max_bet_per_round"
              :label="$t('bonus.available.max_bet_per_round')"
              :value="defaultFiatAmountDisplay(data.max_bet_per_round)"
            />
            <detail-item
              v-if="!Number(data.bet_requirement_amount)"
              :label="$t('common.wagering_required')"
              :value="`${data.bet_requirement || 0}X`"
            />
            <detail-item
              v-if="data.type === BONUS_TYPE.FREE_SPINS_OFFER"
              :label="$t('bonus.active.used_free_spins')"
              :value="`${data.used_fs_round || 0}/${data.fs_round || 0}`"
            />
            <p
              class="term-and-condition mt-2 text-xs text-[#ef3054]"
              v-if="data.term_and_condition"
              v-html="data.term_and_condition"
            ></p>
          </div>
        </CollapseItem>
      </div>
    </div>
    <div
      v-else
      class="flex h-[300px] items-center justify-center text-sm text-white"
    >
      {{ $t("common.message.no_bonus_found") }}
    </div>
  </Scrollbar>
</template>

<script setup lang="ts">
import dayjs from "dayjs";
import { storeToRefs } from "pinia";
import ProgressLevel from "~/components/ProgressLevel.vue";
import { BONUS_TYPE } from "~/config/enum";
import { ROUTES_NAME } from "~/config/router";
import { useBonusStore } from "~/stores/bonusStore";
import { useSystemStore } from "~/stores/systemStore";
import { bonusData } from "~/types/schema";
import {
  getBonusStatusText,
  handleBonusDetailsCollapse,
  startCountdownBonus,
} from "~/utils";
import CollapseItem from "./CollapseItem.vue";
import DetailItem from "./DetailItem.vue";

const bonusStore = useBonusStore();
const systemStore = useSystemStore();
const { changeBonusDialog } = systemStore;
const { listActive } = storeToRefs(bonusStore);

const openDetails = ref<number[]>(
  listActive.value?.map((item: bonusData) => item.id)
);

startCountdownBonus({ data: listActive.value, field: "end_active_time" });

const handleBonusProgression = (bonus: bonusData) => {
  const betAmount = bonus.bet_amount ? Number(bonus.bet_amount) : 0;
  const betRequirementAmount = bonus.bet_requirement_amount
    ? Number(bonus.bet_requirement_amount)
    : 0;
  return (betAmount / betRequirementAmount) * 100;
};

const buttonLabel = (bonus: bonusData) => {
  return bonus.fs_round > bonus.used_fs_round
    ? useNuxtApp().$i18n.t("common.btn.play")
    : useNuxtApp().$i18n.t("common.lobby");
};

const handleClick = (bonus: bonusData) => {
  if (bonus.fs_round > bonus.used_fs_round) {
    playGame(bonus);
  } else if (bonus.fs_round === bonus.used_fs_round) {
    goToHome();
  }
};
const playGame = (game: bonusData) => {
  const { game_code } = game || {};
  changeBonusDialog(false);
  handlePlayGame(game_code, {
    forceReload: false,
    query: { k: Math.random() },
  });
};

const goToHome = () => {
  changeBonusDialog(false);
  navigateTo({
    path: ROUTES_NAME.LOBBY,
  });
};
</script>

<style scoped lang="scss"></style>
