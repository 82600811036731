import { computed } from "vue";

export const useCurrentLanguage = () => {
  return useCustomCookie("lang", {
    // 6 months
    maxAge: 60 * 60 * 24 * 30 * 6,
  });
};

export const useLanguages = () => {
  const { $i18n } = useNuxtApp();
  return computed(() => [
    {
      title: $i18n.t("common.languages.english"),
      value: "en",
    },
    {
      title: $i18n.t("common.languages.germany"),
      value: "de",
    },
    {
      title: $i18n.t("common.languages.japanese"),
      value: "ja",
    },
    {
      title: $i18n.t("common.languages.french"),
      value: "fr",
    },
    {
      title: $i18n.t("common.languages.dutch"),
      value: "nl",
    },
    {
      title: $i18n.t("common.languages.portuguese"),
      value: "pt",
    },
    {
      title: $i18n.t("common.languages.turkish"),
      value: "tr",
    },
    {
      title: $i18n.t("common.languages.spanish"),
      value: "es",
    },
  ]);
};
